import React from 'react'

    class Simbinet extends React.Component {

        constructor() {

            super()

            this.state = {
                
            }

        }

        componentDidMount() {

            window.location.replace("https://sites.google.com/simbiose.social/simbinet/p%C3%A1gina-inicial");
        
        }

        render() {

            return (

                <div>

                    <div className="paginaNaoEncontrada">
                        
                        <div className="bannerTexto">

                            <div className="bannerTextoTitulo">

                                Simbinet

                            </div>

                            <div className="bannerTextoSubtitulo">

                                Aguarde, você será redirecionado à nossa intranet.
                                
                            </div>

                        </div>

                    </div>

                </div>

            )

        }

    }

export default Simbinet