import React from "react";
import { NavLink } from "react-router-dom";
import Logo from '../images/Simbiose_Social.png'
import setaMenu from '../images/setamenu.svg'
import MenuMobileInativo from '../images/mobilenavinativo.png'
import MenuMobileAtivo from '../images/mobilenavativo.png'
import PropTypes from "prop-types";
import { withRouter } from "react-router";

    class Navbar extends React.Component {

        constructor() {

            super()

            this.state = {
                proponenteInfo: false,
                empresaInfo: false,
            }

        }

        static propTypes = {
          match: PropTypes.object.isRequired,
          location: PropTypes.object.isRequired,
          history: PropTypes.object.isRequired
        }

        exibeProponenteInfo = () => {

            this.setState({
                proponenteInfo: true
            })

        }

        escondeProponenteInfo = () => {

            this.setState({
                proponenteInfo: false
            })

        }

        exibeEmpresaInfo = () => {

            this.setState({
                proponenteInfo: true
            })

        }

        escondeEmpresaInfo = () => {

            this.setState({
                proponenteInfo: false
            })

        }

        exibeEmpresaInfo = () => {

            this.setState({
                empresaInfo: true
            })

        }

        escondeEmpresaInfo = () => {

            this.setState({
                empresaInfo: false
            })

        }

        render() {

            return (

                <div className="navbarContainer">

                    <div className="navbarContent">

                        <div className="navbarBranding">

                            <NavLink className="iconeNavbarLogo" to={{pathname: "/", search: this.props.location.search}} onClick={() => this.props.fechaMenuMobile()}>

                                <img alt="logoSimbio" src={Logo} />

                            </NavLink>

                        </div>

                        <div className="navbarActions">

                            <NavLink className="iconeNavbar" to={{pathname: "/patrocinadores", search: this.props.location.search}} style={{color: this.state.empresaInfo ? '#1F99A7' : ''}} onMouseEnter={() => this.exibeEmpresaInfo()} onMouseLeave={() => this.escondeEmpresaInfo()}>

                                Para patrocinadores <img alt="setaMenu" src={setaMenu} style={{transform: this.state.empresaInfo ? 'rotate(180deg)' : '', transition: 'ease 0.4s'}} />

                            </NavLink>

                            <NavLink className="iconeNavbar" to={{pathname: "/plataforma-de-projetos", search: this.props.location.search}} style={{color: this.state.proponenteInfo ? '#1F99A7' : ''}} onMouseEnter={() => this.exibeProponenteInfo()} onMouseLeave={() => this.escondeProponenteInfo()}>

                                Para proponentes <img alt="setaMenu" src={setaMenu} style={{transform: this.state.proponenteInfo ? 'rotate(180deg)' : '', transition: 'ease 0.4s'}} />

                            </NavLink>

                            {/*

                            <NavLink className="iconeNavbar" to="/sobre-nos">

                                Sobre nós

                            </NavLink>

                            */}

                            {/* <a className="iconeNavbar" href="http://blogsimbiotico.com/">

                                Notícias

                            </a> */}

                            |

                            <a className="iconeNavbarAcao" href="https://sistema.simbiose.social">

                                Login

                            </a>

                            {/*<span className="iconeNavbarAcao-servico" onClick={() => this.props.ativaPopUpContratar()}>

                                Contratar serviço

                            </span>*/}

                        </div>

                        {

                            this.state.proponenteInfo &&

                                <NavLink to={{pathname: "/plataforma-de-projetos", search: this.props.location.search}} className="proponenteRadar" onMouseEnter={() => this.exibeProponenteInfo()} onMouseLeave={() => this.escondeProponenteInfo()}>Radar de patrocinadores</NavLink>

                        }

                        {

                            this.state.empresaInfo &&

                                <NavLink to={{pathname: "/patrocinadores", search: this.props.location.search}} className="empresaRadar" onMouseEnter={() => this.exibeEmpresaInfo()} onMouseLeave={() => this.escondeEmpresaInfo()}>Gestão de projetos</NavLink>

                        }

                        <div className="imagemMenuMobile" onClick={() => this.props.ativaMenuMobile()}>

                            {

                                this.props.menuMobileAtivo ?

                                    <img alt="hamburguerMobile" className="imagemMenuMobileTag" src={MenuMobileAtivo} />

                                :

                                    <img alt="hamburguerMobile" className="imagemMenuMobileTag" src={MenuMobileInativo} />

                            }

                        </div>

                    </div>

                </div>
            )

        }
    }

export default withRouter(Navbar);