import React from 'react'
import { NavLink } from "react-router-dom";

    class PageNotFound extends React.Component {

        constructor() {

            super()

            this.setState = {

            }

        }

        render() {

            return (

                <div>

                    <div className="paginaNaoEncontrada">
                        
                        <div className="bannerTexto">

                            <div className="bannerTextoTitulo">

                                Não encontramos essa página :(

                            </div>

                            <div className="bannerTextoSubtitulo">

                                Infelizmente a página que você procurou não existe no nosso site. 
                                
                            </div>

                            <div className="botoesContainer">

                            <NavLink className="BannerBotao" to="/">

                                Início

                            </NavLink>

                            </div>

                        </div>

                    </div>

                </div>

            )
            
        }

    }

export default PageNotFound;