import * as React from "react"
import Planos from './componentesPagemento/Planos'
import {AXIOS} from '../../service/Base'
import {valores} from '../../service/Base'
import Pagamento from './componentesPagemento/Pagamento'
import Confirmacao from './componentesPagemento/Confirmacao'
import ErroNoPagamento from './componentesPagemento/ErroNoPagamento'
import PagamentoRejeitado from './componentesPagemento/PagamentoRejeitado'
import AgendamentoWebnar from './componentesPagemento/AgendamentoWebnar'
import { pusher } from '../../service/pusherConfig'
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
//import { Redirect } from 'react-router-dom'
/*
import * as ReactGA from 'react-ga';
ReactGA.initialize('UA-178033988-1');
*/


class PopUpPagamento extends React.Component {
    axiosSource

    constructor(props){
        super(props);

        this.state = {
            plano: 1,
            etapa: 1,
            descricaoSelecionado: '',
            valorSelecionado: '',        
            periodoSelecionado: '',
            descricaoAVistaSelecionado: '',
            precoParceladoSelecionado: '',
            precoAVistaSelecionado: '',
            valoresAtivos: '',
            idPlanoSelecionado: '',
            confirmadoPediodo: '',
            confirmadoQtdUsuarios: '', 
            confirmadoValor: '',
            confirmacaoTipoCompra: '',
            confirmadoBoletoLink: '',
            confirmadoBoletoLinhaDigitavel: '',
            loadingInfoPusher: false,
            confirmadoCodigoTransacao: '',
            erroNoPagamento: false,
            erroNoPagamentoString: '',
            verificandoEtapa: false,
            pagamentoRejeitado: false,
            nome: '',
            cnpjCpf: '',
            mudaPraCnpj: '',
            email: '',
            verificandoCadastroExistente: false,
            planoSelecionadoProponente: '',
            stPlano: '',
            linkPagamento: '',
            card: ''
        }
    }

    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    }

    componentWillMount() {

        window.location.replace('https://simbi.social/');

        const quantidadeUsuarios = this.state.plano === 1 ? 1 : this.state.plano === 2 ? 3 : 5

        valores.usuarios.map((usuario) => {

            return (

                usuario.qtdUser === quantidadeUsuarios && 

                    this.setState({   
                        valoresAtivos: {
                            'qtdUser': usuario.qtdUser,
                            'mensal': {
                                'planId': usuario.mensal.plan_id,
                                'preco': usuario.mensal.preco,
                                'descricao': usuario.mensal.descricao,  
                                'preco_parcelado': usuario.mensal.parcelado,
                                'descricao_a_vista': usuario.mensal.descricao_a_vista,
                                'preco_a_vista': usuario.mensal.a_vista,
                            },
                            'semestral': {
                                'planId': usuario.semestral.plan_id,
                                'preco': usuario.semestral.preco,
                                'descricao': usuario.semestral.descricao,  
                                'preco_parcelado': usuario.semestral.parcelado,
                                'descricao_a_vista': usuario.semestral.descricao_a_vista,
                                'preco_a_vista': usuario.semestral.a_vista,
                            },
                            'anual': {
                                'planId': usuario.anual.plan_id,
                                'preco': usuario.anual.preco,
                                'descricao': usuario.anual.descricao,  
                                'preco_parcelado': usuario.anual.parcelado,
                                'descricao_a_vista': usuario.anual.descricao_a_vista,
                                'preco_a_vista': usuario.anual.a_vista,
                            },
                        },
                    })
                
            )
            
        })

        //this.inscreverNosCanaisDePagamento(this.props.idCliente)

        this.verificarEtapaUsuario()

    }

    componentDidMount() {
        
        //this.props.history.push(`?popUpPagamento`)

        if(this.props.location.state && this.props.location.state.precoAVistaSelecionado) {

            this.setState({
                etapa: 2,
            })

            this.planoSelecionado({
                'valor': 50,
                'periodo': this.props.location.state.periodoAssinatura,
                'descricao': this.props.location.state.parcelamento[1],
                'descricao_a_vista': this.props.location.state.precoAVistaSelecionado,
                'preco_parcelado': this.props.location.state.precoParceladoSelecionado,
                'preco_a_vista': this.props.location.state.precoAVistaSelecionado,
                'id_plano': this.props.location.state.idPlanoSelecionado
              })
            
        }

    }

    verificarEtapaUsuario = (validador) => {

        this.setState({
            verificandoEtapa: true,
            loadingInfoPusher: false,
        })

        AXIOS().get('assinatura', { 	
 
         })
         .then((response) => {
            console.log(response)
             //const etapa = response.data.data.st_status
             const etapa = response.data.data.st_status

             switch(etapa){
                 case 'processing': // PAGINA DE BOLETO
                    return (
                        this.setState({
                            confirmadoBoletoLink: response.data.data.data.data.charge.print_url,
                            confirmadoBoletoLinhaDigitavel: response.data.data.data.data.charge.last_transaction.gateway_response_fields.typable_barcode,
                            confirmadoCodigoTransacao: response.data.data.data.data.charge.bill.id,

                            confirmadoPediodo: response.data.data.periodo,
                            confirmadoQtdUsuarios: response.data.data.quantidadeUsuarios, 
                            confirmadoValor: response.data.data.valor,

                            etapa: 3,
                            confirmacaoTipoCompra: 'boleto',
                            verificandoEtapa: false,
                        })
                     );
                case 'waiting': // PAGINA DE BOLETO
                    return (
                        this.setState({
                            confirmadoBoletoLink: response.data.data.data.data.charge.print_url,
                            confirmadoBoletoLinhaDigitavel: response.data.data.data.data.charge.last_transaction.gateway_response_fields.typeable_barcode,
                            confirmadoCodigoTransacao: response.data.data.data.data.charge.bill.id,

                            confirmadoPediodo: response.data.data.periodo,
                            confirmadoQtdUsuarios: response.data.data.quantidadeUsuarios, 
                            confirmadoValor: response.data.data.valor,

                            etapa: 3,
                            confirmacaoTipoCompra: 'boleto',
                            verificandoEtapa: false,
                        })
                     );
                case 'rejected': // PAGAMENTO REJEITADO - CRIAR PÁGINA
                    return (
                        validador ?

                            this.setState({
                                etapa: 3,
                                verificandoEtapa: false,
                                pagamentoRejeitado: true,
                            })

                        :

                            this.setState({
                                verificandoEtapa: false,
                            })
                            
                    );
                 default: return  (
                    this.setState({
                        verificandoEtapa: false,
                        confirmadoCodigoTransacao: response.data.data.data.data.charge.bill.id,
                    })
                 );
             }
         })
         .catch((error) => {
             console.log(error)
             this.setState({
                verificandoEtapa: false,                 
             })           
             console.log('DEU ERRADO ETAPA USUARIO') 
         })

    }
        
    inscreverNosCanaisDePagamento = () => {
        let nomeCanal = "private-pagamento."+this.props.idCliente;
        let channel = pusher.subscribe(nomeCanal);
        console.log('conectado no canal : EventoVindi '+nomeCanal);
        channel.unbind();
        channel.bind('EventoVindi', (data) => {
                //
                console.log('dataPusher > ',data)
                //this.carregarNotificacoes();
                //atualizar notificacao

                console.log('PUSHER TIPO > ', data.tipo)

                switch(data.tipo){
                    case 'bill_paid': 
                        return (
                            this.setState({
                                loadingInfoPusher: false,
                                confirmadoCodigoTransacao: data.evento.data.charge.bill.id,
                            })
                        );
                    case 'charge_created': 
                        return (
                            this.setState({
                                confirmadoBoletoLink: data.evento.data.charge.print_url,
                                confirmadoBoletoLinhaDigitavel: data.evento.data.charge.last_transaction.gateway_response_fields.typeable_barcode,
                                confirmadoCodigoTransacao: data.evento.data.charge.bill.id,
                                loadingInfoPusher: false,
                            })
                        );
                    case 'charge_rejected': 
                        return (
                            this.setState({
                                erroNoPagamento: true,
                                erroNoPagamentoString: data.evento.data.charge.last_transaction.gateway_message,
                                loadingInfoPusher: false,
                            })
                        );
                    default: return 
                }
        });
    }

    

    alteraPlano = (plano) => {

      const quantidadeUsuarios = plano === 1 ? 1 : plano === 2 ? 3 : 5

        valores.usuarios.map((usuario) => {

            return (

                usuario.qtdUser === quantidadeUsuarios && 

                    this.setState({
                        plano: plano,
                        valoresAtivos: {
                            'qtdUser': usuario.qtdUser,
                            'mensal': {
                                'planId': usuario.mensal.plan_id,
                                'preco': usuario.mensal.preco,
                                'descricao': usuario.mensal.descricao,  
                                'preco_parcelado': usuario.mensal.parcelado,
                                'descricao_a_vista': usuario.mensal.descricao_a_vista,
                                'preco_a_vista': usuario.mensal.a_vista,
                            },
                            'semestral': {
                                'planId': usuario.semestral.plan_id,
                                'preco': usuario.semestral.preco,
                                'descricao': usuario.semestral.descricao,  
                                'preco_parcelado': usuario.semestral.parcelado,
                                'descricao_a_vista': usuario.semestral.descricao_a_vista,
                                'preco_a_vista': usuario.semestral.a_vista,
                            },
                            'anual': {
                                'planId': usuario.anual.plan_id,
                                'preco': usuario.anual.preco,
                                'descricao': usuario.anual.descricao,  
                                'preco_parcelado': usuario.anual.parcelado,
                                'descricao_a_vista': usuario.anual.descricao_a_vista,
                                'preco_a_vista': usuario.anual.a_vista,
                            },
                        },
                    })
                
            )
            
        })

    }

    proximaEtapa = () => {

        this.setState({
            etapa: this.state.etapa + 1,
        })

    }
    
    planoSelecionado = (dados) => {

        console.log(dados)
        
        this.setState({    
            valorSelecionado: dados.valor,        
            periodoSelecionado: dados.periodo,
            descricaoSelecionado: dados.descricao,
            descricaoAVistaSelecionado: dados.descricao_a_vista,
            precoParceladoSelecionado: dados.preco_parcelado,
            precoAVistaSelecionado: dados.preco_a_vista,
            etapa: 2,
            idPlanoSelecionado: dados.id_plano,
            planoSelecionadoProponente: dados.planoSelecionadoProponente,
            stPlano: dados.stPlano,
            linkPagamento: dados.linkPagamento,
            card: dados.card
        })

    }

    agendarDataWebnar = () => {

        this.setState({
            etapa: 4
        })
        
    }

    voltarPlanos = () => {

        this.setState({
            etapa: 2,
            pagamentoRejeitado: false,
            erroNoPagamento: false,
        })

    }

    voltarSelecionarPlanos = () => {

        this.setState({
            etapa: 1,
        })

    }

    voltarPagamento = () => {

        this.setState({
            etapa: 2,
            erroNoPagamento: false,
            pagamentoRejeitado: false,
        })

    }

    pagamentoConfirmado = (periodicidade, qtdUsuarios, valor, tipoCompra) => {

        this.setState({
            etapa: 3,
            loadingInfoPusher: true,
            confirmadoPediodo: periodicidade,
            confirmadoQtdUsuarios: qtdUsuarios, 
            confirmadoValor: valor,
            confirmacaoTipoCompra: tipoCompra ? 'cartao' : 'boleto'
        })

        setTimeout(
            function() {
                if(this.state.loadingInfoPusher) {
                    console.log('PUSHER TIME OUT CHECK STEP')
                    this.verificarEtapaUsuario(true)
                    this.setState({
                        realizandoPagamento: false
                    })
                }
            }
            .bind(this),
            10000
        );

    }

    fecharPopUpPagamento = () => {

        this.props.exibirPopUpPagamento()

        if(this.state.etapa !== 3) {     

            this.props.history.push(`?pagamentoNaoRealizado&Etapa=${this.state.etapa}`)

            //ReactGA.pageview('?pagamentoRealizado');

        }
        
    }
    
    render() {

        const iconDone = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none"> <path d="M6 13.5L5.5038 13.9199L6.09797 14.6221L6.56146 13.8275L6 13.5ZM13.5615 1.82752C13.7423 1.51743 13.6376 1.11943 13.3275 0.938544C13.0174 0.757662 12.6194 0.8624 12.4385 1.17248L13.5615 1.82752ZM0.00379893 7.41986L5.5038 13.9199L6.4962 13.0801L0.996201 6.58014L0.00379893 7.41986ZM6.56146 13.8275L13.5615 1.82752L12.4385 1.17248L5.43854 13.1725L6.56146 13.8275Z" fill="white"/> </svg>

        const voltar = <svg style={{marginRight: '5px'}} xmlns="http://www.w3.org/2000/svg" width="6" height="14" viewBox="0 0 6 14" fill="none">
        <path d="M5.1084 1.55396L1.00034 6.81482L5.1084 12.554" stroke="#1F99A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
            return (

                    <>
                    
                        <div className="popUpPagamento">

                            <div className="popUpPagamentoContainer">

                                <div className="popUpPagamentoHeader">

                                    <div className="popUpPagamentoHeaderConteudo">

                                        <div className="popUpPagamentoHeaderLogo">
                                            
                                            {/*<img alt="logosimbi" src={logoSimbi} />*/}
                                            
                                        </div>

                                        <div className="popUpPagamentoHeaderSteps">
                                            
                                            <div className="popUpPagamentoHeaderStep">

                                                <div className="popUpPagamentoHeaderStepNumero" style={{backgroundColor: this.state.etapa >= 1 && '#1F99A7', color: this.state.etapa >= 1 && '#FFF'}}>

                                                    {this.state.etapa === 1 ? '1' : this.state.etapa > 1 && iconDone}

                                                </div>

                                                <div className="popUpPagamentoHeaderStepString"style={{color: this.state.etapa >= 1 && '#1F99A7'}}>

                                                    ESCOLHA SEU PLANO

                                                </div>

                                            </div>
                                            
                                            <div className="popUpPagamentoHeaderStep">

                                                <div className="popUpPagamentoHeaderStepNumero" style={{backgroundColor: this.state.etapa >= 2 && '#1F99A7', color: this.state.etapa >= 2 && '#FFF'}}>

                                                    {this.state.etapa <= 2 ? '2' : this.state.etapa > 2 && iconDone}

                                                </div>

                                                <div className="popUpPagamentoHeaderStepString"style={{color: this.state.etapa >= 2 && '#1F99A7'}}>

                                                    PAGAMENTO

                                                </div>

                                            </div>
                                            
                                            <div className="popUpPagamentoHeaderStep">

                                                <div className="popUpPagamentoHeaderStepNumero" style={{backgroundColor: this.state.etapa >= 3 && '#1F99A7', color: this.state.etapa >= 3 && '#FFF'}}>

                                                    {this.state.etapa <= 3 ? '3' : this.state.etapa > 3 && iconDone}

                                                </div>

                                                <div className="popUpPagamentoHeaderStepString"style={{color: this.state.etapa >= 3 && '#1F99A7'}}>

                                                    CONFIRMAÇÃO

                                                </div>

                                            </div>

                                            {/*
                                            
                                                <div className="popUpPagamentoHeaderStep sair" onClick={() => this.fecharPopUpPagamento()}>

                                                    <div className="popUpPagamentoHeaderStepNumero">

                                                        X

                                                    </div>

                                                    <div className="popUpPagamentoHeaderStepString">

                                                        SAIR

                                                    </div>

                                                </div>

                                            */}
                                            
                                        </div>

                                    </div>

                                </div>

                                <div className="popUpPagamentoConteudoContainer">

                                    <div className="popUpPagamentoConteudo">

                                        {

                                            this.state.verificandoEtapa &&

                                                <div className="paginaPagamentoProcessandoPedido">

                                                    Aguarde...

                                                </div>

                                        }

                                        {

                                            this.state.etapa === 1 &&
                                            
                                                    <Planos agendarDataWebnar={this.agendarDataWebnar} alteraPlano={this.alteraPlano} verificandoEtapa={this.state.verificandoEtapa} plano={this.state.plano} planoSelecionado={this.planoSelecionado} infoCard={this.state.valoresAtivos} />

                                        }
                                        
                                        {

                                            this.state.verificandoCadastroExistente ?

                                                <div className="paginaPagamentoProcessandoPedido">

                                                    Aguarde...

                                                </div>

                                            :

                                                this.state.etapa === 2 &&

                                                    <Pagamento 
                                                        parcelamento={[this.state.descricaoSelecionado, this.state.descricaoAVistaSelecionado]} 
                                                        pediodoAssinatura={this.state.periodoSelecionado} 
                                                        precoParceladoSelecionado={this.state.precoParceladoSelecionado}
                                                        precoAVistaSelecionado={this.state.precoAVistaSelecionado}
                                                        idPlano={this.state.idPlanoSelecionado}
                                                        quantidadeUsuarios={this.state.valoresAtivos.qtdUser}
                                                        valores={valores}
                                                        pagamentoConfirmado={this.pagamentoConfirmado}
                                                        inscreverNosCanaisDePagamento={this.inscreverNosCanaisDePagamento}
                                                        planoSelecionadoProponente={this.state.planoSelecionadoProponente}
                                                        stPlano={this.state.stPlano}
                                                        linkPagamento={this.state.linkPagamento}
                                                        card={this.state.card}
                                                    />

                                        }
                                        
                                        {

                                            this.state.etapa === 3 && !this.state.verificandoEtapa &&

                                                <>

                                                    {
                                                    
                                                        this.state.loadingInfoPusher ?

                                                            <div className="paginaPagamentoProcessandoPedido">

                                                                Estamos processando o seu pedido...

                                                            </div>

                                                        :

                                                            this.state.erroNoPagamento ?

                                                                <ErroNoPagamento 
                                                                    erro={this.state.erroNoPagamentoString} 
                                                                    voltarPagamento={this.voltarPagamento}    
                                                                />

                                                            :

                                                                this.state.pagamentoRejeitado ?
                    
                                                                        <PagamentoRejeitado 
                                                                            voltarPlanos={this.voltarPlanos}
                                                                        />

                                                                    :

                                                                        <Confirmacao 
                                                                            periodo={this.state.confirmadoPediodo}
                                                                            qtdUsuarios={this.state.confirmadoQtdUsuarios}
                                                                            valor={this.state.confirmadoValor}
                                                                            tipoCompra={this.state.confirmacaoTipoCompra}
                                                                            BoletoLink={this.state.confirmadoBoletoLink}
                                                                            linhaDigitavel={this.state.confirmadoBoletoLinhaDigitavel}
                                                                            codigoTransacao={this.state.confirmadoCodigoTransacao}
                                                                        />
                                                            
                                                    }

                                                </>

                                        }

                                        {

                                            this.state.etapa === 4 &&

                                                <AgendamentoWebnar />

                                        }

                                    </div>

                                </div>
                                        
                                {

                                    (this.state.etapa === 2 || this.state.etapa === 4) &&

                                    <div className="popUpPagamentoMensagemVoltarContainer">
        
                                        <div className="popUpPagamentoMensagemVoltarConteudo">
        
                                            <div className="popUpPagamentoMensagemVoltarMensagem" onClick={() => this.voltarSelecionarPlanos()}>

                                                {voltar} voltar a escolher planos
        
                                            </div>
                                            
                                        </div>
        
                                    </div>

                                }

                            </div>

                        </div>

                    </>

        )
    }
}

export default withRouter(PopUpPagamento);