import React from 'react'
import { CLIENT_ID } from '../../service/Base'
import { CLIENT_SECRET } from '../../service/Base'
import { AXIOS } from '../../service/Base'
import { Redirect, Link } from 'react-router-dom'

    class Login extends React.Component {

        constructor() {

            super()

            this.state = {
                email: '',
                password: '',
                logging: false,
                logado: false,
                redireciona: ''
            }

        }

        componentWillMount() {

           window.scrollTo(0, 0)

       }

        componentDidMount() {
            
            const storagedUser = localStorage.getItem('@oauth:user');
            const storagedToken = localStorage.getItem('@oauth:token');

            if(storagedUser && storagedToken) {

                this.setState({
                    logado: true
                })

            }

            this.setState({
                redireciona: this.props.location.state?.from
            })
        
        }

        handleChange = (e) => {

            const value = e.target.value
            const input = e.target.name

            this.setState({
                [input]: value
            })

        }

        realizaLogin = (e) => {

            this.setState({logging: true})

            e.preventDefault()

            const credentials = {
                client_id: CLIENT_ID,
                client_secret: CLIENT_SECRET,
                username: this.state.email,
                password: this.state.password,
                grant_type: "password"
            }

            AXIOS().post('oauth/token', credentials)
            .then((response) => {
                localStorage.setItem('@oauth:user', JSON.stringify(response.data.user));
                localStorage.setItem('@oauth:token', response.data.access_token);
                this.setState({
                    //informacoesBox: response.data.data
                    logging: false,
                    logado: true
                })
            })
            .catch((error) => {
                console.log(error)
            })  

        }

        render() {

            const path = this.state.redireciona

            return (

                this.state.logado ? 

                    <Redirect to={{
                        pathname: `${path ? `/${path}` : `/reagendamento`}`,
                        state: this.props.location.state                        
                    }} />

                :

                    <div className="paginaLoginSection">

                        <div className="paginaLoginContainer">

                            <div className="paginaLoginContent">

                                <div className="paginaLoginBox">
                        
                                    <div className="paginaLoginBoxTitle">
                                        
                                        Realize o login para continuar

                                    </div>

                                    <form onSubmit={(e) => this.realizaLogin(e)}>

                                        <input className="paginaLoginInput" type="email" name="email" placeholder="meuemail@gmail.com" onChange={(e) => this.handleChange(e)} />

                                        <input className="paginaLoginInput" type="password" name="password" placeholder="********" onChange={(e) => this.handleChange(e)} />

                                        <input className="paginaLoginSubmit" style={{pointerEvents: this.state.logging ? 'none' : 'auto', color: this.state.logging ? 'rgb(128, 128, 128)' : '', backgroundColor: this.state.logging ? 'rgb(235, 235, 235)' : ''}} type="submit" value={this.state.logging ? "Logando..." : "Login"} />

                                    </form>

                                    <div className="paginaLoginFooter">

                                        <Link className="paginaLoginFooterAction" 
                                            to={{
                                                pathname: "/cadastro",
                                                state: { 
                                                    planoSelecionadoProponente: this.props.location.state ? this.props.location.state.planoSelecionadoProponente : '',
                                                    stPlano: this.props.location.state ? this.props.location.state.stPlano : 'Gratis',
                                                    linkPagamento: this.props.location.state ? this.props.location.state.linkPagamento : '',
                                                    card: this.props.location.state ? this.props.location.state.card : 'Gratis'
                                                }
                                            }}>
                                                
                                            Não tem uma conta? Registre-se!
                                            
                                        </Link>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

            )

        }

    }

export default Login