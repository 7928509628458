import React from 'react'
import PropTypes from 'prop-types';

    class PopUpVideoApresentacao extends React.Component {

        constructor() {

            super()

            this.state = {



            }

            this.popUpFecha = this.popUpFecha.bind(this);
            this.ClickForaDaDiv = this.ClickForaDaDiv.bind(this);

        }

        componentDidMount() {

            document.addEventListener('mousedown', this.ClickForaDaDiv);

        }

        componentWillUnmount() {

            document.removeEventListener('mousedown', this.ClickForaDaDiv);

        }
        
        popUpFecha(node) {

            this.wrapperRef = node;

        }

        ClickForaDaDiv(event) {

            if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {

                this.props.fechaPopUpVideo()

            }

        }

        render() {

            return (

                <div ref={this.popUpFecha} className="popUpVideoApresentacao">
    
                  <div className="fechaPopUpVideo" onClick={this.props.fechaPopUpVideo}>
    
                    <img alt="sair" src={this.props.sair} />
    
                  </div>
    
                  <iframe title="apresentacaoSimbiose" className="iframeVideoApresentacao" src="https://www.youtube.com/embed/evHHJAtbEMI" />
    
                </div>

            )

        }

    }

export default PopUpVideoApresentacao;

PopUpVideoApresentacao.propTypes = {
  children: PropTypes.element.isRequired,
};