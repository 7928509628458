import * as React from "react"
//import {AXIOS as AxiosRequest, getAxiosSource, AXIOS} from '../../service/base'


export default class Planos extends React.Component {
    axiosSource

    constructor(props) {
        super(props);

        this.state = {
            valoresAtivos: {
                'qtdUser': 1,
                'preco_mensal': '199,00',
                'preco_semestral': '139,00',
                'preco_anual': '99,00',
                'descricao_mensal': '12x de R$99,00',
                'descricao_semestral': '6x de R$139,00',
                'descricao_anual': '1x de R$199,00',
            }
        }

    }

    componentWillMount() {
        window.location.replace('https://simbi.social/')
    }

    componentDidMount() {

    }

    componentWillReceiveProps() {

    }

    selecionaPlano = (valor, periodoSelecionado, descricao, aVista, precoParcelado, precoAVista, idPlano, planoSelecionadoProponente, stPlano, linkPagamento, card) => {

        this.props.planoSelecionado({
            'valor': valor,
            'periodo': periodoSelecionado,
            'descricao': descricao,
            'descricao_a_vista': aVista,
            'preco_parcelado': precoParcelado,
            'preco_a_vista': precoAVista,
            'id_plano': idPlano,
            'planoSelecionadoProponente': planoSelecionadoProponente,
            'stPlano': stPlano,
            'linkPagamento': linkPagamento,
            'card': card
        })

    }



    render() {

        return (

            <>

                <div style={{ display: this.props.verificandoEtapa && 'none', width: '100%' }} className="paginaPropoentesPlanosProponenteContainer">

                    <div className="paginaPropoentesPlanosProponenteTexto" style={{ marginTop: '2%' }}>

                        Escolha o plano alinhado a você

                    </div>
                    <div className="paginaPropoentesPlanosProponentePlanosHeader" style={{ marginTop: '20px' }}>



                        <div className="paginaPropoentesPlanosProponentePlano"
                            onClick={() => this.props.alteraPlano(1)}
                            style={{
                                backgroundColor: this.props.plano === 1 ? '#1F99A7' : '',
                                color: this.props.plano === 1 ? '#FFF' : ''
                            }}>

                            <span className="paginaPropoentesPlanosTextoBold">1</span> USUÁRIO

                        </div>


                        <div className="paginaPropoentesPlanosProponentePlano"
                            onClick={() => this.props.alteraPlano(2)}
                            style={{
                                backgroundColor: this.props.plano === 2 ? '#1F99A7' : '',
                                color: this.props.plano === 2 ? '#FFF' : ''
                            }}>

                            <span className="paginaPropoentesPlanosTextoBold">3</span> USUÁRIOS

                            </div>

                        <div className="paginaPropoentesPlanosProponentePlano"
                            onClick={() => this.props.alteraPlano(3)}
                            style={{
                                backgroundColor: this.props.plano === 3 ? '#1F99A7' : '',
                                color: this.props.plano === 3 ? '#FFF' : ''
                            }}>

                            <span className="paginaPropoentesPlanosTextoBold">5</span> USUÁRIOS

                            </div>


                    </div>


                    <div className="paginaPropoentesPlanosProponenteCards">

                        {/*

                            <div className="paginaPropoentesPlanosProponenteCard" style={{marginTop: '40px'}}>

                                <div className="paginaPropoentesPlanosProponenteCardPeriodicidade">

                                    APRESENTAÇÃO DA PLATAFORMA

                                </div>

                                <div className="barraDivideCardProponentePlanos" />

                                <div className="paginaPropoentesPlanosProponenteCardPreco">

                                    <div className="paginaPropoentesPlanosProponenteCardValor">

                                        <span className="paginaPropoentesPlanosProponenteCardPrecoRS"></span>

                                        Grátis

                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorDescricao">

                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorTotal">

                                    </div>

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudo">

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Escolha o melhor dia para você e participe do tutorial com a nossa equipe Simbiótica!

                                    </div>

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardFooter">

                                    <div className="paginaPropoentesPlanosProponenteCardFooterConteudo">

                                        <div className="paginaPropoentesPlanosProponenteCardFooterBotao" onClick={() => this.props.agendarDataWebnar()}>

                                            Agendar

                                        </div>

                                        <div className="paginaPropoentesPlanosProponenteCardFooterMensagem">
                                                Ao selecionar o plano, você aceita os <a href="https://s3simbiosesocial2019.s3.amazonaws.com/TC/20200526+T%26C+e+Privacidade+Radar+de+Patrocinadores+Simbiose+v1.pdf" style={{ color: '#606A73', textDecoration: 'underline' }}>Termos e Condições</a> e o
                                                    <a href={this.props.plano === 1 ? 'https://s3simbiosesocial2019.s3.amazonaws.com/TC/Termo+de+Adesa%CC%83o+Radar+de+Patrocinadores+1+usua%CC%81rio.pdf' :
                                                            this.props.plano === 2 ? 'https://s3simbiosesocial2019.s3.amazonaws.com/TC/Termo+de+Adesa%CC%83o+Radar+de+Patrocinadores+3+usua%CC%81rios.pdf' : 'https://s3simbiosesocial2019.s3.amazonaws.com/TC/Termo+de+Adesa%CC%83o+Radar+de+Patrocinadores+5+usua%CC%81rios.pdf'} style={{ color: '#606A73', textDecoration: 'underline' }}> Termo de Adesão</a>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            */}

                        <div className="paginaPropoentesPlanosProponenteCard" style={{
                            backgroundColor: '#1F99A7',
                            color: '#FFF',
                            marginTop: '40px'
                        }}>

                            <div className="paginaPropoentesPlanosProponenteCardPeriodicidade" style={{
                                color: '#FFF'
                            }}>

                                ASSINATURA ANUAL

                                </div>

                            <div className="barraDivideCardProponentePlanos" />

                            <div className="paginaPropoentesPlanosProponenteCardPreco">



                                <div className="paginaPropoentesPlanosProponenteCardValor" style={{
                                    color: '#FFF'
                                }}>

                                    <span className="paginaPropoentesPlanosProponenteCardPrecoRS">R$</span>

                                    {

                                        this.props.infoCard.anual.preco

                                    }

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardValorDescricao" style={{
                                    color: '#FFF'
                                }}>

                                    mensais por usuário

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardValorTotal" style={{
                                    color: '#FFF'
                                }}>

                                    {

                                        this.props.infoCard.anual.descricao

                                    }

                                </div>

                            </div>

                            <div className="paginaPropoentesPlanosProponenteCardConteudo" style={{
                                color: '#FFF'
                            }}>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    {

                                        this.props.plano === 1 ? 'Desconto de 50%' :
                                            this.props.plano === 2 ? 'Desconto de 60%' : 'Desconto de 65%'

                                    }

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    Mais de 62 mil patrocinadores

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    Dados consolidados em relatórios

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    Dicas de aporte

                                    </div>

                            </div>

                            <div className="paginaPropoentesPlanosProponenteCardFooter">

                                <div className="paginaPropoentesPlanosProponenteCardFooterConteudo">

                                    <div onClick={() => this.selecionaPlano(

                                        this.props.infoCard.anual.preco,
                                        'Anual',
                                        this.props.infoCard.anual.descricao,
                                        this.props.infoCard.anual.descricao_a_vista,
                                        this.props.infoCard.anual.preco_parcelado,
                                        this.props.infoCard.anual.preco_a_vista,
                                        this.props.infoCard.anual.planId,
                                        this.props.plano,
                                        'Anual',

                                        this.props.plano === 1 ?
                                            'https://app.vindi.com.br/customer/pages/204ba81b-412b-4ed9-8b47-f9c460d87dc8/subscriptions/'
                                            :
                                            this.props.plano === 2 ?
                                                'https://app.vindi.com.br/customer/pages/6ecbe97e-4e53-45b3-966c-968c552e44f7/subscriptions/new'
                                                :
                                                this.props.plano === 3 ?
                                                    'https://app.vindi.com.br/customer/pages/390702ae-0258-4e53-9d5f-9549a2f1eb25/subscriptions/new'
                                                    :
                                                    '',
                                        1

                                    )} className="paginaPropoentesPlanosProponenteCardFooterBotao" style={{
                                        backgroundColor: '#FFF',
                                        color: '#71CAD4'
                                    }}>

                                        Selecionar o plano

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardFooterMensagem" style={{
                                        color: '#FFF'
                                    }}>
                                        Ao selecionar o plano, você aceita os <a target="_blank" href="https://simbiose.social/politica-de-privacidade" style={{ color: '#FFF' }}>Termos e Condições</a> e o
                                            <a target="_blank" href={this.props.plano === 1 ? 'https://simbiose.social/politica-de-privacidade' :
                                            this.props.plano === 2 ? 'https://simbiose.social/politica-de-privacidade' : 'https://simbiose.social/politica-de-privacidade'} style={{ color: '#FFF' }}> Termo de Adesão</a>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="paginaPropoentesPlanosProponenteCard" style={{ marginTop: '40px' }}>

                            <div className="paginaPropoentesPlanosProponenteCardPeriodicidade">

                                ASSINATURA SEMESTRAL

                                </div>

                            <div className="barraDivideCardProponentePlanos" />

                            <div className="paginaPropoentesPlanosProponenteCardPreco">

                                <div className="paginaPropoentesPlanosProponenteCardValor">

                                    <span className="paginaPropoentesPlanosProponenteCardPrecoRS">R$</span>

                                    {

                                        this.props.infoCard.semestral.preco

                                    }

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardValorDescricao">

                                    mensais por usuário

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardValorTotal">

                                    {

                                        this.props.infoCard.semestral.descricao

                                    }

                                </div>

                            </div>

                            <div className="paginaPropoentesPlanosProponenteCardConteudo">

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    {

                                        this.props.plano === 1 ? 'Desconto de 30%' :
                                            this.props.plano === 2 ? 'Desconto de 45%' : 'Desconto de 50%'

                                    }

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    Mais de 62 mil patrocinadores

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    Dados consolidados em relatórios

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    Dicas de aporte

                                    </div>

                            </div>

                            <div className="paginaPropoentesPlanosProponenteCardFooter">

                                <div className="paginaPropoentesPlanosProponenteCardFooterConteudo">

                                    <div onClick={() => this.selecionaPlano(

                                        this.props.infoCard.semestral.preco,
                                        'Semestral',
                                        this.props.infoCard.semestral.descricao,
                                        this.props.infoCard.semestral.descricao_a_vista,
                                        this.props.infoCard.semestral.preco_parcelado,
                                        this.props.infoCard.semestral.preco_a_vista,
                                        this.props.infoCard.semestral.planId,

                                        this.props.plano,
                                        'Semestral',

                                        this.props.plano === 1 ?
                                            'https://app.vindi.com.br/customer/pages/d1e18907-8011-4cf4-9773-2b551a6e5221/subscriptions/new'
                                            :
                                            this.props.plano === 2 ?
                                                'https://app.vindi.com.br/customer/pages/c1400904-57fb-4580-8e9a-bb9c86131640/subscriptions/new'
                                                :
                                                this.props.plano === 3 ?
                                                    'https://app.vindi.com.br/customer/pages/8dd43ce7-06b2-44f4-b9f3-221d6427cdcf/subscriptions/new'
                                                    :
                                                    '',
                                        2

                                    )} className="paginaPropoentesPlanosProponenteCardFooterBotao">

                                        Selecionar o plano

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardFooterMensagem">
                                        Ao selecionar o plano, você aceita os <a target="_blank" href="https://simbiose.social/politica-de-privacidade" style={{ color: '#FFF' }}>Termos e Condições</a> e o
                                            <a target="_blank" href={this.props.plano === 1 ? 'https://simbiose.social/politica-de-privacidade' :
                                            this.props.plano === 2 ? 'https://simbiose.social/politica-de-privacidade' : 'https://simbiose.social/politica-de-privacidade'} style={{ color: '#FFF' }}> Termo de Adesão</a>


                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="paginaPropoentesPlanosProponenteCard" style={{ marginTop: '40px' }}>

                            <div className="paginaPropoentesPlanosProponenteCardPeriodicidade">

                                ASSINATURA MENSAL

                                </div>

                            <div className="barraDivideCardProponentePlanos" />

                            <div className="paginaPropoentesPlanosProponenteCardPreco">

                                <div className="paginaPropoentesPlanosProponenteCardValor">

                                    <span className="paginaPropoentesPlanosProponenteCardPrecoRS">R$</span>

                                    {

                                        this.props.infoCard.mensal.preco

                                    }

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardValorDescricao">

                                    mensais por usuário

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardValorTotal">

                                    {

                                        this.props.infoCard.mensal.descricao

                                    }

                                </div>

                            </div>

                            <div className="paginaPropoentesPlanosProponenteCardConteudo">

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    {

                                        this.props.plano === 1 ? '' :
                                            this.props.plano === 2 ? 'Desconto de 20%' : 'Desconto de 30%'

                                    }

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    Mais de 62 mil patrocinadores

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    Dados consolidados em relatórios

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                    Dicas de aporte

                                    </div>

                            </div>

                            <div className="paginaPropoentesPlanosProponenteCardFooter">

                                <div className="paginaPropoentesPlanosProponenteCardFooterConteudo">

                                    <div onClick={() => this.selecionaPlano(

                                        this.props.infoCard.mensal.preco,
                                        'Mensal',
                                        this.props.infoCard.mensal.descricao,
                                        this.props.infoCard.mensal.descricao_a_vista,
                                        this.props.infoCard.mensal.preco_parcelado,
                                        this.props.infoCard.mensal.preco_a_vista,
                                        this.props.infoCard.mensal.planId,

                                        this.props.plano,
                                        'Mensal',

                                        this.props.plano === 1 ?
                                            'https://app.vindi.com.br/customer/pages/97b6626e-ae8c-4f4d-9390-d013ee933865/subscriptions/new'
                                            :
                                            this.props.plano === 2 ?
                                                'https://app.vindi.com.br/customer/pages/93931246-2b19-4372-9831-0bab3cb9049b/subscriptions/new'
                                                :
                                                this.props.plano === 3 ?
                                                    'https://app.vindi.com.br/customer/pages/9632eaa4-62a0-42cf-b43e-77bf0281c142/subscriptions/new'
                                                    :
                                                    '',
                                        3

                                    )} className="paginaPropoentesPlanosProponenteCardFooterBotao">

                                        Selecionar o plano

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardFooterMensagem">
                                        Ao selecionar o plano, você aceita os <a target="_blank" href="https://simbiose.social/politica-de-privacidade" style={{ color: '#FFF' }}>Termos e Condições</a> e o
                                            <a target="_blank" href={this.props.plano === 1 ? 'https://simbiose.social/politica-de-privacidade' :
                                            this.props.plano === 2 ? 'https://simbiose.social/politica-de-privacidade' : 'https://simbiose.social/politica-de-privacidade'} style={{ color: '#FFF' }}> Termo de Adesão</a>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="paginaPropoentesPlanosProponenteDesktopMensagem">

                        * Assinaturas para mais de 5 usuários entre em contato com o nosso atendimento suporte@simbiose.social

                        </div>

                </div>

            </>

        )
    }
}