import * as React from "react"
import {AXIOS} from '../../../service/Base'

export default class AgendamentoWebnar extends React.Component {
    axiosSource

    constructor(props){
        super(props);

        this.state = {
            datasWebnairs: [],
            carregando: false,
            dataSelecionada: '',
            horarioSelecionado: '',
            agendando: false,
            agenadadoComSucesso: false,
            usuario: ''
        }

    }  

    componentDidMount() {

        console.log()

        /*
        
        var md5 = require('md5');

        this.setState({
            usuario: md5(sessionStorage.getItem('id_usuario'))
        })

        this.setState({
            carregando: true
        })

        AXIOS().post('agendamento', {
            "usuario": window.location.href.split('?usuario=')[1],
            _method: "GET"
        })
        .then((response) => {
            console.log(response)
            this.setState({
                datasWebnairs: response.data.data.webinars,
                carregando: false
            })
        })
        .catch((error) => {
            console.log(error)
            this.setState({
                carregando: false
            })
        })     

        */

    }

    exibeData = (data) => {

        const mainDate = data.split(' ')[0];
        const month = mainDate.split('-')[1];
        const day = mainDate.split('-')[2];
        const year = mainDate.split('-')[0];


        return (day+'/'+month+'/'+year)

    }

    selecionaData = (idData) => {

        this.setState({
            dataSelecionada: idData,
            horarioSelecionado: ''
        })

    }

    selecionaHorario = (horario) => {

        this.setState({
            horarioSelecionado: horario
        })

    }

    agendar = () => {

        this.setState({
            agendando: true
        })

        AXIOS().post('inscrever/'+this.state.dataSelecionada, {
            "usuario": this.state.usuario,
            "horario": this.state.horarioSelecionado,     
        })
        .then((response) => {
            console.log(response)
            if(response.data.success){
                this.setState({
                    agendando: false,
                    agenadadoComSucesso: true
                })
            }
        })
        .catch((error) => {
            console.log(error)
            this.setState({
                agendando: false,
                agenadadoComSucesso: false
            })
        })

    }
    
    render() {
        
            return (

                this.state.carregando ?

                <div className="agendamentoWebnar" style={{fontSize: '21px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    Carregando...

                </div>

                :

                this.state.agenadadoComSucesso ?

                    <div className="agendamentoWebnar" style={{fontSize: '21px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        Agendado com sucesso!

                    </div>

                :

                    <>

                        <div className="agendamentoWebnar">

                            <div className="agendamentoWebnarData">

                                <div className="agendamentoWebnarDataTitulo">

                                    Datas disponíveis:

                                </div>

                                {
                                
                                    this.state.datasWebnairs.map((data) => {

                                        return (

                                            <div onClick={() => this.selecionaData(data.id_webinar)} style={{backgroundColor: data.id_webinar === this.state.dataSelecionada && '#71cad4', color: data.id_webinar === this.state.dataSelecionada && '#fff'}} className="agendamentoWebnarDataBox">

                                                {this.exibeData(data.data_webinar)}

                                            </div>

                                        )

                                    })

                                }

                            </div>

                            {

                                this.state.dataSelecionada &&

                                    <>

                                    <div className="agendamentoWebnarData" style={{marginTop: '50px'}}>
        
                                        <div className="agendamentoWebnarDataTitulo">
        
                                            Horários disponíveis:
        
                                        </div>
        
                                        {
                                        
                                            this.state.datasWebnairs.map((data) => {
        
                                                return (

                                                    data.id_webinar === this.state.dataSelecionada &&
            
                                                        data.horarios.map((horario) => {

                                                            return (

                                                                horario &&

                                                                    <div className="agendamentoWebnarDataBox"style={{backgroundColor: horario === this.state.horarioSelecionado && '#71cad4', color: horario === this.state.horarioSelecionado && '#fff'}}  onClick={() => this.selecionaHorario(horario)}>

                                                                        {horario}

                                                                    </div>

                                                            )

                                                        })
        
                                                )
        
                                            })
        
                                        }
        
                                    </div>

                                    </>

                            }

                            {

                                this.state.dataSelecionada && this.state.horarioSelecionado &&

                                    <div className="agendamentoBotaoAgendar" style={{marginTop: '50px'}} onClick={() => this.agendar()}>

                                        {this.state.agendando ? 'Agendando...' : 'Agendar'}

                                    </div>

                            }

                        </div>

                    </>

        )
    }
}