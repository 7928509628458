import React from 'react'
import Logo from '../images/Simbiose_Social.png'
import { AXIOS } from '../service/Base'
import { NavLink } from "react-router-dom";

class Footer extends React.Component {

    constructor() {

        super()

        this.state = {
            nome: '',
            email: '',
            emailValidador: false,
            tipoPessoa: 'empresa',
            loaderRegistrandoNewsletter: false,
            erroRegistrandoNewsletter: false,
            registradoComSucesso: false,
        }

    }

    /*

    POST /registernewsletter

        {
            "st_nome": "TESTE EMAIL",*
            "st_email": "emailteste@teste.com.br",*
            "st_tipo": "blablabla"*
        }

    * campos com asterisco são obrigatórios.


    */

    setaNome = (text) => {

        this.setState({
            nome: text.target.value
        })

    }

    setaEmail = (text) => {

        this.setState({
            email: text.target.value
        })

        const usuario = text.target.value.substring(0, text.target.value.indexOf("@"));
        const dominio = text.target.value.substring(text.target.value.indexOf("@") + 1, text.target.value.length);

        if ((usuario.length >= 1) &&
            (dominio.length >= 3) &&
            (usuario.search("@") === -1) &&
            (dominio.search("@") === -1) &&
            (usuario.search(" ") === -1) &&
            (dominio.search(" ") === -1) &&
            (dominio.search(".") !== -1) &&
            (dominio.indexOf(".") >= 1) &&
            (dominio.lastIndexOf(".") < dominio.length - 1)) {
            this.setState({
                emailValidador: true
            })
        }
        else {
            this.setState({
                emailValidador: false
            })
        }

    }

    setaTipoPessoa = (text) => {

        this.setState({
            tipoPessoa: text.target.value
        })

    }

    registraNewsLetter = () => {

        this.setState({
            loaderRegistrandoNewsletter: true,
        })

        !this.state.emailValidador || !this.state.nome || !this.state.email ?

            this.setState({
                loaderRegistrandoNewsletter: false,
                erroRegistrandoNewsletter: true,
            })

            :

            AXIOS().post('registernewsletter', {
                "st_nome": this.state.nome, // Obrigatório
                "st_email": this.state.email, // Obrigatório
                "st_tipo": this.state.tipoPessoa
            })
                .then((response) => {
                    console.log(response)
                    this.setState({
                        loaderRegistrandoNewsletter: false,
                        erroRegistrandoNewsletter: false,
                        registradoComSucesso: true,
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        loaderRegistrandoNewsletter: false,
                        erroRegistrandoNewsletter: true,
                        registradoComSucesso: false,
                    })
                })

    }

    render() {

        const Hoje = new Date();
        const Ano = Hoje.getFullYear();

        return (

            <>

                <div className="landingFooter">

                    <div className="landingFooterContent">

                        <div className="landingFooterContentContainer">

                            <div className="landingFooterContentColunaImagem" to="/">

                                <NavLink to="/">

                                    <img alt="imagemLogoFooter" src={Logo} />

                                </NavLink>

                                <div className="social">

                                    <a className="socialItemFooter mt-2" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/simbiosesocial" style={{ textDecoration: 'none', color: '#606A73', fontSize: '25px' }}>

                                        <i className="fab fa-facebook"></i>

                                    </a>

                                    <a className="socialItemFooter ml-2" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/simbiosesocial/" style={{ textDecoration: 'none', color: '#606A73', fontSize: '25px', marginLeft: '5px' }}>

                                        <i className="fab fa-instagram"></i>

                                    </a>

                                    <a className="socialItemFooter ml-2" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/simbiose-social/" style={{ textDecoration: 'none', color: '#606A73', fontSize: '25px', marginLeft: '5px' }}>

                                        <i className="fab fa-linkedin"></i>

                                    </a>

                                </div>

                            </div>

                            <div className="landingFooterContentColunaInfo">

                                <div className="landingFooterContentColunaInfoTexto">

                                    SIMBIOSE SOCIAL

                                </div>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/">

                                    Home page

                                </NavLink>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/patrocinadores">

                                    Para empresas

                                </NavLink>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/plataforma-de-projetos">

                                    Para proponentes

                                </NavLink>

                                {/*

                                <NavLink className="landingFooterContentColunaInfoItem" to="/sobre-nos">

                                    Sobre nós

                                </NavLink>

                                <div className="landingFooterContentColunaInfoItem">

                                    Press

                                </div>

                                */}

                            </div>

                            <div className="landingFooterContentColunaInfo">

                                <div className="landingFooterContentColunaInfoTexto">

                                    ASSISTÊNCIA

                                </div>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/contato">

                                    Contato

                                </NavLink>

                            </div>

                            <div className="landingFooterContentColunaInfo">

                                <div className="landingFooterContentColunaInfoTexto">

                                    LEGAL

                                </div>

                                <NavLink className="landingFooterContentColunaInfoItem" rel="noopener noreferrer" to="/politica-de-privacidade/">

                                    Termos e condições

                                </NavLink>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/politica-de-privacidade/">

                                    Política de Privacidade

                                </NavLink>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/politica-de-privacidade-analise/">

                                    Política de Privacidade de Análise

                                </NavLink>

                                {/*

                                <div className="landingFooterContentColunaInfoItem">

                                    Política de privacidade

                                </div>

                                <div className="landingFooterContentColunaInfoItem">

                                    Termos de uso

                                </div>

                                */}

                            </div>

                            {

                                this.state.registradoComSucesso ?

                                    <div className="landingFooterContentColunaNewsletter">

                                        <div className="landingFooterContentColunaNewsletterTexto">

                                            Uhuu! Newsletter assinada!

                                        </div>

                                        <div className="landingFooterContentColunaNewsletterSubtitulo">

                                            Obrigado, {this.state.nome}! Você vai ficar por dentro de tudo que acontece na Simbiose.

                                        </div>

                                    </div>

                                    :

                                    <div className="landingFooterContentColunaNewsletter">

                                        <div className="landingFooterContentColunaNewsletterTexto" style={{ color: this.state.erroRegistrandoNewsletter && 'red' }}>

                                            {this.state.erroRegistrandoNewsletter ? 'Tente novamente! Algo deu errado...' : 'Assine nossa newsletter'}

                                        </div>

                                        <div className="landingFooterContentColunaNewsletterSubtitulo">

                                            Enviamos novidades da Simbiose Social para você.

                                        </div>

                                        <div className="landingFooterContentColunaNewsletterFormularioContainer">

                                            <div className="landingFooterContentColunaNewsletterFormularioCima">

                                                <input className="landingFooterContentColunaNewsletterFormularioNome" style={{ border: !this.state.nome && this.state.erroRegistrandoNewsletter && '1px solid red' }} onChange={(text) => this.setaNome(text)} value={this.state.nome} placeholder="Digite seu nome" />

                                                <select className="landingFooterContentColunaNewsletterFormularioDropdown" onChange={(text) => this.setaTipoPessoa(text)} value={this.state.tipoPessoa} placeholder="Selecione uma categoria">
                                                    <option value="empresa">Sou uma empresa</option>
                                                    <option value="proponente">Sou um proponente</option>
                                                    <option value="servidor">Sou um servidor públio</option>
                                                </select>

                                            </div>

                                            <div className="landingFooterContentColunaNewsletterFormularioBaixo">

                                                <input className="landingFooterContentColunaNewsletterFormularioEmail" style={{ border: !this.state.email && this.state.erroRegistrandoNewsletter && '1px solid red' }} onChange={(text) => this.setaEmail(text)} value={this.state.email} placeholder="Digite seu email" />

                                                <div className="landingFooterContentColunaNewsletterFormularioEnviar" style={{ pointerEvents: this.state.loaderRegistrandoNewsletter && 'none' }} onClick={() => this.registraNewsLetter()}>{this.state.loaderRegistrandoNewsletter ? 'Enviando...' : 'Enviar'}</div>

                                            </div>

                                            <div className="landingFooterContentColunaNewsletterFormularioBaixoErroMensagem">

                                                {

                                                    !this.state.emailValidador && this.state.email.length > 0 &&

                                                    <div className="secaoContatoEmailmensagemErro">

                                                        Digite um email valido!

                                                    </div>

                                                }

                                            </div>

                                        </div>

                                    </div>

                            }

                        </div>

                    </div>

                    <div className="landingFooterDescription">

                        Simbiose Assessoria em projetos culturais e desportivos - CNPJ: 26.984.592/0001-17 - Endereço: Avenida Angélica, 2529 - São Paulo - SP - CEP: 01227-200 <br />Copyright © {Ano} Simbiose Social. Todos os direitos reservados.

                    </div>

                </div>

                <div className="landingFooterMobile">

                    <div className="landingFooterContentMobile">

                        <div className="landingFooterContentColunaImagem">

                            <img alt="imagemLogoFooter" src={Logo} />

                        </div>

                        <div className="landingFooterContentColunaContainer">

                            <div className="landingFooterContentColunaInfo">

                                <div className="landingFooterContentColunaInfoTexto">

                                    SIMBIOSE SOCIAL

                                </div>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/">

                                    Home page

                                </NavLink>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/patrocinadores">

                                    Para empresas

                                </NavLink>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/plataforma-de-projetos">

                                    Para proponentes

                                </NavLink>

                                {/*

                                <NavLink className="landingFooterContentColunaInfoItem" to="/sobre-nos">

                                    Sobre nós

                                </NavLink>

                                <div className="landingFooterContentColunaInfoItem">

                                    Press

                                </div>

                                */}

                            </div>

                            <div className="landingFooterContentColunaInfo">

                                <div className="landingFooterContentColunaInfoTexto">

                                    ASSISTÊNCIA

                                </div>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/contato">

                                    Contato

                                </NavLink>

                            </div>

                            <div className="landingFooterContentColunaInfo">

                                <div className="landingFooterContentColunaInfoTexto">

                                    LEGAL

                                </div>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/politica-de-privacidade/">

                                    Termos e condições

                                </NavLink>

                                <NavLink className="landingFooterContentColunaInfoItem" to="/politica-de-privacidade/">

                                    Política de Privacidade

                                </NavLink>

                                {/*

                                <div className="landingFooterContentColunaInfoItem">

                                    Política de privacidade

                                </div>

                                <div className="landingFooterContentColunaInfoItem">

                                    Termos de uso

                                </div>

                                */}

                            </div>

                        </div>

                        {

                            this.state.registradoComSucesso ?

                                <div className="landingFooterContentColunaNewsletter">

                                    <div className="landingFooterContentColunaNewsletterTexto">

                                        Uhuu! Newsletter assinada!

                                        </div>

                                    <div className="landingFooterContentColunaNewsletterSubtitulo">

                                        Obrigado, {this.state.nome}! Você vai ficar por dentro de tudo que acontece na Simbiose.

                                        </div>

                                </div>

                                :

                                <div className="landingFooterContentColunaNewsletter">

                                    <div className="landingFooterContentColunaNewsletterTexto" style={{ color: this.state.erroRegistrandoNewsletter && 'red' }}>

                                        {this.state.erroRegistrandoNewsletter ? 'Tente novamente! Algo deu errado...' : 'Assine nossa newsletter'}

                                    </div>

                                    <div className="landingFooterContentColunaNewsletterSubtitulo">

                                        Enviamos novidades da Simbiose Social para você.

                                        </div>

                                    <div className="landingFooterContentColunaNewsletterFormularioContainer">

                                        <div className="landingFooterContentColunaNewsletterFormularioCima">

                                            <input className="landingFooterContentColunaNewsletterFormularioInput" style={{ border: !this.state.nome && this.state.erroRegistrandoNewsletter && '1px solid red' }} onChange={(text) => this.setaNome(text)} value={this.state.nome} placeholder="Digite seu nome" />

                                            <select className="landingFooterContentColunaNewsletterFormularioInput" onChange={(text) => this.setaTipoPessoa(text)} value={this.state.tipoPessoa} placeholder="Selecione uma categoria">
                                                <option value="empresa">Sou uma empresa</option>
                                                <option value="proponente">Sou um proponente</option>
                                                <option value="servidor">Sou um servidor públio</option>
                                            </select>

                                        </div>

                                        <div className="landingFooterContentColunaNewsletterFormularioBaixo">

                                            <input className="landingFooterContentColunaNewsletterFormularioInput" style={{ border: !this.state.email && this.state.erroRegistrandoNewsletter && '1px solid red' }} onChange={(text) => this.setaEmail(text)} value={this.state.email} placeholder="Digite seu email" />

                                            <div className="landingFooterContentColunaNewsletterFormularioEnviar" style={{ pointerEvents: this.state.loaderRegistrandoNewsletter && 'none' }} onClick={() => this.registraNewsLetter()}>{this.state.loaderRegistrandoNewsletter ? 'Enviando...' : 'Enviar'}</div>

                                        </div>

                                    </div>

                                </div>

                        }

                    </div>

                    <div className="landingFooterDescription">

                        Simbiose Assessoria em projetos culturais e desportivos - CNPJ: 26.984.592/0001-17 - Endereço: Avenida Angélica, 2529 - São Paulo - SP - CEP: 01227-200 <br />Copyright © {Ano} Simbiose Social. Todos os direitos reservados.

                    </div>

                </div>

            </>

        )

    }

}

export default Footer;