import React from 'react'
import {AXIOS} from '../../service/Base'
import IconEnvelope from '../../images/icon_envelope.svg'
import IconGlobe from '../../images/icon_globe.svg'
import IconClock from '../../images/icon_clock.svg'

    class Agendamento extends React.Component {

        constructor() {

            super()

            this.state = {
                IdUsuario: '',
                datasWebnairs: [],
                confirmando: false,
                erroConfirmacao: false,
                dataSelecionada: '',
                horarioSelecionado: '',
                inscritoComSucesso: false,
                inscrevendo: false,
                erroNaInscricao: false,
                dataConfirmada: '',
                confirmandoInteresse: false,
                ErroConfirmandoInteresse: false,
                confirmadoInteresseComSucesso: false,
                nome: '',
                empresa: '',
                stringDataSelecionada: '',
                RadioId: 0,
                radioText: ''
            }

        }

        componentWillMount() {

            //6ea9ab1baa0efb9e19094440c317e21b
            window.location.replace('https://simbi.social/');

            this.setState({
                confirmando: true,
                IdUsuario: window.location.href.split('?usuario=')[1],
            })

            AXIOS().post('agendamento', {
                "usuario": window.location.href.split('?usuario=')[1],
                _method: "GET"
            })
            .then((response) => {
                console.log(response)
                this.setState({
                    email: response.data.data.usuario.email,
                    nome: response.data.data.usuario.st_nome_usuario,
                    datasWebnairs: response.data.data.webinars,
                    empresa: response.data.data.usuario.cliente_id.st_nome_cliente,
                    confirmando: false,
                    erroConfirmacao: false,
                })
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    confirmando: false,
                    erroConfirmacao: true
                })
            })        
        
        }

        agendarWebnair = (e) => {

            e.preventDefault()

            const representa = this.state.RadioId === 0 ? 'Proponente' : this.state.RadioId === 1 ? 'Agência' : this.state.RadioId === 2 ? this.state.radioText : ''

            this.setState({
                inscrevendo: true
            })

            AXIOS().post('inscrever/'+this.state.dataSelecionada, {
                "usuario": this.state.IdUsuario,
                "horario": this.state.horarioSelecionado,     
                "st_representa": representa
            })
            .then((response) => {
                console.log(response)
                if(response.data.success){
                    this.setState({
                        inscritoComSucesso: true,
                        dataConfirmada: response.data.data.data_webinar,
                        inscrevendo: false,
                        erroNaInscricao: false,
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    inscritoComSucesso: false,
                    inscrevendo: false,
                    erroNaInscricao: true,
                })
            })

        }

        selecionaData = (text) => {

            console.log(text.target)

            this.setState({
                dataSelecionada: parseInt(text.target.value),
                horarioSelecionado: ''
            })

            this.state.datasWebnairs.map((webnar) => {

                return (

                    parseInt(text.target.value) === webnar.id_webinar &&

                        this.setState({
                            stringDataSelecionada: webnar.data_webinar
                        })

                )

            })

        }

        exibeData = (data) => {

            const mainDate = data.split(' ')[0];
            const month = mainDate.split('-')[1];
            const day = mainDate.split('-')[2];
            const year = mainDate.split('-')[0];


            return (day+'/'+month+'/'+year)

        }

        selecionaHorario = (horario) => {

            this.setState({
                horarioSelecionado: horario
            })

        }

        /*
        
        Pegar as datas disponíveis:
        GET /api/v1/agendamento
        retorno:
        {
            "success": true,
            "data": [
                {
                    "id_webinar": 1,
                    "data_webinar": "2020-06-17 00:00:00",
                    "horarios": [
                        "21:00",
                        "10:00"
                    ],
                    "created_at": "2020-06-10 17:03:06",
                    "updated_at": "2020-06-10 17:03:06"
                }
            ],
            "message": "Datas Retornadas com sucesso!"
        }

        Para se inscrever:
        POST /api/v1/inscrever/{id_webinar}
        body:
        {
            "usuario": "b6d767d2f8ed5d21a44b0e5886680cb9",
            "horario": "21:00"
        }

        */

       tenhoInteresseWebnair = () => {

            this.setState({
                confirmandoInteresse: true
            })
        
            AXIOS().post('contatosite', {
                "st_nome": this.state.nome, // Obrigatório
                "st_nome_empresa": this.state.empresa, // Obrigatório
                "st_email": this.state.email, // Obrigatório
                "mensagem": 'Recebemos uma declaração de interesse de um proponente no nosso site em participar de um treinamento do Radar. Como não temos nenhum Webinar cadastrado na API, assim que tivermos uma data confirmada, por favor envie um e-mail confirmando a data para '+this.state.nome +' no email ' +this.state.email,
                "id_novidades": '',
            })
            .then((response) => {
                console.log(response)
                this.setState({
                    confirmandoInteresse: false,
                    confirmadoInteresseComSucesso: true,
                })
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    confirmandoInteresse: false,
                    ErroConfirmandoInteresse: true,
                })
            })

       }

       radioRepresenta = (id) => {

            this.setState({
                RadioId: id
            })

       }

       setaRadioOutro = (text) => {

            this.setState({
                radioText: text.target.value
            })

       }

        render() {

            return (

                this.state.inscritoComSucesso ?

                    <div className="paginaAgendamentoContainerConfirmacao">

                        <div className="paginaAgendamentoContainerConfirmacaoContainer">

                            <div className="paginaAgendamentoContainerTexto">

                                <div className="paginaAgendamentoContainerTextoTitulo">

                                    Perfeito! Data e horário agendados

                                </div>

                                <div className="paginaAgendamentoContainerTextoSubtitulo">

                                    O email <span className="paginaAgendamentoContainerTextoSubtituloBold">{this.state.email}</span> foi confirmado para o teste da plataforma de radar de patrocinadores para <span className="paginaAgendamentoContainerTextoSubtituloBold">{this.exibeData(this.state.dataConfirmada)}</span> às <span className="paginaAgendamentoContainerTextoSubtituloBold">{this.state.horarioSelecionado}</span>. Enviaremos o link para o acesso do teste 24 horas antes.

                                </div>

                            </div>

                        </div>

                    </div>

                :

                    <div className="paginaAgendamentoContainer">

                        {
                        
                        this.state.confirmando ? 
                        
                            'CONFIRMANDO AGUARDE...' 
                        
                        :

                            this.state.datasWebnairs.length > 0 ?

                                <div className="paginaAgendamentoConteudoContainer">

                                    <div className="paginaAgendamentoConteudoEsquerdo">

                                        <div className="paginaAgendamentoConteudoTexto">

                                            <div className="paginaAgendamentoConteudoTextoTitulo">

                                                Teste do radar de patrocinadores

                                            </div>

                                            <div className="paginaAgendamentoConteudoTextoSubtitulo">

                                                Selecione uma data e horário para agendar um teste do radar de patrocinadores com a nossa equipe.

                                            </div>

                                        </div>

                                        <div className="paginaAgendamentoConteudoInformacoes">

                                            <div className="paginaAgendamentoConteudoInformacoesItem">

                                                <div className="paginaAgendamentoConteudoInformacoesItemHeaderMobile">

                                                    <img alt="iconInfo" src={IconEnvelope} />

                                                    <div className="paginaAgendamentoConteudoInformacoesItemTitutlo">

                                                        email confirmado:

                                                    </div>

                                                </div>

                                                <div className="paginaAgendamentoConteudoInformacoesItemValor">

                                                    {this.state.email}

                                                </div>

                                            </div>

                                            <div className="paginaAgendamentoConteudoInformacoesItem">

                                                <div className="paginaAgendamentoConteudoInformacoesItemHeaderMobile">

                                                    <img alt="iconInfo" src={IconClock} />

                                                    <div className="paginaAgendamentoConteudoInformacoesItemTitutlo">

                                                        duração:

                                                    </div>

                                                </div>

                                                <div className="paginaAgendamentoConteudoInformacoesItemValor">

                                                    60 min

                                                </div>

                                            </div>

                                            <div className="paginaAgendamentoConteudoInformacoesItem">

                                                <div className="paginaAgendamentoConteudoInformacoesItemHeaderMobile">

                                                    <img alt="iconInfo" src={IconGlobe} />

                                                    <div className="paginaAgendamentoConteudoInformacoesItemTitutlo">

                                                        Horário de Brasília {this.state.horarioSelecionado ? `(${this.state.horarioSelecionado})` : '(-)'}

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="paginaAgendamentoConteudoDireito">

                                    <div className="paginaAgendamentoConteudoDireitoContainer">

                                        <form onSubmit={(e) => this.agendarWebnair(e)} className="paginaAgendamentoConteudoDireitoConteudo">

                                            <div className="paginaAgendamentoConteudoDireitoConteudoTitulo">

                                                Escolha uma data disponível

                                            </div>

                                            <input type="email" name="email" value={this.state.email} style={{display: 'none'}} />

                                            <input type="data" name="data" value={this.state.stringDataSelecionada} style={{display: 'none'}} />

                                            <input type="text" name="horario" value={this.state.horarioSelecionado} style={{display: 'none'}} />

                                            <input type="text" name="represento" value={this.state.RadioId === 0 ? 'Proponente' : this.state.RadioId === 1 ? 'Agência' : this.state.RadioId === 2 ? this.state.radioText : ''} style={{display: 'none'}} />                                            
        
                                            <div className="secaoContatoRepresentaContainer">

                                                <div className="secaoContatoRepresentaLabel">

                                                </div>

                                                <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                    <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(0)} checked={this.state.RadioId === 0 ? true : false} /> 
                                                    
                                                    Sou um proponente

                                                </div>

                                                <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                    <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(1)} checked={this.state.RadioId === 1 ? true : false} />

                                                    Sou uma agência

                                                </div>

                                                <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                    <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(2)} checked={this.state.RadioId === 2 ? true : false} />

                                                    Outro ?

                                                </div>

                                                {

                                                    this.state.RadioId === 2 && 

                                                        <input className="inputsecaoContatoTermo" placeholder="Qual?" style={{marginBottom: '0px',borderBottom: this.state.ErroConfirmandoInteresse && !this.state.radioText && '1px solid red'}} value={this.state.radioText} onChange={(text) => this.setaRadioOutro(text)} /> 

                                                }

                                            </div>

                                            <select className="paginaAgendamentoConteudoDatas" onChange={(text) => this.selecionaData(text)} value={this.state.dataSelecionada} placeholder="Selecione uma categoria">

                                                <option value="selecione" selected hidden> Selecione uma data </option>

                                                {
                                                    this.state.datasWebnairs.map((webnair) => {

                                                        return (

                                                            <option key={webnair.id_webinar} id={webnair.id_webinar} name={webnair.data_webinar} value={webnair.id_webinar}>{this.exibeData(webnair.data_webinar)}</option>

                                                        )

                                                    })
                                                }

                                            </select>
                                        
                                        {

                                        this.state.dataSelecionada &&

                                            <>

                                                <div className="paginaAgendamentoConteudoTitulo">

                                                    Escolha um horário disponível

                                                </div>

                                                <div className="paginaAgendamentoConteudoTagHorarios">

                                                    {

                                                        this.state.datasWebnairs.map((webnairs) => {

                                                            return (

                                                                webnairs.id_webinar === this.state.dataSelecionada &&

                                                                    webnairs.horarios.map((horarios, index) => {

                                                                        return (

                                                                            horarios != null &&

                                                                            <div key={index} style={{
                                                                                color: this.state.horarioSelecionado === horarios && '#fff',
                                                                                backgroundColor: this.state.horarioSelecionado === horarios && '#71CAD4'

                                                                            }} className="paginaAgendamentoConteudoTituloHorario" onClick={() => this.selecionaHorario(horarios)}>{horarios}</div>

                                                                        )

                                                                    })

                                                            )

                                                        })

                                                    }

                                                </div>

                                                <button type="submit" style={{
                                                    color: ((this.state.RadioId === 2 && !this.state.radioText) || !this.state.dataSelecionada || !this.state.horarioSelecionado) && 'rgb(128, 128, 128)',
                                                    backgroundColor: ((this.state.RadioId === 2 && !this.state.radioText) || !this.state.dataSelecionada || !this.state.horarioSelecionado) && 'rgb(235, 235, 235)',
                                                    pointerEvents: ((this.state.RadioId === 2 && !this.state.radioText) || !this.state.dataSelecionada || !this.state.horarioSelecionado || this.state.inscrevendo) && 'none',
                                                }} className="paginaAgendamentoConteudoBotaoConfirmar">

                                                    {this.state.inscrevendo ? 'Inscrevendo...' : 'Confirmar'}

                                                </button>

                                            </>

                                        }

                                        </form>

                                    </div>

                                    {/*

                                        this.state.dataSelecionada &&
                                    
                                            <div className="paginaAgendamentoConteudoHorarios">
                                    
                                            <div className="paginaAgendamentoConteudoHorariosContainer">

                                                <div className="paginaAgendamentoConteudoTitulo">

                                                    Escolha um horário disponível

                                                </div>

                                                <div className="paginaAgendamentoConteudoTagHorarios">

                                                    {

                                                        this.state.datasWebnairs.map((webnairs) => {

                                                            return (

                                                                webnairs.id_webinar === this.state.dataSelecionada &&

                                                                    webnairs.horarios.map((horarios) => {

                                                                        return (

                                                                            <div style={{
                                                                                color: this.state.horarioSelecionado === horarios && '#fff',
                                                                                backgroundColor: this.state.horarioSelecionado === horarios && '#71CAD4'

                                                                            }} className="paginaAgendamentoConteudoTituloHorario" onClick={() => this.selecionaHorario(horarios)}>{horarios}</div>

                                                                        )

                                                                    })

                                                            )

                                                        })

                                                    }

                                                </div>

                                                <div style={{
                                                    color: (!this.state.dataSelecionada || !this.state.horarioSelecionado) && 'rgb(128, 128, 128)',
                                                    backgroundColor: (!this.state.dataSelecionada || !this.state.horarioSelecionado) && 'rgb(235, 235, 235)',
                                                    pointerEvents: (!this.state.dataSelecionada || !this.state.horarioSelecionado || this.state.inscrevendo) && 'none',
                                                }} onClick={() => this.agendarWebnair()} className="paginaAgendamentoConteudoBotaoConfirmar">

                                                    {this.state.inscrevendo ? 'Inscrevendo...' : 'Confirmar'}

                                                </div>

                                            </div>

                                            </div>

                                        */}

                                    </div>
                                    

                                </div>

                            :

                                this.state.confirmadoInteresseComSucesso ?

                                    <div className="paginaAgendamentoContainerConfirmacao">
                
                                        <div className="paginaAgendamentoContainerInteresseContainer">
                
                                            <div className="paginaAgendamentoContainerTexto">
                
                                                <div className="paginaAgendamentoContainerTextoTitulo">
                
                                                    Uhul! Seu interesse foi enviado ao nosso time simbiótico!
                
                                                </div>
                
                                                <div className="paginaAgendamentoContainerTextoSubtitulo">
                
                                                    Você receberá um email quando marcarmos o próximo webinar.
                
                                                </div>
                
                                            </div>
                
                                        </div>
                
                                    </div>

                                :

                                    <div className="paginaAgendamentoContainerConfirmacao">
                
                                        <div className="paginaAgendamentoContainerInteresseContainer">
                
                                            <div className="paginaAgendamentoContainerTexto">
                
                                                <div className="paginaAgendamentoContainerTextoTitulo">
                
                                                    Ainda não divulgamos a data do próximo treinamento da plataforma, mas eles acontecem mensalmente! 
                
                                                </div>
                
                                                <div className="paginaAgendamentoContainerTextoSubtitulo">

                                                    {

                                                        this.state.ErroConfirmandoInteresse ?

                                                            <div style={{color: 'red'}}>Opa! Parece que ocorreu algum erro durante o envio, tente novamente! Se o erro persistir envie um email para radar@simbiose.social</div>

                                                        :

                                                            'Clique no link abaixo para receber um e-mail quando tivermos a data da próxima apresentação!'

                                                    }
                
                
                                                </div>

                                                <div className="botaoConfirmaInteresseContainer">

                                                    <div onClick={() => this.tenhoInteresseWebnair()} className="paginaAgendamentoConteudoBotaoConfirmar" style={{
                                                        pointerEvents: this.state.confirmandoInteresse ? 'none' : 'auto',
                                                    }}>

                                                        {this.state.confirmandoInteresse ? 'Confirmando...' : 'Confirmar Interesse'}

                                                    </div>

                                                </div>
                
                                            </div>
                
                                        </div>
                
                                    </div>

                        }
    
                    </div>

            )

        }

    }

export default Agendamento