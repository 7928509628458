import React from 'react'
import LogosEmpresasParceiras from '../images/empresas_parceiras_novo.png'
import FrameMissao from '../images/nossa_missao.svg'
import FrameCard1 from '../images/vantagens_01.svg'
import FrameCard2 from '../images/vantagens_02.svg'
import FrameCard3 from '../images/vantagens_03.svg'
import PlataformaProponentes from './actioncomponents/PlataformaProponentes'
import PlataformaEmpresas from './actioncomponents/PlataformaEmpresas'
import Depoimentos from './actioncomponents/Depoimentos'
//import TiraDuvidas from '../images/manual.svg'
import Acreditam1 from '../images/artemisia_logo.svg'
import Acreditam2 from '../images/cesnik_logo.svg'
import Acreditam3 from '../images/dinamo_logo.svg'
import SimbiLogoConheca from '../images/logo-simbiose-12-01-png.png'
//import Acreditam4 from '../images/animus_logo.svg'
import Acreditam5 from '../images/fgv_logo.svg'
import Acreditam7 from '../images/image9.png'
import Acreditam8 from '../images/skoll_logo.svg'
import Footer from "./Footer"
import Potencialize from "./Potencialize"
import { NavLink } from 'react-router-dom'

    class Landing extends React.Component {

        constructor() {

            super()

            this.state = {

            }

        }

        componentWillMount() {
            window.location.replace('https://simbi.social/')
        }

        componentDidMount() {            

           window.scrollTo(0, 0)
           

        }

        render() {

            return (

                

                <div>

                    <div className="bannerLandingPrincipal">

                        <div className="bannerTexto">

                            <div className="bannerTextoTitulo">
                                Potencialize o <br /><span className="highlight">impacto social</span><br /> em todo o Brasil.
                            </div>

                            <div className="bannerTextoSubtitulo">
                                Somos uma <span style={{fontStyle: 'italic'}}>social tech</span> que usa inteligência de dados para conectar empresas e projetos sociais que utilizam leis de incentivo.
                            </div>

                            {

                                /*

                                    <div className="botoesContainer">

                                        <NavLink className="BannerBotao" to={{pathname: "/patrocinadores", search:`${this.props.location.search}`}}>

                                            Sou patrocinador

                                        </NavLink>

                                        <NavLink className="BannerBotao" to={{pathname: "/plataforma-de-projetos", search:`${this.props.location.search}`}}>

                                            Sou proponente

                                        </NavLink>

                                    </div>
                                
                                */

                            }

                        </div>

                    </div>

                    <div className="landingMissao">

                        <div className="landingMissaoContainer">

                            <div className="landingMissaoTexto">

                                <div className="numerosSimbi">

                                    <div className="numerosBlock">

                                        <div className="numerosValorBlock">

                                            <div className="numerosBlockTitulo">

                                                <strong>+R$350</strong>

                                            </div>

                                            <div className="numerosBlockValorString">

                                                milhões

                                            </div>
                                        
                                        </div>

                                        <div className="numerosBlockValorDescrição">

                                            <span style={{fontWeight: 'bold'}}>movimentados</span> em incentivos fiscais

                                        </div>

                                    </div>

                                    <div className="numerosBlock">

                                        <div className="numerosValorBlock">

                                            <div className="numerosBlockTitulo">

                                                +462

                                            </div>

                                            <div className="numerosBlockValorString">

                                                projetos

                                            </div>
                                        
                                        </div>

                                        <div className="numerosBlockValorDescrição">

                                            <span style={{fontWeight: 'bold'}}>beneficiados</span> em 13 estados brasileiros

                                        </div>

                                    </div>

                                    <div className="numerosBlock">

                                        <div className="numerosValorBlock">

                                            <div className="numerosBlockTitulo">

                                                +40

                                            </div>

                                            <div className="numerosBlockValorString">

                                                empresas

                                            </div>
                                        
                                        </div>

                                        <div className="numerosBlockValorDescrição">

                                            <span style={{fontWeight: 'bold'}}>e mais de 40 organizações sociais</span> utilizam a plataforma da Simbiose Social

                                        </div>

                                    </div>

                                    {/*<div className="numerosBlock">

                                        <div className="numerosValorBlock">

                                            <div className="numerosBlockTitulo">

                                                +180mil

                                            </div>

                                            <div className="numerosBlockValorString">

                                                milhões

                                            </div>
                                        
                                        </div>

                                        <div className="numerosBlockValorDescrição">

                                            <span style={{fontWeight: 'bold'}}>movimentados</span> em incentivos fiscais

                                        </div>

                                    </div>*/}

                                </div>

                                {/*

                                    <div className="landingMissaoTextoTitulo">

                                        A nossa missão é democratizar o acesso a recursos.

                                    </div>

                                    <div className="landingMissaoTextoSubtitulo">

                                        Fundada em 2016, a partir de um sonho de fazer a diferença no mundo. Somos uma startup de tecnologia voltada para o impacto social. Especialistas em leis de incentivo, conectamos empresas, proponentes e governo para potencializar a transformação. Apostamos e acreditamos na democratização e transparência das informações e dados públicos como catalisadores de mudança social.
                                    
                                    </div>

                                    <div className="landingMissaoAcao">

                                        <NavLink className="landingMissaoAcaoBotao" to="/sobre-nos">

                                            Saiba mais sobre nós

                                        </NavLink>

                                        <div className="landingMissaoAcaoVideoApresentacao" onClick={() => this.props.ativaPopUpVideo()}>

                                            <img alt="playVideo" src={Play} style={{marginRight: '10px'}} /> Veja o nosso vídeo de apresentação

                                        </div>

                                    </div>

                                */}

                            </div>

                            <div className="landingMissaoImagem">

                                <img className="landingMissaoImagemTag" alt="imagem" src={FrameMissao} />

                                <div className="landingMissaoTextoTitulo">

                                    Conheça o <br/> <span className="highlight">impacto gerado</span> <br /> pela Simbiose Social

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="landingPlataformaEmpresas">

                        <div className="landingPlataformaEmpresasTexto">

                            Soluções para <span className="textoCorDiferente">empresas</span>

                            <div className="tagPlataformaAtualizada"><span className="tagPlataformaAtualizadaTexto">Plataforma atualizada</span></div>

                        </div>

                        <PlataformaEmpresas {...this.props} />

                    </div>

                    <div className="landingPlataformaProponentes">

                        <div className="landingPlataformaProponentesTexto">

                            Soluções para <span className="textoCorDiferente">projetos sociais</span>

                        </div>

                        <PlataformaProponentes {...this.props} />

                    </div>

                    <div className="empresasParceiras">

                        <div className="landingEmpresasParceirasTitulo">
                            
                            Nossos clientes

                        </div>

                        <div className="empresa">

                            <img alt="imagem" src={LogosEmpresasParceiras} />

                        </div>

                    </div>

                    {/*

                    <div className="landingTireSuasDuvidasContainer">

                        <div className="landingTireSuasDuvidasConteudo">

                            <div className="landingTireSuasDuvidasConteudoContainer">

                                <div className="landingTireSuasDuvidasConteudoTexto">

                                    <div className="landingTireSuasDuvidasConteudoTitulo">

                                        Tire suas dúvidas em relação às leis de incentivo

                                    </div>

                                    <div className="landingTireSuasDuvidasConteudoSubtitulo">

                                        Leis de incentivo podem ser nebulosas para muitas empresas e proponentes, acesse nosso canal especializado sobre o assunto e tenha acesso a todos os dados e informações que precisa saber sobre as diferentes leis no país.

                                    </div>

                                    <div className="landingTireSuasDuvidasConteudoBotao">

                                        Ir para o manual

                                    </div>

                                </div>

                                <div className="landingTireSuasDuvidasConteudoImagem">

                                    <img className="landingTireSuasDuvidasConteudoImagemTag" alt="tiraDuvidas" src={TiraDuvidas} />

                                </div>

                            </div>

                        </div>

                    </div>

                    */}

                    <div className="landingDepoimentos">

                        <div className="landingDepoimentosTitulo">

                            Quem <span className="textoCorDiferente" style={{marginLeft: '5px', marginRight: '5px'}}>acredita</span> em nós

                        </div>

                        <Depoimentos />

                        <div className="landingDepoimentosDepoimentoEmpresasQueAcreditamContainer">

                            <div className="landingDepoimentosDepoimentoEmpresasQueAcreditamTitulo">
                                
                                Reconhecimentos

                            </div>

                            <div className="landingDepoimentosDepoimentoEmpresasQueAcreditam">

                                <div><img alt="empresaAcredita" className="empresaAcredita" src={Acreditam1} /></div>

                                <div><img alt="empresaAcredita" className="empresaAcredita" src={Acreditam2} /></div>

                                <div><img alt="empresaAcredita" className="empresaAcredita" src={Acreditam3} /></div>

                                <div><img alt="empresaAcredita" className="empresaAcredita" src={Acreditam5} /></div>

                                <div><img alt="empresaAcredita" className="empresaAcredita" src={Acreditam7} /></div>

                                <div><img alt="empresaAcredita" className="empresaAcredita" src={Acreditam8} /></div>

                            </div>

                        </div>

                    </div>

                    <div className="landingVantagens">

                        <div className="landingVantagensTitulo">
                            
                            POR QUE A SIMBIOSE SOCIAL?

                        </div>

                        <div className="landingVantagensSubtitulo">
                            
                            Vantagens de ser simbiótico

                        </div>

                        <div className="landingVantagensCards">

                            <div className="landingVantagensCardInfo">

                                <div className="landingVantagensCardInfoImagem">

                                    <img className="landingVantagensCardInfoImagemTag" alt="imagem" src={FrameCard1} />

                                </div>

                                <div className="landingVantagensCardInfoTitulo">

                                    Tenha mais controle dos seus recursos

                                </div>

                                <div className="landingVantagensCardInfoSubtitulo">

                                    Acompanhe com mais transparência os recursos captados ou investidos

                                </div>

                            </div>

                            <div className="landingVantagensCardInfo">

                                <div className="landingVantagensCardInfoImagem">

                                    <img className="landingVantagensCardInfoImagemTag" alt="imagem" src={FrameCard3} />

                                </div>

                                <div className="landingVantagensCardInfoTitulo">

                                    Potencialize a governança

                                </div>

                                <div className="landingVantagensCardInfoSubtitulo">

                                    Tome decisões mais assertivas a partir de dados e gere impacto social positivo

                                </div>

                            </div>

                            <div className="landingVantagensCardInfo">

                                <div className="landingVantagensCardInfoImagem">

                                  <img className="landingVantagensCardInfoImagemTag" alt="imagem" src={FrameCard2} />

                                </div>

                                <div className="landingVantagensCardInfoTitulo">

                                    Otimize o match

                                </div>

                                <div className="landingVantagensCardInfoSubtitulo">

                                    Encontre projetos ou empresas que têm mais afinidade com o que você busca

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="conhecaASimbi">

                        <div className="conhecaASimbiTextoTitulo">

                            Conheça a Simbiose Social

                        </div>
                        
                        <div className='conhecaASimbiColumns'>

                            <div className="conhecaASimbiTextoSubtitulo">

                                <img alt="simbiLogoConheca" src={SimbiLogoConheca} style={{width: '345px', height: '150px'}} />
                            
                            </div>

                            <div className="conhecaASimbiAcao">

                                <div className="bannerTextoSubtituloConheca">
                                    Fundada em 2017, a Simbiose Social é uma <span style={{fontStyle: 'italic'}}>social tech</span> que atua no mercado de leis de incentivo fiscal no Brasil. Através da sua plataforma e time de especialistas, <span style={{fontWeight: 'bold'}}>ela otimiza e democratiza a distribuição de recursos financeiros entre projetos e organizações sociais</span> que geram alto impacto e promovem acesso à cultura, educação, saúde e esporte. <br/><br/> Mais inteligência de dados para conectar empresas aptas a doarem parte do pagamento de impostos com projetos aprovados pelas leis de incentivo.
                                </div>                                

                            </div>

                        </div>

                    </div>
                    
                    <Potencialize {...this.props} />
                    
                    <Footer />

                </div>

            )

        }

    }

export default Landing;