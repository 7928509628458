import React from 'react'
import IconePergunta from '../../images/iconepergunta.png'

    class PerguntasERespostasProponentes extends React.Component {

        constructor() {

            super()

            this.state = {
                    perguntas: [
                      {
                        "titulo": "Como funciona a busca de patrocinadores?",
                        "resposta": "A busca de patrocinadores pode acontecer de duas formas! <br><br> • A primeira opção é você nos contar um pouco mais sobre o seu projeto a partir dos filtros! Neles você pode descrever um pouco mais sobre o local do seu projeto, o tema, o valor entre outras informações que irão ajudar o nosso algoritmo a encontrar os potenciais patrocinadores perfeitos para o seu projeto :) <br><br> • A segunda forma é pelas características da empresa! Caso você queira olhar especificamente as informações de um patrocinador, você pode nos contar o cnpj dele ou a razão social e nós te mostramos o relatório de investimento dele, caso ele já tenha investido em Cultura ou Esporte por meio do Imposto de Renda.",
                      },
                      {
                        "titulo": "A plataforma vai me colocar em contato com os potenciais patrocinadores?",
                        "resposta": "O radar de patrocinadores não fornece dados de contato de empresas. A ferramenta otimiza a captação de recursos do seu projeto através da inteligência de dados, com o objetivo de mostrar potenciais patrocinadores novos e quais os seus interesses de investimento!",
                      },
                      {
                        "titulo": "Estou com dificuldades no pagamento.",
                        "resposta": "Não se preocupe! Basta entrar em contato com a nossa equipe que vamos te auxiliar da melhor maneira possível. radar@simbiose.social",
                      },
                      {
                        "titulo": "Estou com dúvidas. Com quem posso falar?",
                        "resposta": "Nossa equipe simbiótica está à disposição para ajudar! Para falar com um simbiótico basta mandar um email para: radar@simbiose.social",
                      },
                      {
                        "titulo": "Como faço para fazer um upgrade do plano ou cancelamento?",
                        "resposta": "É só mandar um email para radar@simbiose.social, que iremos atender sua demanda :)",
                      },
                    ]
                      ,
                perguntaAtiva: null,
            }

        }

        expandePergunta = (pergunta) => {

            this.state.perguntaAtiva === pergunta ?

                this.setState({
                    perguntaAtiva: null
                })

            :

                this.setState({
                    perguntaAtiva: pergunta
                })

        }

        render() {

            return (

                <div>

                    <div className="paginaPropoentesPerguntasERespostas">

                        <div className="paginaPropoentesPerguntasERespostasConteudo">

                            <div className="paginaPropoentesPerguntasERespostasTexto">

                                Perguntas e respostas

                            </div>

                            <div className="paginaPropoentesPerguntasERespostasPerguntasContainer">

                                {

                                    this.state.perguntas.map((pergunta, index) => {

                                        return (

                                            <div key={index}>

                                                 <div className="paginaPropoentesPerguntasERespostasPergunta" onClick={() => this.expandePergunta(index)}>

                                                    <img className="iconePerguntaTag" alt="iconePergunta" src={IconePergunta} style={{
                                                        transform: this.state.perguntaAtiva === index ? 'rotate(45deg)' : ''
                                                    }} />

                                                        {pergunta.titulo}

                                                </div>


                                                {

                                                    this.state.perguntaAtiva === index ?

                                                        <div className="paginaPropoentesPerguntasERespostasResposta">

                                                            <div dangerouslySetInnerHTML={{__html: pergunta.resposta}} />

                                                        </div>

                                                    : ''

                                                }

                                                <div className="barraDividePerguntas" />

                                            </div>

                                        )

                                    })

                                }

                            </div>

                        </div>

                    </div>

                </div>

            )

        }

    }

export default PerguntasERespostasProponentes;