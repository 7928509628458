import React from 'react'
import { Link } from "react-router-dom";
import Imagem1Nova from '../../images/Resultados da Auditoria.svg'
import Imagem2Nova from '../../images/forms.png'
import PreInvestimento from '../../images/preInvestimento.png'
import Imagem4 from '../../images/posInvestimento.png'
import PlanoBolinhaDesmarcada from '../../images/planoBolinhaDesmarcada.svg'
import PlanoBolinhaMarcada from '../../images/planoBolinhaMarcada.svg'

    class PlataformaEmpresas extends React.Component {

        constructor(){

            super()

            this.state = {
                componenteAtivo: 'radar',
                imagem: PreInvestimento,
                titulo: 'Pré-investimento',
                subtituloBold: '',
                subtitulo: 'Procure por projetos sociais à nível municipal com base nos ODS da ONU',
            }

        }

        componentWillMount() {

            window.location.replace('https://simbi.social/');
        
        }

        mudaComponenteAtivo = (componente) => {

            componente === 'radar' &&

            this.setState({
                componenteAtivo: componente,
                imagem: PreInvestimento,
                titulo: 'Pré-investimento',
                subtituloBold: '',
                subtitulo: 'Procure por projetos sociais à nível municipal com base nos ODS da ONU',
            })

            componente === 'cnpj' &&

            this.setState({
                componenteAtivo: componente,
                imagem: Imagem1Nova,
                titulo: 'Auditoria',
                subtituloBold: '',
                subtitulo: 'Passe os projetos escolhidos por uma análise criteriosa e invista com mais segurança',
            })

            componente === 'relatorios' &&

            this.setState({
                componenteAtivo: componente,
                imagem: Imagem2Nova,
                titulo: 'Gestão de investimento',
                subtituloBold: '',
                subtitulo: 'Acompanhe em tempo real o impacto gerado pelo investimento realizado',
            })

            componente === 'atualizacoes' &&

            this.setState({
                componenteAtivo: componente,
                imagem: Imagem4,
                titulo: 'Pós-investimento',
                subtituloBold: '',
                subtitulo: 'Consolide os resultados do apoio em relatórios personalizáveis',
            })

        }

        render(){

            return (

                <div className="landingPlataformaProponentesContainer">

                    <div className="landingPlataformaProponentesHeaderContainer">

                        <div className="landingPlataformaProponentesHeader">

                            <div className="landingPlataformaProponentesHeaderIcone" 
                                style={{
                                    backgroundColor: this.state.componenteAtivo === 'radar' ? '#71CAD4' : '', 
                                    color: this.state.componenteAtivo === 'radar' ? '#FFF' : ''
                                }} 
                                onClick={() => this.mudaComponenteAtivo('radar')}>

                                PRÉ-INVESTIMENTO

                            </div>

                            <div className="landingPlataformaProponentesHeaderIcone"
                                style={{
                                    backgroundColor: this.state.componenteAtivo === 'cnpj' ? '#71CAD4' : '', 
                                    color: this.state.componenteAtivo === 'cnpj' ? '#FFF' : ''
                                }} 
                                onClick={() => this.mudaComponenteAtivo('cnpj')}>

                                AUDITORIA

                            </div>

                            <div className="landingPlataformaProponentesHeaderIcone"
                                style={{
                                    backgroundColor: this.state.componenteAtivo === 'relatorios' ? '#71CAD4' : '', 
                                    color: this.state.componenteAtivo === 'relatorios' ? '#FFF' : ''
                                }} 
                                onClick={() => this.mudaComponenteAtivo('relatorios')}>

                                GESTÃO DE INVESTIMENTO

                            </div>

                            <div className="landingPlataformaProponentesHeaderIcone"
                                style={{
                                    backgroundColor: this.state.componenteAtivo === 'atualizacoes' ? '#71CAD4' : '', 
                                    color: this.state.componenteAtivo === 'atualizacoes' ? '#FFF' : ''
                                }} 
                                onClick={() => this.mudaComponenteAtivo('atualizacoes')}>

                                PÓS-INVESTIMENTO

                            </div>

                        </div>

                    </div>

                    <div className="landingPlataformaProponentesHeaderIconeTriangulo" style={{
                        marginLeft: this.state.componenteAtivo === 'radar' ? '10%' 
                            : this.state.componenteAtivo === 'cnpj' ? '36%' 
                                : this.state.componenteAtivo === 'relatorios' ? '61%'
                                    : this.state.componenteAtivo === 'atualizacoes' ? '87%' : '10%'}} />

                    <div className="landingPlataformaProponentesConteudoContainerDesktop">

                        <div className="landingPlataformaProponentesConteudo">

                            <div className="landingPlataformaProponentesTextoPlataforma">

                                <div className="landingPlataformaProponentesTitulo">

                                    {this.state.titulo}

                                </div>

                                <div className="landingPlataformaProponentesBarra" />

                                <div className="landingPlataformaProponentesSubtituloBold">

                                    {this.state.subtituloBold}

                                </div>

                                <div className="landingPlataformaProponentesSubtitulo">

                                    <div dangerouslySetInnerHTML={{__html: this.state.subtitulo}} />

                                </div>

                                <Link className="landingPlataformaProponentesBotao" to={{pathname: "patrocinadores", search: this.props.location.search}}>
                                    
                                    Ver mais
                                    
                                </Link>

                            </div>

                            <div className="landingPlataformaProponentesTextoImagem">

                                <img className="imagemPreviewPlataforma" alt="imagemPlataformaProponente" src={this.state.imagem} />

                            </div>

                        </div>

                    </div>

                    {

                        /*


                            SEPARAÇÃO DIV DESKTOP E DIV MOBILE

                        
                        */

                    }

                    <div className="landingPlataformaProponentesConteudoContainerMobile">

                        <div className="landingPlataformaProponentesConteudoContainer">

                            <div className="landingPlataformaProponentesConteudo">

                                <div className="landingPlataformaProponentesTextoPlataforma">

                                    <div className="landingPlataformaProponentesTitulo">

                                        Plataforma de gestão de incentivos

                                    </div>

                                    <div className="landingPlataformaProponentesBarra" />

                                    <div className="landingPlataformaProponentesSubtituloBold">

                                        

                                    </div>

                                    <div className="landingPlataformaProponentesSubtitulo">

                                        São mais de 20 mil projetos captando recursos neste momento. Otimize o processo de busca analisando apenas aqueles que estejam alinhados com a sua estratégia de investimento.

                                    </div>

                                    <div className="landingPlataformaProponentesBotao">

                                        Ver preços

                                    </div>

                                </div>

                                <div className="landingPlataformaProponentesTextoImagem">

                                    <img className="imagemPreviewPlataforma" alt="imagemPlataformaProponente" src={this.state.imagem} />

                                </div>

                                <Link className="landingPlataformaProponentesTextoPlataformaBotaoMobile" to={{pathname: "patrocinadores", search: this.props.location.search}}>
                                    
                                    Ver mais
                                    
                                </Link>

                            </div>

                        </div>

                    </div>

                    <div className="landingPlataformaProponentesBolinhasContainer">

                        <div className="landingPlataformaProponentesBolinhasConteudo">

                            <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.mudaComponenteAtivo('radar')} src={this.state.componenteAtivo === 'radar' ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                            <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.mudaComponenteAtivo('cnpj')} src={this.state.componenteAtivo === 'cnpj' ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                            <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.mudaComponenteAtivo('relatorios')} src={this.state.componenteAtivo === 'relatorios' ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                            <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.mudaComponenteAtivo('atualizacoes')} src={this.state.componenteAtivo === 'atualizacoes' ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                        </div>

                    </div>

                </div>

            )

        }

    }

export default PlataformaEmpresas;