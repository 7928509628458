import React from 'react'

import policyIcon1 from '../../../../images/policyIcon1.svg'
import policyImage2 from '../../../../images/nossa_missao.svg'
import policyImage3 from '../../../../images/vantagens_03.svg'
import policyImage4 from '../../../../images/vantagens_02.svg'
import PolicyImage5 from '../../../../images/cadastrofundomobile.svg'
class PrivacidadeUsuario extends React.Component {
  render() {
    return (
      <div className="paginaPropoentesPerguntasERespostas">

        <div className="paginaPropoentesPerguntasERespostasConteudo" style={{ maxWidth: '1000px', marginTop: '50px' }}>

          <h1 className="titlePolicy">Política de Privacidade do usuário.</h1>

          <p className="policyParagraph">Olá! Esta é a <strong>Política de Privacidade do Usuário da Simbiose Social.</strong> Ela explica para você como nós coletamos, armazenamos e utilizamos os seus dados e informações no nosso dia-a-dia e em nossos produtos tecnológicos</p>

          <div className="politicaSecaoComImagem">

            <div>

              <h2 className="titlePolicy">1 - A quem se aplica a Política de Privacidade?</h2>

              <p className="policyParagraph">Ela se aplica a todo usuário, cliente, fornecedor e colaborador da Simbiose Social que fornece
              qualquer dado e/ou informações pessoais à Simbiose Social, seja por meio físico ou digital.</p>

            </div>

            <div>

              <img className="policyIcon1" alt="policyIcon1" src={policyIcon1} />

            </div>

          </div>

          <p className="policyParagraph">Ao aceitar esta Política de Privacidade você consente expressamente sobre a forma que a
          Simbiose coleta, trata e protege suas informações e dados, conforme aqui descrito. Sua aceitação é indispensável para qualquer tipo de utilização dos serviços da <strong>Simbiose Social</strong>. </p>

          <div className="politicaSecaoComImagem">

            <div>

              <img className="policyIcon1" src={policyImage2} alt="policyImage2" />

            </div>

            <div>

              <p className="policyParagraph">Esta Política de Privacidade não se aplica a sites ou aplicativos de terceiros que podem estar ligados aos serviços da Simbiose Social. Você deve ler atentamente os Termos e Condições de Uso e as Políticas de Privacidade desses sites e aplicativos de terceiros para, se desejar, fornecer adequadamente seu consentimento, ainda que tenha chegado a esses ambientes através da Simbiose Social. </p>

              <p className="policyParagraph">Caso você tenha um contrato individual com a Simbiose Social, esse contrato poderá ter termos de privacidade que também se aplicam às informações pessoais. Esses termos poderão ser diferentes ou mais restritivos do que os termos desta Política de Privacidade.</p>

            </div>

          </div>

          <h2 className="titlePolicy">2 - Que dados coletamos?</h2>

          <p className="policyParagraph">Todos os dados e informações que a Simbiose Social possui sobre você, foram: </p>

          <p className="policyParagraph">a. Fornecidos por você mesmo, mediante o consentimento com esta Política de Privacidade; ou <br />

                        b. Estão disponíveis em documentos, sites, bancos de dados e outras fontes de informação pública, aos quais você forneceu consentimento expresso ou tácito para a publicação (seus dados públicos do CPF ou CNPJ, por exemplo, disponibilizados por você para a Receita Federal).  </p>

          <p className="policyParagraph"><strong>As principais informações e dados que a Simbiose Social coleta de você são:</strong> </p>

          <p className="policyParagraph">a. Dados de Identificação: nome, e-mail, endereço, telefone e outros, que você nos forneceu. </p>

          <p className="policyParagraph">b. Dados de Navegação (cookies, log info): dados da sua interação com qualquer plataforma digital mantida pela Simbiose Social, como por exemplo seus dados de interação com os nossos ambientes digitais, as informações sobre o aparelho pelo qual você está acessando nossos ambientes digitais</p>

          <p className="policyParagraph">c. Dados Comerciais: informações de cobrança, histórico de contratação de serviços, dados para faturamento, informações bancárias, histórico de comunicações entre a Simbiose Social e você. </p>

          <p className="policyParagraph">d. Dados Operacionais: seus dados, tornados de acesso público por qualquer ente da administração pública, agência ou órgão governamental. </p>

          <p className="policyParagraph">A Simbiose Social não coleta nenhum tipo de dado sensível, tratando sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, ou qualquer tipo de dado biométrico. </p>

          <div className="politicaSecaoComImagem">

            <div>

              <h2 className="titlePolicy">3 - Onde armazenamos deus Dados?</h2>

              <p className="policyParagraph">Todos os dados e informações digitais coletados pela Simbiose Social são armazenados em nuvem, na plataforma AWS – Amazon, se aplicando aos dados e informações coletados pela Simbiose Social a responsabilidade compartilhada (<a href="https://aws.amazon.com/pt/compliance/shared-responsibility-model/" target="_blank" rel="noopener noreferrer">https://aws.amazon.com/pt/compliance/shared-responsibility-model/</a> e <a href="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf" target="_blank" rel="noopener noreferrer">https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf</a>) para segurança da nuvem e segurança na nuvem. </p>

            </div>

            <div>

              <img className="policyIcon1" src={policyImage3} alt="policyImage3" />

            </div>

          </div>

          <h2 className="titlePolicy">4 - Como Tratamos seus Dados?</h2>

          <p className="policyParagraph">Ao iniciar uma relação com Simbiose, pela contratação ou assinatura de serviços, você passará a acessar os ambientes digitais da Simbiose Social. Nesse momento, passaremos a armazenar as informações pessoais fornecidas em nossos contatos ou ambientes digitais da Simbiose Social, mediante expresso consentimento, somente para: </p>

          <p className="policyParagraph">a. Cumprir os termos de contratação, uso ou fornecimento, acordados com você; <br />

                        b. Promover melhorias internas e análises macro sobre os serviços prestados e ambientes digitais da Simbiose Social; <br />

                        c. Promover, enquanto você permitir, o envio de e-mails e outras formas de comunicação institucional relevante da Simbiose Social, com conteúdo informativo, promocional ou de marketing. </p>

          <p className="policyParagraph">Os Dados de Navegação são anonimizados e utilizados exclusivamente para promoção da melhoria contínua dos serviços prestados pela Simbiose Social. Também utilizamos este tipo de dados fornecidos por você durante a navegação para interagir com aplicações de estatísticas de visitas aos nossos ambientes digitais (Google Analytics). A Simbiose Social poderá eventualmente classificar seus Dados Operacionais e processá-los </p>

          <div className="politicaSecaoComImagem">

            <div>

              <h2 className="titlePolicy">5 -Seus dados serão compartilhados?</h2>

              <p className="policyParagraph">Em hipótese alguma qualquer Dado de Identificação, Dado Comercial ou Dado de Navegação que você cedeu à Simbiose Social, ou que coletamos mediante seu consentimento nos ambientes digitais da Simbiose Social será fornecido a qualquer terceiro, exceto nas hipóteses de:</p>

              <p className="policyParagraph">a. Compartilhamento com afiliadas, subsidiárias ou sucursais, desde que destinado o compartilhamento às mesmas finalidades autorizadas pelo cliente, usuário ou fornecedor; <br />

                                b. Consentimento pessoal e expresso do cliente, usuário ou fornecedor para compartilhamento do dado para finalidade diversa das previamente autorizadas; <br />

                                c. Compartilhamento de Dados Comerciais para fins comerciais protegidos por acordos de confidencialidade, como parte de diligência prévia para operações societárias da Simbiose Social; <br /></p>

            </div>

            <div>

              <img className="policyIcon1" src={policyImage4} alt="policyImage4" />

            </div>

          </div>

          <p className="policyParagraph">

            d. Compartilhamento por ordem judicial expedida por um tribunal competente ou ordem administrativa expedida por órgão competente para fiscalização de dados; <br />

                        e. Compartilhamento permitido pela lei brasileira em casos que houver ameaças ou suspeitas de ameaça à segurança pública ou de Estado.</p>

          <div className="politicaSecaoComImagem">

            <div>

              <h2 className="titlePolicy">6 - Sites de terceiros</h2>

              <p className="policyParagraph">Nosso site pode conter links para sites de terceiros. Não somos responsáveis pelas políticas de privacidade nem pelas práticas de outros sites. Ao acessar um site de terceiro, através de um link ou por qualquer outro meio, ainda que vinculado com a Simbiose Social, o cliente, usuário ou fornecedor deve ler a política de privacidade daquele site para que você possa entender como o site em questão recolhe, utiliza e compartilha dados.</p>

            </div>

            <div>

              <img className="policyIcon1" alt="policyIcon1" src={policyIcon1} />

            </div>

          </div>

          <h2 className="titlePolicy">7 - Como protegemos seus dados?</h2>

          <p className="policyParagraph">Adotamos precauções de segurança em níveis adequados para proteger seus dados contra acessos não autorizados e qualquer tipo de tratamento ilegal. Quando você escolhe uma senha que permita o acesso a qualquer ambiente digital da Simbiose Social, ela será mantida em sigilo e você passará a ser responsável pela não divulgação desta senha.</p>

          <div className="politicaSecaoComImagem">

            <div>

              <img className="policyIcon1" alt="policyIcon1" src={PolicyImage5} />

            </div>

            <div>

              <h2 className="titlePolicy">8 - Alterações na Política de Privacidade </h2>

              <p className="policyParagraph">A Simbiose Social pode alterar esta Política de Privacidade e a maneira como utiliza seus dados. Sempre que houver qualquer tipo de alteração, você será informado ostensivamente sobre as alterações e convidado a consentir com os termos dessa nova Política de Privacidade.</p>

              <h2 className="titlePolicy">9 - Dúvidas ou Exclusão de Dados</h2>

              <p className="policyParagraph">Se você tiver alguma dúvida sobre esta Política de Privacidade, ou ainda, caso deseje solicitar o acesso aos seus dados, a exclusão de algum ou todos os dados ou ainda sua exclusão de alguma das formas de contato realizadas por nós, por favor escreva email para contato@simbiose.social.</p>

            </div>

          </div>

        </div>

      </div>
    )
  }

}

export default PrivacidadeUsuario