import * as React from "react"
import * as TextInputMask from 'react-masked-text';
import {AXIOS} from '../../../service/Base'
import {CHAVE_PUBLICA_ACESSO} from '../../../service/Base'
import {URL_VINDI_API} from '../../../service/Base'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'
import { Redirect } from 'react-router-dom'

import cartoes from '../../../images/cartoesAceitos.svg'
import boleto from "../../../images/boletoIcon.svg"
import expandirIcone from "../../../images/metodoPagamentoIconeExpandir.svg"
import cvvInfo from "../../../images/cvvInfo.svg"
import cartaoCVVIcon from "../../../images/iconeCartaoCVV.svg"


export default class Pagamento extends React.Component {
    axiosSource;
    conteinerRef;
    typingTimer = null;

    constructor(props){
        super(props);

        this.state = {
            exibeDadosProponente: true,
            exibeMetodoPagamento: false,
            pagamentoCartao: true,
            exibeEdicaoUsuarios: false,
            meses: ['01','02','03','04','05','06','07','08','09','10','11','12'],
            anos: [],
            parcelamento: ['12x de R$237,00', '1x de 2.844,00'],
            periodoAssinatura: '',
            precoParceladoSelecionado: '',
            quantidadeUsuarios: '',
            precoAVistaSelecionado: '',
            mudaPraCnpj: false,
            validadorMudarCnpj: false,
            exibeInforcamaoCVV: false,
            idPlanoSelecionado: '',
            gateway_token_vindi: '',
            dadosExistentes: '',
            cupomDesconto: '',
            corBordaInputCupom: '',
            DescontoSetado: false,
            valorAVistaComDesconto: '',
            valorParceladoComDesconto: '',
            cupomSetado: '',
            DescontoSetadoValor: '',
            realizandoPagamento: false,
            erroRealizandoPagamento: false,
            cupomErroString: '',
            bandeirasDisponiveis: '',
            loadingbandeirasDisponiveis: false,
            bandeiraSelecionada: '',
            googleCaptcha: '',
            erroRealizandoPagamentoString: '',
            digitandoCupom: false,
            cnpjCpfValidador: true,
            pesquisandoCEP: false,
            emailValidador: true,
            parcelamentoPlano: [],
            quantidadeParcelas: '',
            valorParcelamento: '',
            descontoValor: '',

            nome: '',
            cnpjCpf: '',
            email: '',
            endereco: '',
            bairro: '',
            numero: '',
            complemento: '',
            cep: '',
            estado: '',
            cidade: '',
            
            numeroCartao: '',
            nomeCartao: '',
            mesExpiracao: '',
            anoExpiracao: '',
            cvv: '',
            opcaoParcalamento: '',


        }
    }

    componentWillMount() {

        window.location.replace('https://simbi.social/');

        this.setState({
            loadingbandeirasDisponiveis: true,
        })

        const hoje = new Date()
        const ano = hoje.getFullYear()

        for(let i = 0; i <= 8; i++) {

            this.state.anos.push(ano+i)

        }

        const quantidadeParcelas = this.props.pediodoAssinatura.toLowerCase() === "anual" ? 12 : this.props.pediodoAssinatura.toLowerCase() === "semestral" ? 6 : 1

        let valorAVista = parseFloat(this.props.precoAVistaSelecionado.replace('.', ''))
        console.log('DIVIDINDO > ' +valorAVista +' por > valores')

        for(let i = 1 ; i <= quantidadeParcelas ; i++) {

            this.state.parcelamentoPlano.push(valorAVista / i)

        }
        
        this.setState({
            parcelamento: this.props.parcelamento,
            periodoAssinatura: this.props.pediodoAssinatura.toLowerCase(),
            precoParceladoSelecionado: this.props.precoParceladoSelecionado,
            quantidadeUsuarios: this.props.quantidadeUsuarios,
            precoAVistaSelecionado: this.props.precoAVistaSelecionado,
            idPlanoSelecionado: this.props.idPlano,
        })

       AXIOS().get('getPaymentMethods', { 	

        })
        .then((response) => {
            console.log(response)
            this.setState({
                bandeirasDisponiveis: response.data,
                loadingbandeirasDisponiveis: false,
            })
            console.log('DEU CERTO CARTÕES')
        })
        .catch((error) => {
            console.log(error)
            this.setState({
                bandeirasDisponiveis: false,
            })           
            console.log('DEU ERRADO CARTÕES') 
        })
    
    }

    componentDidMount() {

        document.addEventListener('mousedown', this.handleClickOutside);

        this.props.inscreverNosCanaisDePagamento()

        this.verificaCadastroUsuario()

    }
  
    componentWillUnmount() {

      document.removeEventListener('mousedown', this.handleClickOutside);

      clearTimeout(this.typingTimer);

    }
    
    setConteinerRef = (node) => {
      this.conteinerRef = node;
    }

    handleClickOutside = (event) => {
      if (this.conteinerRef && !this.conteinerRef.contains(event.target)) {

        this.setState({
            exibeInforcamaoCVV: false,
        })
        
      }
    }

    fechaDadosProponente = () => {

        this.setState({
            exibeDadosProponente: !this.state.exibeDadosProponente,
            exibeMetodoPagamento: this.state.exibeDadosProponente ? true : false,
        })

    }

    fechaMetodoPagamento = () => {

        this.setState({
            exibeMetodoPagamento: !this.state.exibeMetodoPagamento,
            exibeDadosProponente: this.state.exibeMetodoPagamento ? true : false,
        })

    }

    PagaComCartao = () => {

        this.setState({
            pagamentoCartao: true,
        })

    }

    PagaComBoleto = () => {

        this.setState({
            pagamentoCartao: false,
        })

    }

    //
    // INFORMAÇÕES DA PESSOA
    //

    setaNome = (e) => {

        this.setState({            
            nome: e.target.value,
        })

    }
        setaCnpjCpf = (e) => {

            const auxiliador = e.length
            console.log(auxiliador)
    
            this.setState({
                cnpjCpfValidador: e.length != 0 ? false : true,
            })
    
            if(this.state.validadorMudarCnpj && e.length < 14) {
    
                this.setState({
                    validadorMudarCnpj: false,
                    mudaPraCnpj: false,
                }) 
            
            }
    
            if(e.length <= 14 && !this.state.validadorMudarCnpj) {
    
                this.setState({
                    cnpjCpf: e.replace(/[^0-9]/g, ''),
                    validadorMudarCnpj: false,
                })
    
                if(e.length == 14) {
    
                    this.setState({
                        validadorMudarCnpj: true
                    })
    
                }
        
            } else {
    
                this.setState({ 
                    mudaPraCnpj: true,
                    cnpjCpf: e.replace(/[^0-9]/g, ''),
                })
    
            }
            
            if (e.length == 14) {
                var cpf = e
             
                cpf = cpf.replace(/\./g, '');
                cpf = cpf.replace('-', '');
                cpf = cpf.split('');
                
                var v1 = 0;
                var v2 = 0;
                var aux = false;
                
                for (var i = 1; cpf.length > i; i++) {
                    if (cpf[i - 1] != cpf[i]) {
                        aux = true;   
                    }
                } 
                
                if (aux == false) {
                    this.setState({
                        cnpjCpfValidador: false,
                    })
                } 
                
                for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
                    v1 += cpf[i] * p; 
                } 
                
                v1 = ((v1 * 10) % 11);
                
                if (v1 == 10) {
                    v1 = 0; 
                }
                
                if (v1 != cpf[9]) {
                    this.setState({
                        cnpjCpfValidador: false,
                    })
                } 
                
                for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
                    v2 += cpf[i] * p; 
                } 
                
                v2 = ((v2 * 10) % 11);
                
                if (v2 == 10) {
                    v2 = 0; 
                }
                
                if (v2 != cpf[10]) {
                    this.setState({
                        cnpjCpfValidador: false,
                    })
                } else {   
                    this.setState({
                        cnpjCpfValidador: true,
                    })
                }
            } 
            
            else if (e.length == 18) {
                var cnpj = e;
                
                cnpj = cnpj.replace(/\./g, '');
                cnpj = cnpj.replace('-', '');
                cnpj = cnpj.replace('/', ''); 
                cnpj = cnpj.split(''); 
                
                var v1 = 0;
                var v2 = 0;
                var aux = false;
                
                for (var i = 1; cnpj.length > i; i++) { 
                    if (cnpj[i - 1] != cnpj[i]) {  
                        aux = true;   
                    } 
                } 
                
                if (aux == false) {  
                    this.setState({
                        cnpjCpfValidador: false,
                    })
                }
                
                for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
                    if (p1 >= 2) {  
                        v1 += cnpj[i] * p1;  
                    } else {  
                        v1 += cnpj[i] * p2;  
                    } 
                } 
                
                v1 = (v1 % 11);
                
                if (v1 < 2) { 
                    v1 = 0; 
                } else { 
                    v1 = (11 - v1); 
                } 
                
                if (v1 != cnpj[12]) { 
                    this.setState({
                        cnpjCpfValidador: false,
                    }) 
                } 
                
                for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
                    if (p1 >= 2) {  
                        v2 += cnpj[i] * p1;  
                    } else {   
                        v2 += cnpj[i] * p2; 
                    } 
                }
                
                v2 = (v2 % 11); 
                
                if (v2 < 2) {  
                    v2 = 0;
                } else { 
                    v2 = (11 - v2); 
                } 
                
                if (v2 != cnpj[13]) {   
                    this.setState({
                        cnpjCpfValidador: false,
                    })
                } else {  
                    this.setState({
                        cnpjCpfValidador: true,
                    })
                }
            } else {
                
            }
    
        }

    setaEmail = (e) => {

        this.setState({
            email: e.target.value,
            emailValidador: e.target.value.length === 0 ? true : false
        })

        const usuario = e.target.value.substring(0, e.target.value.indexOf("@"));
        const dominio = e.target.value.substring(e.target.value.indexOf("@")+ 1, e.target.value.length);
         
        if ((usuario.length >=1) &&
            (dominio.length >=3) && 
            (usuario.search("@")===-1) && 
            (dominio.search("@")===-1) &&
            (usuario.search(" ")===-1) && 
            (dominio.search(" ")===-1) &&
            (dominio.search(".")!==-1) &&      
            (dominio.indexOf(".") >=1)&& 
            (dominio.lastIndexOf(".") < dominio.length - 1)) {
            this.setState({
                emailValidador: true
            })
        }
        else{
            this.setState({
                emailValidador: false
            })
        }

    }

    setaEndereco = (e) => {

        this.setState({
            endereco: e.target.value,
        })

    }

    setaBairro = (e) => {

        this.setState({
            bairro: e.target.value,
        })

    }

    setaNumero = (e) => {

        this.setState({
            numero: e.target.value,
        })

    }

    setaComplemento = (e) => {

        this.setState({
            complemento: e.target.value,
        })

    }

    setaCep = (e) => {

        this.setState({
            cep: e.target.value,
        })

        this.setState({
            cep: e.target.value.replace(/[^0-9]/g, ''),
        })

        const inputFormatted = e.target.value.replace(/[^0-9]/g, '')

        if(inputFormatted.length === 8) {

            this.setState({
                pesquisandoCEP: true
            })
        
            axios({
                method: 'GET',
                url: `https://viacep.com.br/ws/${inputFormatted}/json/`
            }).then(response => {
                if(response.data.erro === true){
                    this.setState({
                        pesquisandoCEP: false
                    })
                    console.log("error")
                }else {
                    console.log(response.data)
                    this.setState({
                        endereco: response.data.logradouro,
                        bairro: response.data.bairro, 
                        cidade: response.data.localidade, 
                        estado: response.data.uf,
                        pesquisandoCEP: false
                    })
                }
            })
        
        }

    }

    setaEstado = (e) => {

        this.setState({
            estado: e.target.value,
        })

    }

    setaCidade = (e) => {

        this.setState({
            cidade: e.target.value,
        })

    }

    //
    // INFORMAÇÕES DO CARTÃO
    //

    setaNumeroCartao = (e) => {

        this.setState({
            numeroCartao: e,
        })

        e.length > 16 ?

            this.setState({
                bandeiraSelecionada: this.getCardFlag(e)
            })

        :        

            this.setState({
                bandeiraSelecionada: ''
            })

    }

    getCardFlag = (cardnumber) => {

        var cardnumberaux = cardnumber.replace(/[^0-9]+/g, '');

        var cards = {
            visa      : /^4[0-9]{12}(?:[0-9]{3})/,
            mastercard : /^5[1-5][0-9]{14}/,
            american_express      : /^3[47][0-9]{13}/,

            diners    : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
            discover  : /^6(?:011|5[0-9]{2})[0-9]{12}/,
            hipercard  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
            elo        : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
            jcb        : /^(?:2131|1800|35\d{3})\d{11}/,       
            aura      : /^(5078\d{2})(\d{2})(\d{11})$/     
        };

        for (var flag in cards) {
            if(cards[flag].test(cardnumberaux)) {
                return flag;
            }
        }       

        return false;
    }

    setaNomeCartao = (e) => {

        this.setState({
            nomeCartao: e.target.value,
        })

    }

    setaMesExpiracao = (e) => {

        this.setState({
            mesExpiracao: e.target.value,
        })

    }

    setaAnoExpiracao = (e) => {

        this.setState({
            anoExpiracao: e.target.value,
        })

    }

    setaCvv = (e) => {

        this.setState({
            cvv: e.target.value,
        })

    }

    setaParcelamento = (e) => {

        console.log(e.target.value)

        const value = e.target.value

        const opcaoParcalamento = value.split('@')[1]
        const qtdParcelas = value.split('@')[0]

        this.setState({
            opcaoParcalamento: value,
            valorParcelamento: parseInt(opcaoParcalamento),
            quantidadeParcelas: parseInt(qtdParcelas)
        })

    }

    defineParcelas = (parcelas) => {

        switch(parcelas) {
            case 1:
              return '(1x)';
            case 2:
              return '(2x)';
            case 3:
              return '(3x)';
            case 4:
              return '(4x)';
            case 5:
              return '(5x)';
            case 6:
              return '(6x)';
            case 7:
              return '(7x)';
            case 8:
              return '(8x)';
            case 9:
              return '(9x)';
            case 10:
              return '(10x)';
            case 11:
              return '(11x)';
            case 12:
              return '(12x)';
            default:
              return '';
          }

    }

    setaMoedaBR = (valor) => {

        return valor.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})

    }

    setaBandeira = (e) => {

        this.setState({
            bandeiraSelecionada: e.target.value,
        })

    }

    exibeEdicaoUsuarios = () => {

        this.setState({
            exibeEdicaoUsuarios: !this.state.exibeEdicaoUsuarios,
            opcaoParcalamento: ''
        })

    }

    mudaQuanditade = (quantidade) => {

        this.setState({
            exibeEdicaoUsuarios: false,
        })

        this.props.valores.usuarios.map((usuario) => {

            return (

                usuario.qtdUser === quantidade ?

                    this.state.periodoAssinatura === 'mensal' ?

                        this.setState({
                            parcelamento: [usuario.mensal.descricao, usuario.mensal.descricao_a_vista],
                            precoParceladoSelecionado: usuario.mensal.parcelado,
                            quantidadeUsuarios: quantidade,
                            precoAVistaSelecionado: usuario.mensal.a_vista,
                            idPlanoSelecionado: usuario.mensal.plan_id,
                        })

                    :

                    this.state.periodoAssinatura === 'semestral' ? 

                        this.setState({
                            parcelamento: [usuario.semestral.descricao, usuario.semestral.descricao_a_vista],
                            precoParceladoSelecionado: usuario.semestral.parcelado,
                            quantidadeUsuarios: quantidade,
                            precoAVistaSelecionado: usuario.semestral.a_vista,
                            idPlanoSelecionado: usuario.semestral.plan_id,
                        })

                    :

                        this.setState({
                            parcelamento: [usuario.anual.descricao, usuario.anual.descricao_a_vista],
                            precoParceladoSelecionado: usuario.anual.parcelado,
                            quantidadeUsuarios: quantidade,
                            precoAVistaSelecionado: usuario.anual.a_vista,
                            idPlanoSelecionado: usuario.anual.plan_id,
                        })     
                    
                :

                    ''

            )

        })

        if(this.state.DescontoSetado) {
            
            setTimeout(() => {
                this.setaDesconto(this.state.descontoValor)
            }, 20)

        }

    }

    exibeInforcamaoCVV = () => {

        this.setState({
            exibeInforcamaoCVV: !this.state.exibeInforcamaoCVV
        })

    }

    recebeCaptcha = (value) => {

        /*
        
            site key = 6LcED60ZAAAAAF2g7DhalNIEawcwKuAYqty1-AQS
            secret key = 6LcED60ZAAAAAC4tlVFROqHn49yOcRv9fT8t7YRp

        */

        this.setState({
            googleCaptcha: value
        })

    }

    /* 
    
        MÉTODOS DE PAGAMENTO ABAIXO
    
    */
   
    setaCupomDesconto(event) {

		let value = event.target.value

		this.setState({
            corBordaInputCupom: value.length === 0 && '',
            digitandoCupom: value.length === 0 ? false : true
		})

		clearTimeout(this.typingTimer);

		this.typingTimer = setTimeout(() => {
            if (value) {
                this.validaCupom(value)
            }
        }, 2000);

    }

    validaCupom = (value) => {

        document.getElementById("inputCupom").blur();

        this.setState({
            corBordaInputCupom: 'verificando'
        })

        if(value.length > 0) {

            AXIOS().post('validarCupom', {
                'codigo_pagina': value,
            })
            .then((response) => {
                this.setState({
                    cupomErroString: '',
                    digitandoCupom: false,
                    descontoValor: response.data.data.id_desconto
                })
                console.log(response)
                this.setaDesconto(response.data.data.id_desconto)
            }).catch((error) => {
                let response = error.response;
                this.setState({
                    corBordaInputCupom: 'falha',
                    cupomErroString: response.data.message,
                    digitandoCupom: false,
                    cupomSetado: '',
                })
                console.error(error)
            })
    
        }

        this.setState({
            cupomSetado: value,
        })

    }

    setaDesconto = (desconto) => {
        
        const valorAVista = parseFloat(this.state.precoAVistaSelecionado.replace(".", ""))
        const valorParcelado = parseFloat(this.state.precoParceladoSelecionado)

        const valorAVistaComDesconto = valorAVista - (valorAVista * (desconto/100)) 
        const valorParceladoComDesconto = valorParcelado - (valorParcelado * (desconto/100))
        
        this.setState({
            DescontoSetado: true,
            DescontoSetadoValor: desconto,
            valorAVistaComDesconto: valorAVistaComDesconto,
            valorParceladoComDesconto: valorParceladoComDesconto,
            corBordaInputCupom: 'sucesso'
        })

    }

    verificaCadastroUsuario = () => {

        this.setState({
            verificandoCadastroExistente: true,
        })

        AXIOS().get('getCustomer')
        .then((response) => {
            this.setState({
                nome: response.data.data.name,
                cnpjCpf: response.data.data.registry_code,
                mudaPraCnpj: response.data.data.registry_code.length < 14 ? false : true,
                email: response.data.data.email,
                verificandoCadastroExistente: false,
                endereco: response.data.data.address.street,
                bairro: response.data.data.address.neighborhood,
                numero: response.data.data.address.number,
                complemento: response.data.data.address.additional_details,
                cep: response.data.data.address.zipcode,
                estado: response.data.data.address.state,
                cidade: response.data.data.address.city,
            })
            console.log(response.data.data)
            console.log('ACIMAAAAA')

        }).catch((error) => {
            this.setState({
                verificandoCadastroExistente: false
            })
            console.error(error)
        })

    }

    preencheInfoUsuario = () => {

        console.log('usuario com dados existentes já')

    }

    iniciaPagamento = () => {

        this.setState({
            realizandoPagamento: true,
        })

        this.state.pagamentoCartao ?

            this.enviaDadosCartao()

        :

            this.realizaPagamento()
    
    }

    enviaDadosCartao = () => {

        console.log('dados cartão teste')

        const dataExpiracao = this.state.mesExpiracao+'/'+this.state.anoExpiracao

        var profile = {
            "holder_name": this.state.nomeCartao,
            "card_expiration": dataExpiracao,
            "card_number": this.state.numeroCartao,
            "card_cvv": this.state.cvv,
            "payment_method_code": "credit_card",
            "payment_co_code": this.state.bandeiraSelecionada
        } 

        fetch(URL_VINDI_API +'public/payment_profiles', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Basic " + btoa(CHAVE_PUBLICA_ACESSO + ":"),
            },
            body: JSON.stringify(profile)
        })
        .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                if(data.errors) {

                    this.setState({
                        realizandoPagamento: false,
                        erroRealizandoPagamento: true,
                        erroRealizandoPagamentoString: data.errors[0].message
                    })

                } else {

                    this.setState({
                        gateway_token_vindi: data.payment_profile.gateway_token
                    })
                    this.realizaPagamento()

                }
            })
            .catch((errors) => {
            });

    }

    realizaPagamento = () => {

        console.log('Realiza pagamento')

        //this.props.inscreverNosCanaisDePagamento()

        AXIOS().post('postAssinatura', {
            "name": this.state.nome, //NOME DO FORMULÁRIO
            "email": this.state.email, //EMAIL DO FORMULARIO
            "registry_code": this.state.cnpjCpf, //DOCUMENTO DO FORMULÁRIO CPF/CNPJ
            "address": { //CAMPOS DE ENDEREÇO DO FORMULÁRIO
                "street": this.state.endereco,
                "number": this.state.numero,
                "additional_details": this.state.complemento,
                "neighborhood": this.state.bairro,
                "zipcode": this.state.cep,
                "city": this.state.cidade,
                "state": this.state.estado
            },
            "payment_method_code": this.state.pagamentoCartao ? "credit_card" : "bank_slip", //MÉTODO DE PAGAMENTO CREDIT_CARD OU BANK_SLIP
            "gateway_token": this.state.gateway_token_vindi, //GATEWAY TOKEN QUE VEM DO ENDPOINT DA VINDI
            "plan_id": this.state.idPlanoSelecionado, //ID DO PLANO QUE ESTOU CONTRATANDO
            "codigo_pagina": this.state.DescontoSetado ? this.state.cupomSetado : '',
            'periodo': this.state.periodoAssinatura,
            'quantidadeUsuarios': this.state.quantidadeUsuarios,
            'valor': this.state.DescontoSetado ? this.state.valorParceladoComDesconto : parseInt(this.state.precoParceladoSelecionado)
        })
		.then((response) => {
			console.log(response)
            this.setState({
                realizandoPagamento: false,
                erroRealizandoPagamento: false,
                erroRealizandoPagamentoString: '',
            })
            this.props.pagamentoConfirmado(this.props.pediodoAssinatura, this.props.quantidadeUsuarios, this.state.DescontoSetado ? this.state.valorParceladoComDesconto : parseInt(this.props.precoParceladoSelecionado), this.state.pagamentoCartao)
		})
		.catch((error) => {
			console.log(error)
            this.setState({
                realizandoPagamento: false,
                erroRealizandoPagamento: true,
                erroRealizandoPagamentoString: error.response.data.message
            })            
        })

    }

    voltarAoPagamento = () => {

        this.setState({
            erroRealizandoPagamento: false,
            erroRealizandoPagamentoString: ''
        })

    }

    /* 
    
        MÉTODOS DE PAGAMENTO ACIMA

    */
    
    render() {

        const logged = localStorage.getItem('@oauth:token')

        const radioSelecionada = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"> <rect width="14" height="14" rx="7" fill="#71CAD4"/> </svg>
        /*
        const cartoes = "/src/images/cartoesAceitos.svg"
        const boleto = "/src/images/boletoIcon.svg"
        const expandirIcone = "/src/images/metodoPagamentoIconeExpandir.svg"
        const cvvInfo = "/src/images/cvvInfo.svg"
        const cartaoCVVIcon = "/src/images/iconeCartaoCVV.svg"
        */
        const iconDone = <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none"> <path d="M1 4.66667L4.96 9L10 1" stroke="white" strokeWidth="1.3" strokeLinecap="round"/> </svg>

        const informacoesCadastroFinalizada = 
            this.state.nome 
            && this.state.cnpjCpf 
            && this.state.cnpjCpf.length !== 0
            && this.state.cnpjCpfValidador
            && this.state.email 
            && this.state.email.length !== 0
            && this.state.emailValidador
            && this.state.endereco 
            && this.state.bairro 
            && this.state.numero
            && this.state.cep
            && this.state.estado
            && this.state.cidade
            && !this.state.digitandoCupom

        const informacoesCartaoFinalizadas = 
            (this.state.numeroCartao
            && this.state.nomeCartao
            && this.state.mesExpiracao
            && this.state.anoExpiracao
            && this.state.cvv
            && this.state.bandeiraSelecionada
            && this.state.googleCaptcha) || (!this.state.pagamentoCartao && true)

            return (

                !logged ?

                    <Redirect 
                        to={{
                            pathname: "/login",
                            state: { 
                                from: 'pagamento',
                                parcelamento: this.props.parcelamento,
                                periodoAssinatura: this.props.pediodoAssinatura.toLowerCase(),
                                precoParceladoSelecionado: this.props.precoParceladoSelecionado,
                                quantidadeUsuarios: this.props.quantidadeUsuarios,
                                precoAVistaSelecionado: this.props.precoAVistaSelecionado,
                                idPlanoSelecionado: this.props.idPlano, 
                                parcelamentoPlano: this.state.parcelamentoPlano,
                                planoSelecionadoProponente: this.props.planoSelecionadoProponente,
                                stPlano: this.props.stPlano,
                                linkPagamento: this.props.linkPagamento,
                                card: this.props.card
                            }
                        }}
                    />

                :

                <>

                    {

                        this.state.erroRealizandoPagamento &&

                            <div className="paginaPropoentesPagamentoErroDurantePagamento">

                                <div className="paginaPropoentesPagamentoErroDurantePagamentoConteudo">

                                    <div className="paginaPropoentesPagamentoErroDurantePagamentoTitulo">

                                        Ops! Ocorreu um erro durante o processamento dos dados :(

                                    </div>

                                    <div className="paginaPropoentesPagamentoErroDurantePagamentoErro">

                                        Erro nas informações do cartão: {this.state.erroRealizandoPagamentoString}

                                    </div>

                                    <div className="paginaPropoentesPagamentoErroDurantePagamentoBotaoContainer">

                                        <div className="paginaPropoentesPagamentoErroDurantePagamentoBotao" onClick={() => this.voltarAoPagamento()}>

                                            Voltar

                                        </div>

                                    </div>

                                </div>

                            </div>

                    }

                    <div style={{filter: this.state.erroRealizandoPagamento && 'blur(4px)', pointerEvents: this.state.realizandoPagamento ? 'none' : 'auto'}} className="paginaPropoentesPagamentoContainer">

                        <div className="paginaPropoentesPagamentoConteudoDados">

                            <div className="paginaPropoentesPagamentoConteudoDadosProponente" 
                                style={{
                                    height: !this.state.exibeDadosProponente && '60px',
                                    padding: !this.state.exibeDadosProponente && '0 20px 0 20px',   
                                    cursor: !this.state.exibeDadosProponente && 'pointer',                            
                                }}
                                onClick={!this.state.exibeDadosProponente ? () => {
                                    this.fechaDadosProponente()} : null
                                }
                                >   

                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeader" style={{
                                    borderBottom: !this.state.exibeDadosProponente && 'hidden'
                                }}>

                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderInicio">

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderIcone" style={{backgroundColor: informacoesCadastroFinalizada && '#1F99A7'}}>

                                            {informacoesCadastroFinalizada && iconDone}

                                        </div>

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderTexto">

                                            Informações de pagamento

                                                <span className="infoNotaFiscal">(Aparecerão na nota fiscal)</span>

                                        </div>

                                    </div>

                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderFim">

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderFimEditar" onClick={() => this.fechaDadosProponente()}>

                                            editar

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderFimFechar" style={{transform: this.state.exibeDadosProponente && 'rotate(180deg)'}}>

                                                <img alt="expandirIcone" src={expandirIcone} />

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormulario">

                                    {

                                        this.state.verificandoCadastroExistente ?

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioVerificandoCadastro">

                                                Aguarde...

                                            </div>

                                        :

                                            <>

                                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCampos">

                                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCamposEsquerdo">

                                                        Nome / Razão Social

                                                        <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" value={this.state.nome} onChange={(e) => this.setaNome(e)} />

                                                    </div>

                                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCamposDireito">

                                                        CNPJ/CPF

                                                            <TextInputMask 
                                                                type="text" 
                                                                name="st_cnpj" 
                                                                placeholder="" 
                                                                className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput"
                                                                kind={this.state.mudaPraCnpj ? 'cnpj' : 'cpf'}
                                                                value={this.state.cnpjCpf}
                                                                onChangeText={this.setaCnpjCpf}
                                                                style={{
                                                                    border: !this.state.cnpjCpfValidador && '1px solid #FF988E', 
                                                                    color: !this.state.cnpjCpfValidador && '#FF988E'
                                                                }}
                                                            />

                                                            {

                                                                !this.state.cnpjCpfValidador && this.state.exibeDadosProponente &&

                                                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInputErroCnpj">

                                                                        Digite um CNPJ/CPF válido

                                                                    </div>

                                                            }

                                                    </div>

                                                </div>

                                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioUmCampo">

                                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioEmail">

                                                        Email

                                                        <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" value={this.state.email} onChange={(e) => this.setaEmail(e)} 
                                                            style={{
                                                                border: !this.state.emailValidador && '1px solid #FF988E', 
                                                                color: !this.state.emailValidador && '#FF988E'
                                                            }}
                                                        />

                                                        {

                                                            !this.state.emailValidador && this.state.exibeDadosProponente &&

                                                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInputErroEmail">

                                                                    Digite um email válido

                                                                </div>

                                                        }

                                                    </div>

                                                </div>

                                                <div style={{pointerEvents: this.state.pesquisandoCEP ? 'none' : 'auto'}}>

                                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioTresCampos">

                                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioTresCamposArea">

                                                            CEP {this.state.pesquisandoCEP ? ' - Pesquisando...' : ''}

                                                            <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" value={this.state.cep} onChange={(e) => this.setaCep(e)} />

                                                        </div>

                                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioTresCamposArea">

                                                            Estado (UF)

                                                            <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" maxLength={2} value={this.state.estado.toUpperCase()} onChange={(e) => this.setaEstado(e)} />

                                                        </div>

                                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioTresCamposArea">

                                                            Cidade

                                                            <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" value={this.state.cidade} onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} onChange={(e) => this.setaCidade(e)} />

                                                        </div>

                                                    </div>

                                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCampos">

                                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCamposEsquerdo">

                                                            Endereço

                                                            <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" value={this.state.endereco} onChange={(e) => this.setaEndereco(e)} />

                                                        </div>

                                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCamposDireito">

                                                            Número

                                                            <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" value={this.state.numero} onChange={(e) => this.setaNumero(e)} />

                                                        </div>

                                                    </div>

                                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCampos">

                                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCamposEsquerdoMenor">

                                                            Complemento
                                                            
                                                            <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" value={this.state.complemento} onChange={(e) => this.setaComplemento(e)} />

                                                        </div>

                                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCamposDireitoMenor">

                                                            Bairro

                                                            <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" value={this.state.bairro} onChange={(e) => this.setaBairro(e)} />

                                                        </div>

                                                    </div>

                                                </div>

                                            </>
                                    
                                    }

                                </div>

                            </div> 
                            
                            <div className="paginaPropoentesPagamentoConteudoMetodoPagamento" 
                                style={{
                                    height: !this.state.exibeMetodoPagamento ? '60px' : !this.state.pagamentoCartao ? '450px' : '800px',
                                    padding: !this.state.exibeMetodoPagamento && '0 20px 0 20px',    
                                    cursor: !this.state.exibeMetodoPagamento && 'pointer',                            
                                }}
                                onClick={!this.state.exibeMetodoPagamento ? () => {
                                    this.fechaMetodoPagamento()} : null
                                }
                                >

                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeader" style={{
                                    borderBottom: !this.state.exibeMetodoPagamento && 'hidden'
                                }}>

                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderInicio">

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderIcone" style={{backgroundColor: informacoesCartaoFinalizadas && '#1F99A7'}}>

                                            {informacoesCartaoFinalizadas && iconDone}

                                        </div>

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderTexto">

                                            Método de pagamento

                                        </div>

                                    </div>

                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderFim">

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderFimEditar" onClick={() => this.fechaMetodoPagamento()}>

                                            editar

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponenteHeaderFimFechar" style={{transform: this.state.exibeMetodoPagamento && 'rotate(180deg)'}}>

                                                <img alt="expandirIcone" src={expandirIcone} />

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoContainer">

                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoConteudo">

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoConteudoHeader">

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoConteudoHeaderEsquerdo">

                                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoRadioCartao" onClick={() => this.PagaComCartao()}>

                                                    {this.state.pagamentoCartao && radioSelecionada}

                                                </div>

                                                Cartão de Crédito

                                            </div>

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoConteudoTiposCartoes">

                                                <img alt="cartoesAceitos" src={cartoes} />
                                                
                                            </div>

                                        </div>

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCampos" style={{display: !this.state.pagamentoCartao && 'none'}}>

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCamposEsquerdo">

                                                Número do cartão {this.state.bandeiraSelecionada && '- ('+this.state.bandeiraSelecionada+')'}

                                                <TextInputMask 
                                                    type="credit-card" 
                                                    placeholder="" 
                                                    className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput"
                                                    kind={'credit-card'}
                                                    value={this.state.numeroCartao}
                                                    onChangeText={this.setaNumeroCartao}
                                                />

                                            </div>

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioDoisCamposDireito">

                                                Nome do titular

                                                <input className="paginaPropoentesPagamentoConteudoDadosProponenteFormularioInput" value={this.state.nomeCartao} onChange={(e) => this.setaNomeCartao(e)} />

                                            </div>

                                        </div>

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoDataExpiracao" style={{display: !this.state.pagamentoCartao && 'none'}}>

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoDataSelect">

                                                Data de Expiração

                                                <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoDataSelectInputs">

                                                    <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoMes">

                                                        <select className="dropdownSelecionaValidade" value={this.state.mesExpiracao} onChange={(e) => this.setaMesExpiracao(e)}>

                                                            <option selected hidden> Mês </option>

                                                            {this.state.meses.map((mes, index) => {
                                                                return (
                                                                    <option key={index} value={mes}>{mes}</option>
                                                                )
                                                            })}
                                                        </select>

                                                    </div>

                                                    <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoAno">

                                                        <select className="dropdownSelecionaValidade" value={this.state.anoExpiracao} onChange={(e) => this.setaAnoExpiracao(e)}>

                                                            <option selected hidden> Ano </option>

                                                            {this.state.anos.map((ano, index) => {
                                                                return (
                                                                    <option key={index} value={ano}>{ano}</option>
                                                                )
                                                            })}
                                                        </select>

                                                    </div>

                                                </div>

                                            </div>

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoCVV">

                                                <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoCVVString">

                                                    CVV <img alt="cvv" className="cvvicon" src={cvvInfo} onClick={() => this.exibeInforcamaoCVV()} />

                                                    {

                                                        this.state.exibeInforcamaoCVV &&

                                                            <div ref={this.setConteinerRef} className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoCVVInformativo">

                                                                <div className="paginaProponenteCVVInformaticoConteudo">

                                                                    <div className="paginaProponenteCVVInformaticoTexto">

                                                                        O número CVV é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o CVV é um número de 4 dígitos na frente do cartão.

                                                                    </div>

                                                                    <div className="paginaProponenteCVVInformaticoImagem">

                                                                        <img alt="cvvinfo" src={cartaoCVVIcon} />

                                                                    </div>

                                                                </div>
        
                                                            </div>

                                                    }

                                                </div>

                                                <input className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoCVV" maxLength={4} value={this.state.cvv} onChange={(e) => this.setaCvv(e)} />

                                            </div>

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoParcelamento">

                                                {/*

                                                Opções de parcelamento

                                                <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoParcelamentoSelect">

                                                        <select className="dropdownSelecionaParcelamento" value={this.state.opcaoParcalamento} onChange={(e) => this.setaParcelamento(e)}>

                                                            <option selected hidden> Parcelamento </option>

                                                            {this.state.parcelamentoPlano.map((parcelamento, index) => {
                                                                return (
                                                                    <option key={index} value={index+1 +"@" +parcelamento}>{index+1 +'x de ' +parseInt(parcelamento).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</option>
                                                                )
                                                            })}
                                                        </select>

                                                </div>

                                                Bandeira do cartão

                                                <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoDadosCartaoParcelamentoSelect">

                                                        <select className="dropdownSelecionaParcelamento" value={this.state.bandeiraSelecionada} onChange={(e) => this.setaBandeira(e)}>

                                                            <option selected hidden> {this.state.loadingbandeirasDisponiveis ? 'carregando...' : 'Selecione a bandeira'} </option>

                                                            {!this.state.loadingbandeirasDisponiveis && this.state.bandeirasDisponiveis.map((bandeira, index) => {
                                                                
                                                                return (

                                                                    bandeira.code === 'credit_card' &&

                                                                        bandeira.payment_companies.map((companhia, index) => {

                                                                            return (

                                                                                <option key={index} value={companhia.code}>{companhia.name}</option>

                                                                            )

                                                                        })

                                                                )

                                                            })}
                                                        </select>

                                                </div>
                                                
                                                */}

                                            </div>

                                        </div>

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponentePagamentoNaoSouRobo" style={{display: !this.state.pagamentoCartao && 'none'}}>

                                            <ReCAPTCHA
                                                sitekey="6LcED60ZAAAAAF2g7DhalNIEawcwKuAYqty1-AQS"
                                                onChange={this.recebeCaptcha}
                                                hl={'pt-BR'}
                                            />

                                        </div>

                                    </div>

                                </div>
                                
                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoSeparacao" />
                                
                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoContainer">

                                    <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoConteudo">

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoConteudoHeader">

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoConteudoHeaderEsquerdo">

                                                <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoRadioCartao" onClick={() => this.PagaComBoleto()}>

                                                    {!this.state.pagamentoCartao && radioSelecionada}

                                                </div>

                                                Boleto Bancário

                                            </div>

                                            <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoConteudoTiposCartoes">

                                                <img alt="cartoesAceitos" src={boleto} />
                                                
                                            </div>

                                        </div>

                                        <div className="paginaPropoentesPagamentoConteudoDadosProponenteMetodoConteudoBoletoString">

                                            Boleto (Somente à vista). <br />

                                            Pagamentos com Boleto Bancário levam até 3 dias úteis para serem compensados e então terem os produtos liberados. <br />
                                            
                                            Atente-se ao vencimento do boleto. Você pode pagar o boleto em qualquer banco ou casa lotérica até o dia do vencimento.

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="paginaPropoentesPagamentoConteudoInformacao">

                            <div className="paginaPropoentesPagamentoConteudoInformacaoConteudo">

                                <div className="paginaPropoentesPagamentoConteudoInformacaoHeader">

                                    <div className="paginaPropoentesPagamentoConteudoInformacaoHeaderTitulo">

                                        Assinatura {this.state.periodoAssinatura}

                                    </div>

                                    {/*

                                    <div className="paginaPropoentesPagamentoConteudoInformacaoHeaderTitulo">

                                        Fatura mensal

                                        {
                                        
                                            //this.state.pagamentoCartao ? 'Fatura mensal' : 'Fatura à vista'
                                        
                                        }

                                    </div>

                                    */}

                                </div>

                                <div className="paginaPropoentesPagamentoConteudoInformacaoPagamento">

                                    <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfo">

                                        <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoTitulo">

                                            Acesso de:

                                        </div>

                                        <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoValor">

                                            {this.state.quantidadeUsuarios === 1 ? this.state.quantidadeUsuarios +' usuário' : this.state.quantidadeUsuarios + ' usuários'}

                                        </div>

                                    </div>

                                    <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoPagamentoValor">

                                        <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoEditar" onClick={() => this.exibeEdicaoUsuarios()}>

                                            editar

                                        </div>

                                        {

                                            this.state.exibeEdicaoUsuarios &&

                                                <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoEditarQtdUsuarios">

                                                    <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoEditarQtdUsuariosUsuario" onClick={() => this.mudaQuanditade(1)}>1 usuário</div>

                                                    <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoEditarQtdUsuariosUsuario" onClick={() => this.mudaQuanditade(3)}>3 usuários</div>

                                                    <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoEditarQtdUsuariosUsuario" onClick={() => this.mudaQuanditade(5)}>5 usuários</div>

                                                </div>

                                        }

                                    </div>

                                </div>

                                <div className="paginaPropoentesPagamentoConteudoBarraDivisaoContainer">

                                    <div className="paginaPropoentesPagamentoConteudoBarraDivisao" />

                                </div>

                                {

                                    this.state.pagamentoCartao ?

                                        <div className="paginaPropoentesPagamentoConteudoInformacaoPagamento preco">

                                            <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfo">

                                                <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoTitulo">

                                                    Pagamento:

                                                </div>

                                                <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoValor">

                                                    {

                                                        this.state.quantidadeParcelas ? 
                                                        
                                                            'Parcelado ' +this.defineParcelas(this.state.quantidadeParcelas)
                                                        
                                                        :
                                                    
                                                            this.state.periodoAssinatura === 'mensal' ? 
                                                            
                                                                'Mensal (1x)' 
                                                                
                                                            : 
                                                            
                                                                this.state.periodoAssinatura === 'semestral' ? 
                                                                
                                                                    'Semestral (6x)' 
                                                                    
                                                                : 
                                                                
                                                                    'Anual (12x)'} 

                                                </div>

                                            </div>

                                            <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoPagamentoValor">

                                                <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoValorDescontado">

                                                    {this.state.DescontoSetado && 'R$'+this.state.precoParceladoSelecionado}

                                                </div>

                                                <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoValor">

                                                    {this.state.DescontoSetado ? this.state.valorParceladoComDesconto.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"}) : this.state.valorParcelamento ? this.setaMoedaBR(this.state.valorParcelamento) : 'R$'+this.state.precoParceladoSelecionado}

                                                </div>

                                            </div>

                                        </div>

                                    :

                                        <div className="paginaPropoentesPagamentoConteudoInformacaoPagamento preco">

                                            <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfo">

                                                <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoTitulo">

                                                    Pagamento:

                                                </div>

                                                <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoValor">

                                                    À vista (1x)

                                                </div>

                                            </div>

                                            <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoPagamentoValor">

                                                <div className="paginaPropoentesPagamentoConteudoInformacaoPagamentoInfoValor">

                                                    R${this.state.precoAVistaSelecionado}

                                                </div>

                                            </div>

                                        </div>                                    
                                
                                }

                                <div className="paginaPropoentesPagamentoConteudoBarraDivisaoContainer">

                                    <div className="paginaPropoentesPagamentoConteudoBarraDivisao" />

                                </div>

                                <div className="paginaPropoentesPagamentoConteudoInformacaoTotal">

                                    <div className="paginaPropoentesPagamentoConteudoInformacaoTotalValorDescontado">

                                        {

                                            this.state.DescontoSetado &&

                                                <>

                                                    <div className="paginaPropoentesPagamentoConteudoInformacaoTotalValorDescontadoInfo">
            
                                                        {this.state.cupomSetado +'    -' +this.state.DescontoSetadoValor+'%'} 
            
                                                    </div>
            
                                                    <div className="paginaPropoentesPagamentoConteudoInformacaoTotalValorDescontadoValor">
            
                                                        {this.state.precoAVistaSelecionado}
            
                                                    </div>

                                                </>

                                        }

                                    </div>

                                    <div className="paginaPropoentesPagamentoConteudoInformacaoTotalInfo">

                                        <div className="paginaPropoentesPagamentoConteudoInformacaoTotalString">

                                            TOTAL

                                        </div>

                                        <div className="paginaPropoentesPagamentoConteudoInformacaoValorRealizar">

                                            {this.state.DescontoSetado ? this.state.valorAVistaComDesconto.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"}) : 'R$'+this.state.precoAVistaSelecionado}

                                        </div>

                                    </div>

                                    <div className="paginaPropoentesPagamentoConteudoInformacaoTotalDescricao">

                                    {

                                        this.state.pagamentoCartao &&

                                            `*Valor será parcelado mensalmente durante 1 ano para ${this.state.quantidadeUsuarios === 1 ? this.state.quantidadeUsuarios +' usuário' : this.state.quantidadeUsuarios + ' usuários'}.`

                                    }

                                    </div>

                                </div>

                                <div className="paginaPropoentesPagamentoConteudoInformacaoCupomContainer">

                                    <input id="inputCupom" className={"paginaPropoentesPagamentoConteudoInformacaoCupom " +this.state.corBordaInputCupom} placeholder="CUPOM DE DESCONTO" onChange={(e) => this.setaCupomDesconto(e)} />

                                    <div className={"paginaPropoentesPagamentoConteudoInformacaoStatus " +this.state.corBordaInputCupom}>

                                        {
                                        
                                            this.state.corBordaInputCupom === 'verificando' ?

                                                'Verificando cupom...' 
                                                
                                            :

                                            this.state.corBordaInputCupom === 'sucesso' ?

                                                'Cupom validado com sucesso!'

                                            :

                                            this.state.corBordaInputCupom === 'falha' ?

                                                this.state.cupomErroString

                                            :

                                                ''
                                        
                                        }

                                    </div>

                                </div>

                                <div className="paginaPropoentesPagamentoConteudoInformacaoBotaoContainer">

                                    <div className="paginaPropoentesPagamentoConteudoInformacaoBotal" style={{
                                        color: (!informacoesCartaoFinalizadas || !informacoesCadastroFinalizada || this.state.corBordaInputCupom === 'verificando') && 'rgb(128, 128, 128)',
                                        backgroundColor: (!informacoesCartaoFinalizadas || !informacoesCadastroFinalizada || this.state.corBordaInputCupom === 'verificando') && 'rgb(235, 235, 235)',
                                        pointerEvents: (!informacoesCartaoFinalizadas || !informacoesCadastroFinalizada || this.state.realizandoPagamento || this.state.corBordaInputCupom === 'verificando') ? 'none' : 'auto',
                                    }}
                                    onClick={() => this.iniciaPagamento()}
                                    >

                                        {
                                        
                                            this.state.corBordaInputCupom === 'verificando' ? 
                                            
                                                'Verificando cupom...' 
                                                
                                            : 

                                            this.state.realizandoPagamento ?

                                                this.state.pagamentoCartao ?

                                                    'Realizando pagamento...'

                                                :

                                                    'Gerando boleto...'
                                            
                                            :

                                            this.state.pagamentoCartao ?

                                                'Realizar pagamento'

                                            :

                                                'Gerar boleto'}

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </>

        )
    }
}