import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { AXIOS } from '../../service/Base'

import PerguntasERespostasEmpresas from '../actioncomponents/PerguntasERespostasEmpresas'
import Footer from "../Footer"
import Potencialize from "../Potencialize"
import PrivacidadeAuditoria from './components/PoliticaseTermos/PrivacidadeAuditoria'
import PrivacidadeRadardePatrocinadores from './components/PoliticaseTermos/PrivacidadeRadardePatrocinadores'
import PrivacidadeUsuario from './components/PoliticaseTermos/PrivacidadeUsuario'
import SideBarPolicy from './components/PoliticaseTermos/SideBarPolicy'

class PrivacyPolicy extends React.Component {

    constructor() {

        super()

        this.state = {
            politicas: [],
            conteudoPoliticaSelecionado: [],
            id_politica_selecionada: "",
            carregando: true,
            tamanhoTela: 0

        }

    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.capturaDados()
        this.setState({
            tamanhoTela: document.getElementById('root').offsetWidth
        })

    }

    capturaDados() {
        AXIOS().get('listapoliticas').then((response) => {

            this.setState({
                politicas: response.data,
                conteudoPoliticaSelecionado: response.data["0"] && response.data["0"].conteudos,
                id_politica_selecionada: response.data["0"] && response.data["0"].id_politica,

            })

            setTimeout(() => {
                this.setState({
                    carregando: false
                })
            }, 1500)
        })
    }


    render() {

        return (

            <>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                    {
                        this.state.carregando ?

                            this.state.tamanhoTela > 500 &&
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <div style={{ width: '250px', display: 'flex', flexDirection: 'column', height: '40px', marginRight: '15px', marginBottom: '20px', marginLeft: '10px' }}>
                                    <div className="skeletonLoader" />
                                </div>
                                <div style={{ width: '250px', display: 'flex', flexDirection: 'column', height: '40px', marginRight: '15px', marginBottom: '20px' }}>
                                    <div className="skeletonLoader" />
                                </div>
                                <div style={{ width: '250px', display: 'flex', flexDirection: 'column', height: '40px', marginRight: '15px', marginBottom: '20px' }}>
                                    <div className="skeletonLoader" />
                                </div>
                            </div>


                            :
                            this.state.tamanhoTela > 500 &&
                            <div style={{ width: '250px', display: 'flex', alignItems: 'center', flexDirection: 'column', borderRadius: '6px' }}>
                                {

                                    this.state.politicas && this.state.politicas.length > 0 &&
                                    this.state.politicas.map((response) => (
                                        <a className={response.id_politica === this.state.id_politica_selecionada ? "NavButtonactive" : "NavButton"}
                                            onClick={() => this.setState({ conteudoPoliticaSelecionado: response.conteudos, id_politica_selecionada: response.id_politica })}
                                        >
                                            {response.nome_politica}
                                        </a>
                                    ))
                                }
                            </div>

                    }

                    {
                        this.state.carregando ?
                            <div style={{ display: 'flex', flexDirection: 'column', width: this.state.tamanhoTela < 500 && "100%" }}>
                                <div className="paginaPropoentesPerguntasERespostas" style={{ minHeight: "500px" }}>
                                    <div className="paginaPropoentesPerguntasERespostasConteudo" style={{ maxWidth: '900px', marginTop: '50px', flexDirection: 'column' }}>
                                        <div className="skeletonLoaderTitle" style={{ maxWidth: '100%' }} />
                                        <br />
                                        <div className="skeletonLoaderParagrafo" style={{ maxWidth: '80vw' }} />
                                        <br />
                                        <div className="skeletonLoaderParagrafo" style={{ marginTop: 20, maxWidth: '100%' }} />
                                        <br />
                                        <div className="skeletonLoaderParagrafo" style={{ marginTop: 20, maxWidth: '100%' }} />
                                        <br />
                                        <div className="skeletonLoaderTitle" style={{ marginTop: 80 }} />
                                        <br />
                                        <div className="politicaSecaoComImagem" style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
                                                <div className="skeletonLoaderParagrafoImagem" />
                                                <div className="skeletonLoaderParagrafoImagem" />
                                                <div className="skeletonLoaderParagrafoImagem" />
                                            </div>
                                            <div className="skeletonLoaderImagem" />
                                        </div>
                                        <div className="politicaSecaoComImagem" style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <div className="skeletonLoaderImagem" />
                                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
                                                <div className="skeletonLoaderParagrafoImagem" />
                                                <div className="skeletonLoaderParagrafoImagem" />
                                                <div className="skeletonLoaderParagrafoImagem" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <PerguntasERespostasEmpresas entrarEmContato={this.entrarEmContato} />
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', width: this.state.tamanhoTela < 500 && "100%" }}>
                                {this.state.conteudoPoliticaSelecionado && this.state.conteudoPoliticaSelecionado.length > 0 &&

                                    <div className="paginaPropoentesPerguntasERespostas" style={{ minHeight: "300px" }}>

                                        <div className="paginaPropoentesPerguntasERespostasConteudo" style={{ maxWidth: '900px', marginTop: '50px' }}>
                                            {
                                                this.state.tamanhoTela < 500 &&
                                                <div style={{ width: '90%', display: 'flex', alignItems: 'center', flexDirection: 'column', borderRadius: '6px' }}>
                                                    {

                                                        this.state.politicas && this.state.politicas.length > 0 &&
                                                        this.state.politicas.map((response) => (
                                                            <a style={{ width: '100%' }} className={response.id_politica === this.state.id_politica_selecionada ? "NavButtonactive" : "NavButton"}
                                                                onClick={() => this.setState({ conteudoPoliticaSelecionado: response.conteudos, id_politica_selecionada: response.id_politica })}
                                                            >
                                                                {response.nome_politica}
                                                            </a>
                                                        ))
                                                    }
                                                </div>
                                            }


                                            {
                                                this.state.conteudoPoliticaSelecionado.length > 0 &&
                                                this.state.conteudoPoliticaSelecionado.map((response) => {
                                                    console.log(response)
                                                    if (response.tipo_conteudo === "Titulo") {
                                                        return (
                                                            <>
                                                                <div dangerouslySetInnerHTML={{ __html: response.texto_conteudo }} className="titlePolicy"></div>
                                                            </>
                                                        )
                                                    } else if (response.tipo_conteudo === "Paragrafo") {
                                                        return (
                                                            <>
                                                                <div dangerouslySetInnerHTML={{ __html: response.texto_conteudo }} style={{ maxWidth: '100%', overflowWrap: "break-word" }} className="policyParagraph"></div>
                                                            </>
                                                        )
                                                    } else if (response.tipo_conteudo === "Imagem") {
                                                        return (
                                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '30px', marginBottom: '30px' }}>
                                                                <img style={{ maxWidth: '90%' }} src={response.imagem} />
                                                            </div>
                                                        )
                                                    }
                                                    else if (response.tipo_conteudo === 'Paragrafoimgdireita') {
                                                        return (
                                                            <div className="politicaSecaoComImagem">
                                                                <div dangerouslySetInnerHTML={{ __html: response.texto_conteudo }} style={{ overflowWrap: "break-word" }} className="policyParagraph"></div>
                                                                <br />
                                                                <div>
                                                                    <img className="policyIcon1" src={response.imagem} />
                                                                </div>
                                                            </div>
                                                        )
                                                    } else if (response.tipo_conteudo === 'Paragrafoimgesquerda') {
                                                        return (
                                                            <div className="politicaSecaoComImagem">
                                                                <div>
                                                                    <img className="policyIcon1" src={response.imagem} />
                                                                </div>
                                                                <div dangerouslySetInnerHTML={{ __html: response.texto_conteudo }} style={{ overflowWrap: "break-word" }} className="policyParagraph"></div>
                                                            </div>
                                                        )
                                                    } else if (response.tipo_conteudo === "Subtitulo") {
                                                        return (
                                                            <div dangerouslySetInnerHTML={{ __html: response.texto_conteudo }} className="titlePolicy"></div>
                                                        )
                                                    }

                                                }
                                                )
                                            }

                                        </div>
                                    </div>
                                }

                                <PerguntasERespostasEmpresas entrarEmContato={this.entrarEmContato} />
                            </div>

                    }
                </div>

                <Potencialize  {...this.props} />
                <Footer />


            </>

        )

    }

}

export default PrivacyPolicy;