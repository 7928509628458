import * as React from "react"

export default class ErroNoPagamento extends React.Component {
    axiosSource

    constructor(props){
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        
    }

    voltarProcesso = () => {

        this.props.voltarPagamento()

    }
    
    render() {

            return (

                <>

                    <div className="paginaConfirmacaoPagamentoContainer">

                        <div className="paginaConfirmacaoPagamentoConteudo erro">

                            <div className="paginaConfirmacaoPagamentoHeaderConteudo">

                                <div className="paginaConfirmacaoPagamentoConteudoIconeConfirmacao">

                                    :(

                                </div>

                                <div className="paginaConfirmacaoPagamentoConteudoTituloConfirmacao">

                                    Ocorreu um erro durante a compra!

                                </div>

                                <div className="paginaConfirmacaoPagamentoConteudoSubtituloConfirmacao">

                                    Sua compra não conseguiu ser processada: <span className="produtoSelecionado">{this.props.erro}</span>

                                </div>

                            </div>
                
                            <div className="paginaConfirmacaoPagamentoInfoCompraBotaoContainer">

                                <div className="paginaConfirmacaoPagamentoInfoCompraBotao" onClick={this.voltarProcesso}>

                                    Voltar

                                </div>

                            </div>

                        </div>

                    </div>

                </>

        )
    }
}