import React from 'react'
import iconePlataforma from '../../images/platEmpresaIcone.svg'
import visibilidadeIcon from '../../images/history.svg'
import segurancaIcon from '../../images/safety.svg'
import PcAcompanheIcon from '../../images/pcIconPlataforma.svg'
import PerguntasERespostasEmpresas from '../actioncomponents/PerguntasERespostasEmpresas'
import Contato from '../actioncomponents/Contato'
import LeisIncentifo from '../../gifs/leis_de_incentivo_empresa.gif'
import BuscaFiltros from '../../gifs/radar_de_projetos.gif'
import ResultadosRelevantes from '../../gifs/integracao_de_times.gif'
import AuditoriaImagem from '../../images/auditoria_imagem_empresa.svg'
import DicasPersonalizadas from '../../gifs/pos_investimento.gif'
import Footer from "../Footer"
import Potencialize from "../Potencialize"
import RadioIcon from '../../images/radio_icon.svg'
import NewsIcon from '../../images/news_icon.svg'
import CloudIcon from '../../images/cloud_icon.svg'

    class Empresas extends React.Component {

        constructor() {

            super()

            this.state = {



            }
            
            this.myDivToFocus = React.createRef()

        }

        componentWillMount() {

            window.location.replace('https://simbi.social/');

            window.scrollTo(0, 0)

       }
     
       entrarEmContato = (event) => {
           
           if(this.myDivToFocus.current){

               this.myDivToFocus.current.scrollIntoView({ 
                  behavior: "smooth", 
                  block: "start"
               })
               
           }
       }

        render() {

            return (

                <>

                    <div className="paginaPropoentesHeader">

                        <div className="paginaPropoentesHeaderTexto">

                            <div className="paginaPropoentesHeaderTextoTitulo">

                                Plataforma de gestão de incentivos

                            </div>

                            <div className="paginaPropoentesHeaderTextosubTitulo">

                            </div>

                            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>

                            <div style={{marginRight: '5px'}} className="paginaPropoentesHeaderTextoBotao" onClick={this.entrarEmContato}>

                                Entre em contato

                            </div>

                            <div style={{marginLeft: '5px'}} className="paginaPropoentesHeaderTextoBotao" onClick={this.entrarEmContato}>

                                Agende uma demonstração gratuita

                            </div>

                            </div>

                        </div>

                    </div>
                    
                    <div className="paginaPropoentesOQuee">

                        <div className="paginaPropoentesOQueeContainer">

                            <div className="paginaPropoentesOQueeConteudoEsquerdo">

                                <img alt="imagemiconePlataforma" src={iconePlataforma} />

                            </div>

                            <div className="paginaPropoentesOQueeConteudoDireito">

                                <div className="paginaPropoentesOQueeTitulo">

                                    O que é o sistema de empresas?

                                </div>

                                <div className="paginaPropoentesOQueeDescricao">

                                    A Plataforma de Gestão de Incentivos é uma ferramenta que integra todos os processos do investimento incentivado. Um sistema que acompanha o processo de ponta a ponta, levando visibilidade e segurança nos patrocínios e doações da sua empresa.

                                </div>

                            </div>

                            <div className="paginaPropoentesOQueeConteudoEsquerdoMobile">

                                <img className="paginaPropoentesOQueeConteudoEsquerdoMobileTag" alt="imagemiconePlataforma" src={iconePlataforma} />

                            </div>

                        </div>

                    </div>

                    <div className="paginaPropoentesBeneficios">

                        

                        <div className="paginaPropoentesBeneficiosConteudo">

                            <div className="paginaPropoentesBeneficiosTexto">

                                <div className="paginaPropoentesBeneficiosTitulo">

                                    Benefícios da plataforma

                                </div>

                            </div>

                            <div className="paginaPropoentesBeneficiosCard">

                                <div className="paginaPropoentesBeneficiosCardConteudo">

                                    <div className="paginaPropoentesBeneficiosCardConteudoSuperiorMobile">

                                        <div className="paginaPropoentesBeneficiosCardImagem">

                                            <img alt="imgvisibilidadeIcon" className="paginaPropoentesBeneficiosCardIconTag" src={visibilidadeIcon} />

                                        </div>

                                        <div className="paginaPropoentesBeneficiosCardTitulo">

                                            Tenha visibilidade

                                        </div>

                                    </div>

                                    <div className="paginaPropoentesBeneficiosCardDescricao">

                                        Tenha acesso à nossa base de dados que conta com mais de 200 mil projetos em leis de incentivo, você vai encontrar o que mais se aproxima da sua estratégia de investimento.

                                    </div>

                                </div>

                            </div>

                            <div className="paginaPropoentesBeneficiosCard">

                                <div className="paginaPropoentesBeneficiosCardConteudo">

                                    <div className="paginaPropoentesBeneficiosCardConteudoSuperiorMobile">

                                        <div className="paginaPropoentesBeneficiosCardImagem">

                                            <img alt="imgsegurancaIcon" className="paginaPropoentesBeneficiosCardIconTag" src={segurancaIcon} />

                                        </div>

                                        <div className="paginaPropoentesBeneficiosCardTitulo">

                                            Segurança nos aportes

                                        </div>

                                    </div>

                                    <div className="paginaPropoentesBeneficiosCardDescricao">

                                        Invista em projetos com segurança e acelere os processos internos de aprovação da sua empresa através da auditoria da Simbiose Social que acontece em até 12 dias úteis.

                                    </div>

                                </div>

                            </div>

                            <div className="paginaPropoentesBeneficiosCard">

                                <div className="paginaPropoentesBeneficiosCardConteudo">

                                    <div className="paginaPropoentesBeneficiosCardConteudoSuperiorMobile">

                                        <div className="paginaPropoentesBeneficiosCardImagem">

                                            <img alt="imgPcAcompanheIcon" className="paginaPropoentesBeneficiosCardIconTag" src={PcAcompanheIcon} />

                                        </div>

                                        <div className="paginaPropoentesBeneficiosCardTitulo">

                                            Acompanhe projetos

                                        </div>

                                    </div>

                                    <div className="paginaPropoentesBeneficiosCardDescricao">

                                        Siga a execução dos projetos investidos e mensure o impacto gerado através do investimento da sua empresa.

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="paginaPropoentesLeisDeIncentivo">

                        <div className="paginaPropoentesLeisDeIncentivoConteudo">

                            <div className="paginaPropoentesLeisDeIncentivoTexto">

                                <div className="tituloSecaoPaginaProponente">

                                    Leis de incentivo

                                </div>

                                <div className="barraSeparacao" />

                                <div className="subtituloSecaoPaginaProponente">

                                    Encontre projetos em 25 leis de incentivo diferentes, com informações padronizadas em âmbitos federal, estadual e municipal. Assim, você facilita escala o seu impacto investindo em novas leis e localidades.

                                </div>

                            </div>

                            <div className="paginaPropoentesLeisDeIncentivoImagem">

                                <img alt="leisIncentivoImagem" className="paginaPropoentesLeisDeIncentivoImagemTag" src={LeisIncentifo} />

                            </div>

                        </div>

                    </div>

                    <div className="paginaPropoentesBuscaFiltro">

                        <div className="paginaPropoentesBuscaFiltroConteudo">

                            <div className="paginaPropoentesBuscaFiltroImagem">

                                <img className="paginaPropoentesRadarProjetosTag" alt="BuscaFiltroImagem" src={BuscaFiltros} />

                            </div>

                            <div className="paginaPropoentesBuscaFiltroTexto">

                                <div className="tituloSecaoPaginaProponente">

                                    Radar de projetos

                                </div>

                                <div className="barraSeparacao" />

                                <div className="subtituloSecaoPaginaProponente">

                                    Tenha a visibilidade de mais de 20 mil projetos captando recursos. Utilize os filtros inteligentes para encontrar projetos que estejam alinhados com a estratégia de investimento da sua empresa.

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="paginaPropoentesResultadosRelevantes">

                        <div className="paginaPropoentesResultadosRelevantesConteudo">

                            <div className="paginaPropoentesResultadosRelevantesTexto">

                                <div className="tituloSecaoPaginaProponente">

                                    Integração de equipes

                                </div>

                                <div className="barraSeparacao" />

                                <div className="subtituloSecaoPaginaProponente">

                                    Centralize toda a gestão e equipe envolvida no investimento incentivado em um lugar só. Veja quem da sua equipe selecionou projetos e decida quais serão auditados.

                                </div>

                            </div>

                            <div className="paginaPropoentesResultadosRelevantesImagem">

                                <img alt="ResultadosRelevantesImagem" className="paginaEmpresaIntegracaoEquipeImagemTag" src={ResultadosRelevantes} />

                            </div>

                        </div>

                    </div>

                    <div className="paginaEmpresaAuditoria">

                        <div className="paginaEmpresaAuditoriaConteudo">

                            <div className="paginaPropoentesAuditoriaTexto">

                                <div className="tituloSecaoPaginaProponente">

                                    Auditoria

                                </div>

                                <div className="barraSeparacao" />

                                <div className="subtituloSecaoPaginaEmpresaAuditoria">

                                    Aumente a segurança no investimento de projetos selecionados. Em uma auditoria que acontece em até 12 dias úteis, levantamos todos os documentos e histórico de execuções dos proponentes, mapeando potenciais riscos. A auditoria é também assinada pelo escritório jurídico <a className="linkLanding" href="https://cqs.adv.br/">Cesnik Quintino & Salinas Advogados</a>.

                                </div>

                            </div>

                            <div className="paginaEmpresaAuditoriaImagem">

                                <img className="paginaPropoentesAuditoriaImagemTag" alt="AuditoriaImagem" src={AuditoriaImagem} />

                            </div>

                        </div>

                    </div>

                    <div className="paginaPropoentesPosInvestimento">

                        <div className="paginaPropoentesPosInvestimentoConteudo">

                            <div className="paginaPropoentesPosInvestimentoTexto">

                                <div className="tituloSecaoPaginaProponente">

                                    Pós investimento

                                </div>

                                <div className="barraSeparacao" />

                                <div className="subtituloSecaoPaginaProponente">

                                Acompanhe a execução de um projeto investido e entenda o impacto gerado pelo investimento da sua empresa:

                                </div>

                                <div className="TituloComIconePaginaEmpresaPosInvestimento">

                                    <div className="IconePaginaEmpresaPosInvestimento">

                                        <img alt='IconePaginaEmpresaPosInvestimento' src={RadioIcon} />

                                    </div>

                                    <div className="TituloPaginaEmpresaPosInvestimento">

                                        Notícias

                                    </div>

                                </div>

                                <div className="DescricaoPaginaEmpresaPosInvestimento">

                                    Acompanhe sobre o projeto e proponente em tempo real e receba notificações imediatas quando novas notícias forem publicadas.

                                </div>

                                <div className="TituloComIconePaginaEmpresaPosInvestimento">

                                    <div className="IconePaginaEmpresaPosInvestimento">

                                        <img alt='IconePaginaEmpresaPosInvestimento' src={CloudIcon} />

                                    </div>

                                    <div className="TituloPaginaEmpresaPosInvestimento">

                                        Relatórios

                                    </div>

                                </div>

                                <div className="DescricaoPaginaEmpresaPosInvestimento">

                                    Monte relatórios de impacto e de execução para acompanhar o projeto ao longo do tempo. Crie perguntas personalizadas e receba respostas periodicamente. Todas as informações são consolidadas em um relatório final de execução.

                                </div>

                                <div className="TituloComIconePaginaEmpresaPosInvestimento">

                                    <div className="IconePaginaEmpresaPosInvestimento">

                                        <img alt='IconePaginaEmpresaPosInvestimento' src={NewsIcon} />

                                    </div>

                                    <div className="TituloPaginaEmpresaPosInvestimento">

                                        Documentos

                                    </div>

                                </div>

                                <div className="DescricaoPaginaEmpresaPosInvestimento">

                                    Salve e encontre documentos específicos de cada projeto de forma fácil e organizada.

                                </div>

                            </div>

                            <div className="paginaPropoentesPosInvestimentoImagem">

                                <img alt="PeronalizadasImagem" className="paginaPropoentesResultadosPosInvestimentoTag" src={DicasPersonalizadas} />

                            </div>

                        </div>

                    </div>

                    <div ref={this.myDivToFocus}>

                        <Contato {...this.props} />

                    </div>

                    <PerguntasERespostasEmpresas entrarEmContato={this.entrarEmContato} />

                    <Potencialize {...this.props} />
                    
                    <Footer />

                </>

            )

        }

    }

export default Empresas;