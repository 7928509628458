import React from 'react'

import policyIcon1 from '../../../../images/policyIcon1.svg'
import policyImage2 from '../../../../images/nossa_missao.svg'
import policyImage3 from '../../../../images/vantagens_03.svg'
import policyImage4 from '../../../../images/vantagens_02.svg'
import PolicyImage5 from '../../../../images/cadastrofundomobile.svg'
import Planos from '../../../../images/Planos.png'
class PrivacidadeRadardePatrocinadores extends React.Component {
  render() {
    return (
      <div className="paginaPropoentesPerguntasERespostas">

        <div className="paginaPropoentesPerguntasERespostasConteudo" style={{ maxWidth: '1000px', marginTop: '50px' }}>

          <h1 className="titlePolicy">Termo de Adesão do Radar de Patrocinadores</h1>
          <p className="policyParagraph"> Olá! Este é o <strong> Termo de Adesão do Radar de Patrocinadores da Simbiose Social.</strong> Ele explica para você como nós coletamos, armazenamos e utilizamos os seus dados e informações no nosso dia-a-dia e em nossos produtos tecnológicos.</p>


          <h2 className="titlePolicy">1 - O que será concordado?</h2>
          <p className="policyParagraph">O Contratante ou Usuário, sendo o representante ou proponente de Projetos que vai contratar o Radar de Patrocinadores, concorda em:</p>
          <div className="politicaSecaoComImagem">
            <div>

              <img className="policyIcon1" src={policyImage2} alt="policyImage2" />

            </div>

            <div>

              <p className="policyParagraph">a. Documentos Anexados – O Contratante/Usuário declara que, para esta prestação de serviços, conhece e concorda com os termos dos seguintes documentos, integrantes destes Termos: a) Política de Relacionamento Simbiose Social; b) Termos e Condições de Uso do Banco de dados.</p>

              <p className="policyParagraph">b. OBJETO – A Simbiose disponibilizará a licença temporária de uso e acesso não exclusivo e intransferível ao Radar de
                Patrocinadores, na versão inicialmente ofertada, com o propósito de facilitar a busca de Doadores e Patrocinadores de acordo com o
                perfil dos Projetos do Contratante/Usuário</p>

            </div>

          </div>

          <div className="politicaSecaoComImagem">

            <div>



              <p className="policyParagraph">c.Remuneração – As Remuneração será paga em Parcela Única no ato da contratação, em meio disponível no site da
                Simbiose, de acordo com o plano escolhido:</p>

            </div>
            <br />
            <div>

              <img className="policyIcon1" alt="policyIcon1" src={policyIcon1} />

            </div>

          </div>



          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <img style={{ width: '80%' }} src={Planos} alt="planos" />
          </div>


          <div className="politicaSecaoComImagem" style={{ marginTop: '40px' }}>

            <div>

              <p className="policyParagraph">d. Prazo – Este termo vigerá pelo prazo contratado pelo Contratante/Usuário, contado da adesão ao serviço, e será renovado automaticamente no silêncio das Partes, sendo reajustada a Remuneração pelo IPCA/IBGE ou índice que vier a substituí-lo.</p>

              <p className="policyParagraph">e. Rescisão – Qualquer das Partes poderá resolver este Termo, mediante comunicação escrita dirigida à outra Parte, sendo certo que a Simbiose não restituirá nenhum valor já pago, importando a resolução apenas no cancelamento do login do Contratante/Usuário no Radar de Patrocinadores.</p>

            </div>
            <br />
            <div>

              <img className="policyIcon1" alt="policyIcon1" src={policyImage3} />

            </div>

          </div>


          <p className="policyParagraph" style={{ marginTop: '40px' }}>f. Comunicações – As comunicações serão feitas por e-mail, nos endereços fornecidos, mediante confirmação de recebimento.</p>

          <p className="policyParagraph">g. Declarações – O Contratante/Usuário declara expressamente que conhece as obrigações a ele impostas pela “Política de Relacionamento Simbiose”, incluindo, mas não se limitando às obrigações de confidencialidade, proteção de direitos autorais, não
            solicitação de funcionários, compliance e respeito às Leis e Regulamentos vigentes à época. </p>

          <p className="policyParagraph">Ao clicar no botão "Aceito" o Contratante/Usuário automaticamente aceita este termo de adesão, bem como a "Política de relacionamento da Simbiose" e os "Termos e condições de uso do Radar". </p>
        </div>

      </div>
    )
  }

}

export default PrivacidadeRadardePatrocinadores