import React from 'react'
import IconePergunta from '../../images/iconepergunta.png'

    class PerguntasERespostasEmpresas extends React.Component {

        constructor() {

            super()

            this.state = {
                    perguntas: [
                      {                      
                        "titulo": "Como funciona a busca ativa de projetos?",
                        "resposta": "Nossa plataforma de busca ativa possui todos os projetos aprovados em Leis de Incentivo que estão aptos à captação em diversas legislações (Federais, Estaduais e Municipais) e você pode buscá-los através de filtros inteligentes, como palavra chave, local de atuação, área e segmento do projeto, nome do proponente, entre outros! Nosso algoritmo automaticamente traz os resultados mais relevantes conforme sua busca.",
                      },
                      {                      
                        "titulo": "Consigo ter acesso a todos os projetos aprovados nas legislações vigentes ou eles precisam se cadastrar na plataforma?",
                        "resposta": "Captamos os dados dos projetos aprovados em Leis de Incentivo através de bases públicas, então eles não precisam se cadastrar em nossa plataforma para ficarem disponíveis para consulta, reforçando nossa missão de democratizar o acesso a todos os projetos do país! Assim que um projeto é aprovado em uma legislação e está com prazo de captação vigente, automaticamente nossos 'robozinhos' captam esta informação e disponibilizam os dados públicos do projeto em nossa base.",
                      },
                      {                      
                        "titulo": "Como eu garanto a segurança no aporte em um projeto encontrado via plataforma?",
                        "resposta": "Assim que um patrocinador seleciona projetos de interesse para um possível patrocínio, estes projetos passam por um rigoroso processo de auditoria em três âmbitos: documental, execução e conceitual. Neste processo, verificamos a regularidade fiscal do proponente, seu histórico de execução e levantamos possíveis riscos no aporte ao projeto e tudo isso é reportado ao potencial patrocinador.",
                      },        
                      {                      
                        "titulo": "A plataforma vai me colocar em contato com os proponentes dos projetos?",
                        "resposta": "Sim! Durante a auditoria do projeto, entramos em contato com o proponente para que ele possa responder diversas perguntas que fazem parte do processo. Assim que a auditoria é finalizada e os resultados ficam disponíveis para a empresa que a solicitou, o contato do projeto é disponibilizado e também é aberto um canal de comunicação entre proponente e patrocinador via chat dentro da própria plataforma.",
                      },        
                      {                      
                        "titulo": "Consigo acompanhar a execução dos projetos que patrocinei via plataforma?",
                        "resposta": "Sim! Dentro da nossa plataforma possuímos a funcionalidade de 'pós investimento' onde o patrocinador consegue acompanhar a execução de todos os projetos que apoiou via Lei de Incentivo. Através dessa funcionalidade é possível criar relatórios personalizados para cada projeto patrocinado, definir a periodicidade de resposta, guardar todos os documentos em um único local, além de acompanhar possíveis notícias que saiam na mídia sobre o projeto!",
                      },              
                      {                      
                        "titulo": "Gostaria de entender melhor sobre planos e formas de contratação. Com quem posso falar?",
                        "resposta": "Basta enviar uma proposta no questionário acima e um Simbiótico entrará em contato com sua equipe o mais rápido possível.",
                      },                
                    ]
                      ,
                perguntaAtiva: null,
            }

        }

        expandePergunta = (pergunta) => {

            this.state.perguntaAtiva === pergunta ? 

                this.setState({
                    perguntaAtiva: null
                })

            :

                this.setState({
                    perguntaAtiva: pergunta
                })

        }

        render() {

            return (

                <div>

                    <div className="paginaPropoentesPerguntasERespostas">

                        <div className="paginaPropoentesPerguntasERespostasConteudo">

                            <div className="paginaPropoentesPerguntasERespostasTexto">

                                Perguntas e respostas

                            </div>

                            <div className="paginaPropoentesPerguntasERespostasPerguntasContainer">

                                {

                                    this.state.perguntas.map((pergunta, index) => {

                                        return (

                                            <div key={index}>

                                                 <div className="paginaPropoentesPerguntasERespostasPergunta" onClick={() => this.expandePergunta(index)}>

                                                    <img className="iconePerguntaTag" alt="iconePergunta" src={IconePergunta} style={{
                                                        transform: this.state.perguntaAtiva === index ? 'rotate(45deg)' : ''
                                                    }} />

                                                        {pergunta.titulo}

                                                </div>


                                                {

                                                    this.state.perguntaAtiva === index ? 
                                                    
                                                        <div className="paginaPropoentesPerguntasERespostasResposta">

                                                            {pergunta.resposta}

                                                        </div>

                                                    : '' 

                                                }

                                                <div className="barraDividePerguntas" />

                                            </div>

                                        )

                                    })

                                }

                            </div>

                        </div>

                    </div>

                </div>
                
            )

        }

    }

export default PerguntasERespostasEmpresas;