import * as Pusher from 'pusher-js';

export const pusher = new Pusher('1a0e694e94635cfa0fa0', {
        cluster: 'ap1',
        encrypted: true,
        authEndpoint: 'http://apihomolog.simbiose.tech/api/v1/pusher/auth',
        auth: {
               	headers: {
                        'Authorization': "Bearer "+sessionStorage.getItem('access_token')
                }
        }
});
export default pusher;