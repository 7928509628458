import React from 'react'
import PropTypes from "prop-types";
import { AXIOS } from '../../service/Base'
import { withRouter } from "react-router";

class BoxWebnair extends React.Component {

    constructor() {

        super()

        this.state = {
            show: true,
            informacoesBox: null
        }

    }

    componentDidMount() {

        AXIOS().get('mensagemfooter', {

        })
            .then((response) => {
                this.setState({
                    informacoesBox: response.data.data
                })
                console.log('response get box', response)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    fechaBoxWebnar = () => {

        this.setState({
            show: false
        })

        setTimeout(
            function () {

                this.props.fechaBoxWebnar()

            }
                .bind(this),
            2000
        );

    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    render() {

        return (

            <>

                <div className="boxWebnarPopupDesktop" style={{ opacity: this.state.informacoesBox && this.state.show ? '1' : '0' }}>

                    <div className="boxWebnarContainer">

                        <div className="boxWebnarContent">

                            <div className="boxWebnarContentText">

                                {

                                    this.state.informacoesBox?.st_mensagem

                                }

                            </div>

                            <a className="boxWebnarContentButton" onClick={() => this.fechaBoxWebnar()} href={this.state.informacoesBox ? this.state.informacoesBox.st_link : ''} target="_blank" rel="noopener noreferrer">

                                {this.state.informacoesBox?.st_botao}

                            </a>

                            {/*

                                    <Link className="boxWebnarContentButton" onClick={() => this.fechaBoxWebnar()} to={{pathname: "/cadastro", search: `${this.props.location.search ? `${this.props.location.search}&` : '?'}popUpAgendamento`, stPlano: 'Gratis', planoSelecionadoProponente: this.state.plano, card: 'Gratis', linkPagamento: this.state.linkAcao, cardClicado: 'Gratis'}}>

                                        Agendar

                                    </Link>

                                */}
                            <div className="boxWebnarContentFecha" onClick={() => this.fechaBoxWebnar()}>

                                x

                            </div>

                        </div>



                    </div>

                </div>

                <div className="boxWebnarPopupMobile" style={{ opacity: this.state.informacoesBox && this.state.show ? '1' : '0' }}>

                    <div className="boxWebnarContainerMobile">

                        <div className="boxWebnarContent">

                            <div className="boxWebnarContentText">

                                {

                                    this.state.informacoesBox?.st_mensagem

                                }

                            </div>

                            <a className="boxWebnarContentButton" onClick={() => this.fechaBoxWebnar()} href={this.state.informacoesBox ? this.state.informacoesBox.st_link : ''} target="_blank" rel="noopener noreferrer">

                                {this.state.informacoesBox?.st_botao}

                            </a>

                        </div>

                        <div className="boxWebnarContentFecha" onClick={() => this.fechaBoxWebnar()}>

                            x

                        </div>

                    </div>

                </div>

            </>

        )

    }

}

export default withRouter(BoxWebnair);