import React from 'react'
import TagManager from 'react-gtm-module'


    class CadastroConfirmacao extends React.Component {

        constructor() {

            super()

            this.state = {



            }

        }

        componentDidMount() {

            const tagManagerArgs = {
                dataLayer: {
                    gtmId: 'AW-878455344',
                    userProject: 'project',
                    page: 'Cadastro-Realizado'
                },
                dataLayerName: 'CadastroRealizado'
            }

            TagManager.dataLayer(tagManagerArgs)
        
        }

        render(){

            return (

                <div className="cadastroConfirmacaoContainer">

                    <div className="cadastroConfirmacaoConteudo">

                        <div className="cadastroConfirmacaoTitulo">

                            Perfeito! Só falta mais uma etapa

                        </div>

                        <div className="cadastroConfirmacaoSubtitulo">

                            {

                                this.props.location.tipoTexto.id === 'Gratis' ?

                                    'Confirme seu email para agendar o teste da plataforma.'

                                :

                                    'Confirme seu email para acessar a plataforma'

                            }

                        </div>

                    </div>

                </div>

            )

        }

    }

export default CadastroConfirmacao;