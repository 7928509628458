import React from 'react'
import { Link } from "react-router-dom";

class PlanosExpandiveis extends React.Component {

    constructor() {

        super()

        this.state = {
            empresa: '',
            tipoCliente: 'empresa',
            receberNovidades: false,
            nome: '',
            email: '',
            mensagem: '',
            loaderEnviandoMensagem: false,
            ErroEnviandoMensagem: false,
            mensagemEnviadaComSucesso: false,
            plano: 1,
            linkAcao: 'https://sandbox-app.vindi.com.br/customer/pages/1fea8e20-e2dd-4780-b9d1-137870274421/subscriptions/new'
        }

    }

    componentDidMount() {

    }

    alteraPlano = (plano) => {

        const link = plano === 1 ?
            'https://sandbox-app.vindi.com.br/customer/pages/1fea8e20-e2dd-4780-b9d1-137870274421/subscriptions/new'
            : plano === 2 ?
                'https://sandbox-app.vindi.com.br/customer/pages/1affb88a-1ce1-46bb-a56c-4d5540999e3f/subscriptions/new'
                :
                'https://sandbox-app.vindi.com.br/customer/pages/39577ac5-27d7-44e1-be5b-3648f1a17aa6/subscriptions/new'

        this.setState({
            plano: plano,
            linkAcao: link
        })

    }

    render() {

        return (

            <div className="PopUpContratarContatoEmpresa">

                <div className="PopUpContratarContatoEmpresaTexto">

                    <div className="PopUpContratarContatoTitulo">

                        Sistema radar de patrocinadores

                    </div>

                    <div className="PopUpContratarContatoSubtitulo">

                        Serviço exclusivo para proponentes encontrarem pontenciais patrocinadores alinhados ao projeto com base em filtros escolhidos.

                    </div>

                </div>

                <div className="PopUpContratarContatoEmpresaTipoPlano">

                    <div className="PopUpContratarContatoEmpresaPlano">

                        <div className="PopUpContratarContatoEmpresaString"
                            onClick={() => this.alteraPlano(1)}
                            style={{
                                backgroundColor: this.state.plano === 1 ? '#1F99A7' : '',
                                color: this.state.plano === 1 ? '#FFF' : ''
                            }}>

                            <div className="PopUpContratarContatoEmpresaStringNumero">1</div> USUÁRIO

                        </div>

                        <div className="PopUpContratarContatoEmpresaString"
                            onClick={() => this.alteraPlano(2)}
                            style={{
                                backgroundColor: this.state.plano === 2 ? '#1F99A7' : '',
                                color: this.state.plano === 2 ? '#FFF' : ''
                            }}>

                            <div className="PopUpContratarContatoEmpresaStringNumero">3</div> USUÁRIOS

                        </div>

                        <div className="PopUpContratarContatoEmpresaString"
                            onClick={() => this.alteraPlano(3)}
                            style={{
                                backgroundColor: this.state.plano === 3 ? '#1F99A7' : '',
                                color: this.state.plano === 3 ? '#FFF' : ''
                            }}>

                            <div className="PopUpContratarContatoEmpresaStringNumero">5</div> USUÁRIOS

                        </div>

                    </div>

                    <div className="PopUpContratarContatoEmpresaPlanos">

                        <div>
                            {
                                /*
<div className="PopUpContratarContatoProponentePlano">
                    <div className="PopUpContratarContatoProponentePlanoHeader">

                        <div className="PopUpContratarContatoProponentePlanoHeaderTitulo">

                            APRESENTAÇÃO DA PLATAFORMA

                        </div>

                        <div className="PopUpContratarContatoProponentePlanoBarraHeaderContainer">

                            <div className="PopUpContratarContatoProponentePlanoBarraHeader" />

                        </div>

                    </div>

                    <div className="PopUpContratarContatoProponentePlanoConteudo">

                        <div className="PopUpContratarContatoProponentePlanoConteudoEsquerdo">

                            <div className="PopUpContratarContatoProponentePlanoConteudoTitulo">

                                GRÁTIS

                            </div>

                            <div className="PopUpContratarContatoProponentePlanoConteudoSubtitulo">



                            </div>

                        </div>

                        <div className="PopUpContratarContatoProponentePlanoConteudoDireito">

                            <Link className="PopUpContratarContatoProponentePlanoConteudoBotao" onClick={() => this.props.fechaPopUp()} to={{pathname: "/cadastro", stPlano: 'Gratis', planoSelecionadoProponente: this.state.plano, card: 'Gratis', linkPagamento: this.state.linkAcao, cardClicado: 'Gratis'}}>

                                Realizar cadastro

                            </Link>

                        </div>

                    </div>

                </div>

                                 */
                            }
                        </div>

                        <div className="PopUpContratarContatoProponentePlano" style={{ backgroundColor: '#1F99A7' }}>

                            <div className="PopUpContratarContatoProponentePlanoHeader">

                                <div className="PopUpContratarContatoProponentePlanoHeaderTitulo" style={{ color: '#FFF' }}>

                                    ASSINATURA ANUAL

                                </div>

                                <div className="PopUpContratarContatoProponentePlanoBarraHeaderContainer">

                                    <div className="PopUpContratarContatoProponentePlanoBarraHeader" />

                                </div>

                            </div>

                            <div className="PopUpContratarContatoProponentePlanoConteudo">

                                <div className="PopUpContratarContatoProponentePlanoConteudoEsquerdo" style={{ color: '#fff' }}>

                                    R$

                                    <div className="PopUpContratarContatoProponentePlanoConteudoTitulo" style={{ color: '#FFF' }}>

                                        {

                                            this.state.plano === 1 ? '99,00' :
                                                this.state.plano === 2 ? '79,00' : '69,00'

                                        }

                                    </div>

                                    <div className="PopUpContratarContatoProponentePlanoConteudoSubtitulo" style={{ color: '#FFF' }}>

                                        mensais por usuário

                                    </div>

                                </div>

                                <div className="PopUpContratarContatoProponentePlanoConteudoDireito">

                                    <Link className="PopUpContratarContatoProponentePlanoConteudoBotao" onClick={() => this.props.fechaPopUp()} style={{
                                        backgroundColor: '#FFF',
                                        color: '#71CAD4'
                                    }} to={{
                                        pathname: "/cadastro",
                                        planoSelecionadoProponente: this.state.plano,
                                        stPlano: 'Anual', card: 2,

                                        linkPagamento:

                                            this.state.plano === 1 ?
                                                'https://app.vindi.com.br/customer/pages/204ba81b-412b-4ed9-8b47-f9c460d87dc8/subscriptions/'
                                                :
                                                this.state.plano === 2 ?
                                                    'https://app.vindi.com.br/customer/pages/6ecbe97e-4e53-45b3-966c-968c552e44f7/subscriptions/new'
                                                    :
                                                    this.state.plano === 3 ?
                                                        'https://app.vindi.com.br/customer/pages/390702ae-0258-4e53-9d5f-9549a2f1eb25/subscriptions/new'
                                                        :
                                                        'teste',

                                        cardClicado: 'Anual'
                                    }}>

                                        Realizar cadastro

                                    </Link>

                                </div>

                            </div>

                        </div>

                        <div className="PopUpContratarContatoProponentePlano">

                            <div className="PopUpContratarContatoProponentePlanoHeader">

                                <div className="PopUpContratarContatoProponentePlanoHeaderTitulo">

                                    ASSINATURA SEMESTRAL

                                </div>

                                <div className="PopUpContratarContatoProponentePlanoBarraHeaderContainer">

                                    <div className="PopUpContratarContatoProponentePlanoBarraHeader" />

                                </div>

                            </div>

                            <div className="PopUpContratarContatoProponentePlanoConteudo">

                                <div className="PopUpContratarContatoProponentePlanoConteudoEsquerdo">

                                    R$

                                    <div className="PopUpContratarContatoProponentePlanoConteudoTitulo">

                                        {

                                            this.state.plano === 1 ? '139,00' :
                                                this.state.plano === 2 ? '109,00' : '99,00'

                                        }

                                    </div>

                                    <div className="PopUpContratarContatoProponentePlanoConteudoSubtitulo">

                                        mensais por usuário

                                    </div>

                                </div>

                                <div className="PopUpContratarContatoProponentePlanoConteudoDireito">

                                    <Link className="PopUpContratarContatoProponentePlanoConteudoBotao" onClick={() => this.props.fechaPopUp()}
                                        to={{
                                            pathname: "/cadastro",
                                            stPlano: 'Semestral',
                                            planoSelecionadoProponente: this.state.plano,
                                            card: 3,

                                            linkPagamento:

                                                this.state.plano === 1 ?
                                                    'https://app.vindi.com.br/customer/pages/d1e18907-8011-4cf4-9773-2b551a6e5221/subscriptions/new'
                                                    :
                                                    this.state.plano === 2 ?
                                                        'https://app.vindi.com.br/customer/pages/c1400904-57fb-4580-8e9a-bb9c86131640/subscriptions/new'
                                                        :
                                                        this.state.plano === 3 ?
                                                            'https://app.vindi.com.br/customer/pages/8dd43ce7-06b2-44f4-b9f3-221d6427cdcf/subscriptions/new'
                                                            :
                                                            'teste',

                                            cardClicado: 'Semestral'
                                        }}>

                                        Realizar cadastro

                                    </Link>

                                </div>

                            </div>

                        </div>

                        <div className="PopUpContratarContatoProponentePlano">

                            <div className="PopUpContratarContatoProponentePlanoHeader">

                                <div className="PopUpContratarContatoProponentePlanoHeaderTitulo">

                                    ASSINATURA MENSAL

                                </div>

                                <div className="PopUpContratarContatoProponentePlanoBarraHeaderContainer">

                                    <div className="PopUpContratarContatoProponentePlanoBarraHeader" />

                                </div>

                            </div>

                            <div className="PopUpContratarContatoProponentePlanoConteudo">

                                <div className="PopUpContratarContatoProponentePlanoConteudoEsquerdo">

                                    R$

                                    <div className="PopUpContratarContatoProponentePlanoConteudoTitulo">

                                        {

                                            this.state.plano === 1 ? '199,00' :
                                                this.state.plano === 2 ? '159,00' : '139,00'

                                        }

                                    </div>

                                    <div className="PopUpContratarContatoProponentePlanoConteudoSubtitulo">

                                        mensais por usuário

                                    </div>

                                </div>

                                <div className="PopUpContratarContatoProponentePlanoConteudoDireito">

                                    <Link className="PopUpContratarContatoProponentePlanoConteudoBotao" onClick={() => this.props.fechaPopUp()}
                                        to={{
                                            pathname: "/cadastro",
                                            stPlano: 'Mensal',
                                            planoSelecionadoProponente: this.state.plano,
                                            card: 4,

                                            linkPagamento:

                                                this.state.plano === 1 ?
                                                    'https://app.vindi.com.br/customer/pages/97b6626e-ae8c-4f4d-9390-d013ee933865/subscriptions/new'
                                                    :
                                                    this.state.plano === 2 ?
                                                        'https://app.vindi.com.br/customer/pages/93931246-2b19-4372-9831-0bab3cb9049b/subscriptions/new'
                                                        :
                                                        this.state.plano === 3 ?
                                                            'https://app.vindi.com.br/customer/pages/9632eaa4-62a0-42cf-b43e-77bf0281c142/subscriptions/new'
                                                            :
                                                            'teste',

                                            cardClicado: 'Mensal'
                                        }}>

                                        Realizar cadastro

                                    </Link>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        )

    }

}

export default PlanosExpandiveis;