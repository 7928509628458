import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import * as serialize from 'form-serialize';
import Footer from '../Footer'
import InputMask from 'react-input-mask'
import { AXIOS_NEW } from '../../service/Base'
import Axios from 'axios';
import { PopupButton } from '@typeform/embed-react'
import { Widget } from '@typeform/embed-react'


class ContatoLandingTS extends React.Component {
  constructor() {
    super()
    this.state = {
      titulo: "",
      subtitulo: "",
      id_typeform: "",
      verificadocaptcha: '',
      perguntas: [],
      arquivoapresentação: '',
      respostas: [],
      nomeEmpresa: '',
      respostasfiles: [],
      email: '',
      nomeproponente: '',
      nome_ong: '',
      tooltips: [],
      form: true,
      sectionagradecimento: false,
      loading: true,
      logoempresa: "",
      nome_projeto: "",
      cpf_cnpj: "",
      nome_lei: "",
      campo_outralei: false,
      aprovadoalgumalei: false,
      leis: [],
      evento: ""

    }
    this.dadosform = React.createRef();
  }


  componentDidMount() {

    const id_cliente = this.props.match.params.id_cliente
    const id_formulario = this.props.match.params.id_formulario

    AXIOS_NEW().get(`pagina/${id_formulario}`).then((response) => {
      console.log(response)
      if (!response.data.success) {
        window.location.href = "https://simbiose.social/";
      } else {
        this.setState({
          //perguntas: response.data.perguntas,
          //nomeEmpresa: response.data.perguntas["0"] && response.data.perguntas["0"].nome_cliente,
          //form: true,
          logoempresa: response.data.data.st_logo_pagina,
          titulo: response.data.data.st_titulo_formulario,
          subtitulo: response.data.data.st_subtitulo_formulario,
          id_typeform: response.data.data.id_typeform,
          loading: false
          //leis: response.data.leis,
          //evento: response.data.evento
        })
      }
    }).catch(() => {
      //window.location.href = "https://simbiose.social/";
    })

  }

  render() {
    return (
      <div className="paginaContatoLandingContainer" >

        {/*Logo empresas Participantes */
          < div className="empresastopbar" >
            <div style={{ marginRight: '10px', fontSize: '16px' }}>Apoio:</div>
            {
              this.state.logoempresa !== "" &&
              <img className="logoEmpresa" alt="logoempresa" src={`${this.state.logoempresa}`} />
            }
          </div >
        }
        {
          !this.state.sectionagradecimento ?
            this.state.form &&
            <div>
              <div className="paginaContatoLandingSection" style={{ marginTop: '40px', minHeight: '190px' }}>
                <strong className="h1title" style={{ fontSize: '30px' }}>
                  {this.state.titulo}
                </strong>

                <h1 className="h1title" style={{ fontSize: '20px', marginTop: '30px' }}>
                  {this.state.subtitulo}
                </h1>
              </div>
              <div  className="paginaContatoLandingSection" style={{ minHeight: '150px', backgroundColor: '#fff' }} >
                { 
                this.state.loading ? 
                    ''
                  :
                    this.props.match.params.id_formulario == 1 ?
                    /*<Widget id={this.state.id_typeform} style={{ width: '100%', height: '36vw' }} className="my-form" />*/
                    <>
                          <PopupButton id={this.state.id_typeform} style={{ fontSize: 20 }} className="botaofaçaparte">
                            Projeto Incentivado
                          </PopupButton>
                          <PopupButton id={'fTw5E9II'} style={{ fontSize: 20 }} className="botaofaçaparte">
                            Verba Direta
                          </PopupButton>
                    </>

                  :
                    <PopupButton id={this.state.id_typeform} style={{ fontSize: 20 }} className="botaofaçaparte">
                      Clique aqui
                    </PopupButton>
                }

              </div>

             

              {/*O que é a Simbiose Social?*/}
              <div className="paginaContatoLandingSection" style={{minHeight: '250px' }}>
                <strong className="h1title" style={{ fontSize: '36px', marginBottom: '20px' }}>
                  O que é a Simbiose Social?
                </strong>

                <span className="textosection">
                  A Simbiose é uma plataforma que une patrocinadores e proponentes semelhantes através da inteligência de dados. Fundada em 2016, a partir da missão de democratizar o acesso a recursos, somos uma startup de tecnologia voltada para o impacto social. Especialistas em leis de incentivo, conectamos empresas, proponentes e governo para potencializar a transformação. Apostamos e acreditamos na democratização e transparência das informações e dados públicos como catalisadores de mudança social.
                </span>
              </div>

              {/*O que acontece quando envio meu projeto? */}
              <div className="paginaContatoLandingSection passoapasso" style={{ marginTop: '30px', minHeight: '300px', backgroundColor: '#fff', flexDirection: 'column' }}>

                <strong className="h1title" style={{ fontSize: '36px', marginBottom: '20px', color: '#1f99a7' }}>
                  O que acontece quando envio meu projeto?
                </strong>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginRight: '30px' }}>
                    <img className="imgpassosenvio" src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1613761774/$5dhh7i9r36w" alt="1" />
                    <span className="textopassosenvio">
                      Recebemos todos os dados enviados pelo formulário e o seu projeto entrará em fase de análise.
                    </span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginRight: '30px' }}>
                    <img className="imgpassosenvio" src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1613761774/$o5vg0ha23l" alt="2" />
                    <span className="textopassosenvio">
                      Caso os documentos estejam regulares, ele será aprovado. Você receberá um e-mail com o resultado da análise!
                    </span>
                  </div>


                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginRight: '30px' }}>
                    <img className="imgpassosenvio" src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1613761774/$6cqu4o4pntd" alt="3" />
                    <span className="textopassosenvio">
                      Uma vez aprovado, o projeto terá destaque na plataforma. O patrocinador poderá encontrá-lo e saberá que entrou em contato.
                    </span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginRight: '30px' }}>
                    <img className="imgpassosenvio" src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1613761774/$162h0du505d" alt="4" />
                    <span className="textopassosenvio">
                      Caso o patrocinador tenha interesse no projeto, ele indicará através da plataforma e entraremos em contato. 😉
                    </span>
                  </div>

                </div>
              </div>

              {/*A Simbiose Social é uma agência de captação de recursos? */}
              <div className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '350px', backgroundColor: '#f0f5f8' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                  <img style={{ width: '110px' }} src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1610315758/$pzjecxgpk7" alt="faqimg" />

                  <strong style={{ fontSize: '24px', marginTop: '40px' }}>
                    A Simbiose Social é uma agência de captação de recursos?
                  </strong>
                  <span className="textosection" style={{ color: '#333', textAlign: 'justify', padding: 0, marginTop: '30px', fontSize: '14px', marginTop: '20px' }}>
                    Atuamos em um formato diferente de agências tradicionais de captação de recursos. Dentro da nossa plataforma de <strong>Radar de Projetos</strong>, os patrocinadores buscam ativamente os projetos e têm uma visibilidade de todos os projetos que estão aptos a captar recursos no mercado, realmente podendo encontrar o que mais se encaixa ao perfil e pilares de atuação da empresa. A Simbiose atua com a tecnologia e inteligência de dados por trás desta busca toda que ajuda a otimizar os processos do mercado.
                  </span>
                </div>
              </div>

              {/*Se eu enviar meu projeto para vocês, vocês me ajudam a encontrar um patrocinador para ele já que possuem contato com diversas empresas? */}
              <div className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '300px', backgroundColor: '#f0f5f8' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                  <strong style={{ fontSize: '24px' }}>
                    Se eu enviar meu projeto para vocês, vocês me ajudam a encontrar um <br />patrocinador para ele já que possuem contato com diversas empresas?
                  </strong>

                  <span className="textosection" style={{ color: '#333', textAlign: 'justify', padding: 0, fontSize: '14px', marginTop: '20px' }}>

                    Se o seu projeto está aprovado em <strong>Lei de Incentivo</strong> e apto a captar recursos, ele já está disponível em nossa plataforma sendo visto por todos os nossos clientes. Para te auxiliar na busca por um patrocinador, temos o Radar de Patrocinadores, plataforma em que os proponentes buscam ativamente por patrocinadores que possuem um perfil de investimento que mais se assemelha ao projeto que estão buscando captação.
                  </span>
                </div>
              </div>

              {/* Posso colocar a apresentação e vídeos do meu projeto na plataforma de */}
              <div className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '300px', backgroundColor: '#f0f5f8' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                  <strong style={{ fontSize: '24px' }}>
                    Posso colocar a apresentação e vídeos do meu projeto na plataforma de <br />vocês para os clientes verem?
                  </strong>

                  <span className="textosection" style={{ color: '#333', textAlign: 'justify', padding: 0, fontSize: '14px', marginTop: '20px' }}>
                    Como prezamos pela democratização de acesso e queremos garantir que todos os projetos do país sejam vistos da mesma maneira em nossa plataforma, em um primeiro momento nossos clientes têm acesso a todas as informações públicas dos projetos (aquelas que foram inscritas nos ministérios, secretarias, etc). Caso algum deles se interesse pelo seu projeto, entraremos em contato e você poderá contar com suas próprias palavras sobre ele, o impacto que ele busca, enviar fotos, vídeos, apresentações, etc!
                  </span>
                </div>
              </div>

              {/*Crie conexões duradouras. Amplie o seu */}
              <div className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '300px', backgroundColor: '#fff' }}>
                <span style={{ fontSize: '32px', color: '#606a73', textAlign: 'center' }}>
                  Crie conexões duradouras. Amplie o seu<br /> impacto social.
                </span>
                <strong style={{ fontSize: '32px', color: '#606a73', textAlign: 'center' }}>
                  Inspire a transformação.
                </strong>

                <a href="https://simbiose.social/" className="botaofaçaparte">Faça Parte</a>
              </div>

            </div>
            :
            <div>
              <div className="paginaContatoLandingSection" style={{ marginTop: '20px', minHeight: '650px', backgroundColor: '#1f99a7' }}>
                <strong className="h1title" style={{ fontSize: '41px', marginBottom: '20px', backgroundColor: '#FFF', color: '#1f99a7' }}>
                  Agradecemos seu cadastro!
                </strong>

                <span className="textosection" style={{ fontSize: '20px' }}>
                  Fique ligado em seu e-mail, continuaremos nossa conversa por lá. 😉
                </span>
              </div>
              <div style={{ marginTop: '35px' }} className="paginaContatoLandingSection propagandaarea">
                <p style={{ color: "rgb(155, 155, 155)", fontFamily: 'Open Sans', fontSize: 25, fontWeight: 'bold' }} >Achamos que você pode se interessar</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '100px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                    <img src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1614622068/$iib8vazjjd" alt="Panorama Leis de Incentivo para 2021" />
                    <a href="https://conteudo.simbiose.social/panorama-leis-de-incentivo-2021" style={{ textDecoration: 'none', color: '#000', cursor: 'pointer', backgroundColor: '#ffe338', borderRadius: '35px', height: '44px', lineHeight: '1.42857', fontSize: '19px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '200px' }}>
                      Baixe agora!
                    </a>
                  </div>

                  <p style={{ color: "rgb(3, 181, 192)", fontFamily: 'Open Sans', maxWidth: '480px', fontSize: '48px' }}>
                    Panorama
                    Leis de Incentivo 2021
                  </p>
                </div>
              </div>
            </div>
        }
      </div >
    )
  }
}

export default ContatoLandingTS