import * as React from "react"
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
        
import compraRealizada from "../../../images/compraRealizadaSucesso.svg"
import BaixarBoleto from "../../../images/baixarBoleto.svg"
import ImprimirBoleto from "../../../images/copiarBoleto.svg"
import CopiarBoleto from "../../../images/imprimirBoleto.svg"
import Banco1 from "../../../images/banco1.svg"
import Banco2 from "../../../images/banco2.svg"
import Banco3 from "../../../images/banco3.svg"
import Banco4 from "../../../images/banco4.svg"
import Banco5 from "../../../images/banco5.svg"

//import * as ReactGA from 'react-ga';
//ReactGA.initialize('UA-178033988-1');
//import TagManager from 'react-gtm-module'

var Barcode = require('react-barcode');

class Confirmacao extends React.Component {
    axiosSource

    constructor(props){
        super(props);

        this.state = {
            stCopiarCodigo: 'Copiar código'
        }
    }

    componentWillMount() {

        //ReactGA.pageview('?pagamentoRealizado');
        window.location.replace('https://simbi.social/')
        
    }

    componentDidMount() {

        setTimeout(() => {

            /*

            const tagManagerArgs = {
    
                gtmId: 'AW-878455344'
                
            }
    
            TagManager.initialize(tagManagerArgs)

            const tagManagerArgsProperties = {
                dataLayer: {
                    gtmId: 'AW-878455344',
                    userProject: 'project',
                    page: 'Pagamento-Realizado'
                },
                dataLayerName: 'PagamentoRealizado'
            }
    
            TagManager.dataLayer(tagManagerArgsProperties)

            */
 
            /*

            const tagManagerArgsInit = {
                gtmId: 'AW-878455344'
            }
             
            TagManager.initialize(tagManagerArgsInit)

            const tagManagerArgs = {
                dataLayer: {
                    gtmId: 'AW-878455344',
                    userProject: 'project',
                    page: 'Pagamento-Realizado'
                },
                dataLayerName: 'PagamentoRealizado'
            }
    
            TagManager.dataLayer(tagManagerArgs)

            */
        
    }, 500);
    
    }

    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    }

    recarregaPagina = () => {

        /*window.location.reload();*/

        window.location.replace('https://simbiose.tech/#/login') 

    }

    visualizarBoleto = () => {

        window.location.replace(this.props.BoletoLink) 

    }

    copiarCodigo = () => {

        //navigator.clipboard.writeText(this.props.linhaDigitavel)
        var textField = document.createElement('textarea')
        textField.innerText = this.props.linhaDigitavel
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        this.setState({
            stCopiarCodigo: 'Código copiado!'
        })

        setTimeout(() => {

            this.setState({
                stCopiarCodigo: 'Copiar código'
            })

		   }, 5000)

    }
    
    render() {

            return (

                <>

                            {

                                this.props.tipoCompra === 'cartao' ?

                                    <>

                                    <div className="paginaConfirmacaoPagamentoContainer">
                
                                        <div className="paginaConfirmacaoPagamentoConteudo">

                                            <div className="paginaConfirmacaoPagamentoHeaderConteudo">
                
                                                <div className="paginaConfirmacaoPagamentoConteudoIconeConfirmacao">
                
                                                    <img alt="compraRealizadaIcon" src={compraRealizada} />
                
                                                </div>
                
                                                <div className="paginaConfirmacaoPagamentoConteudoTituloConfirmacao">
                
                                                    Compra efetuada com sucesso!
                
                                                </div>
                
                                                <div className="paginaConfirmacaoPagamentoConteudoSubtituloConfirmacao">
                
                                                    Sua compra de <span className="produtoSelecionado">Radar de Patrocinadores - plano {this.props.periodo.toLowerCase()}</span> foi processada com sucesso. Verifique em seu email ou na caixa de spam para acessar.
                
                                                </div>
                
                                            </div>
                
                                            <div className="paginaConfirmacaoPagamentoInfoCompraConteudo">
                
                                                <div className="paginaConfirmacaoPagamentoInfoCompraHeader">
                
                                                    DETALHES DA COMPRA
                
                                                </div>
                
                                                <div className="paginaConfirmacaoPagamentoInfoCompraBarraHeader" />
                
                                                <div className="paginaConfirmacaoPagamentoInfoCompraItemConteudo">
                
                                                    <div className="paginaConfirmacaoPagamentoInfoCompraItemInfo">
                
                                                        <div className="paginaConfirmacaoPagamentoInfoCompraTituloItem">
                
                                                            Nome do produto:
                
                                                        </div>
                
                                                        <div className="paginaConfirmacaoPagamentoInfoCompraItem">
                
                                                            Radar de patrocinadores - plano {this.props.periodo.toLowerCase()} | {this.props.qtdUsuarios} usuários
                
                                                        </div>
                
                                                    </div>
                
                                                    <div className="paginaConfirmacaoPagamentoInfoCompraItemInfo">
                
                                                        <div className="paginaConfirmacaoPagamentoInfoCompraTituloItem">
                
                                                            Valor:
                
                                                        </div>
                
                                                        <div className="paginaConfirmacaoPagamentoInfoCompraItem">
                
                                                            {this.props.valor.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})} {this.props.periodo === 'Anual' ? 'mensal' : this.props.periodo === 'Semestral' ? 'semestral' : 'anual'}
                
                                                        </div>
                
                                                    </div>
                
                                                    <div className="paginaConfirmacaoPagamentoInfoCompraItemInfo">
                
                                                        <div className="paginaConfirmacaoPagamentoInfoCompraTituloItem">
                
                                                            código de transação
                
                                                        </div>
                
                                                        <div className="paginaConfirmacaoPagamentoInfoCompraItem">
                
                                                            {this.props.codigoTransacao}
                
                                                        </div>
                
                                                    </div>
                
                                                </div>
                
                                            </div>
                
                                            <div className="paginaConfirmacaoPagamentoInfoCompraBotaoContainer">
                
                                                <div className="paginaConfirmacaoPagamentoInfoCompraBotao" onClick={this.recarregaPagina}>
                
                                                    Acessar plataforma
                
                                                </div>
                
                                            </div>
                
                                            <div className="paginaConfirmacaoPagamentoInfoCompraMensagemContainer">
                
                                                <div className="paginaConfirmacaoPagamentoInfoCompraMensagem">
                
                                                    Para dúvidas relacio nadas ao conteúdo do produto, entre em contato com a Produtora através do email <span className="emailMensagem">radar@simbiose.social</span>.
                
                                                </div>
                
                                            </div>

                                        </div>

                                    </div>

                                    </>

                                :

                                    <>

                                    <div className="paginaConfirmacaoPagamentoContainer">
                
                                        <div className="paginaConfirmacaoPagamentoConteudo boleto">

                                            <div className="paginaConfirmacaoPagamentoHeaderConteudo boleto">
                
                                                <div className="paginaConfirmacaoPagamentoConteudoIconeConfirmacao">
                
                                                    <img alt="compraRealizadaIcon" src={compraRealizada} />
                
                                                </div>
                
                                                <div className="paginaConfirmacaoPagamentoConteudoTituloConfirmacao">
                
                                                    Falta apenas um passo para garantir seu acesso
                
                                                </div>
                
                                                <div className="paginaConfirmacaoPagamentoConteudoSubtituloConfirmacao">
                
                                                    Sua compra de <span className="produtoSelecionado">Radar de Patrocinadores - plano {this.props.periodo.toLowerCase()}</span> está sendo processada. Após efetauar o pagamento, o acesso será liberado em até <span className="diasUteisPagamento">3 dias úteis</span>.
                
                                                </div>
                
                                            </div>

                                            <div className="paginaConfirmacaoPagamentoBoletoConteudo">

                                                <div className="paginaConfirmacaoPagamentoBoletoConteudoEsquerdo">

                                                    <div className="paginaConfirmacaoPagamentoBoletoConteudoEsquerdoContainer">
                
                                                        <div className="paginaConfirmacaoPagamentoInfoCompraHeader">
                        
                                                            DETALHES DA COMPRA
                        
                                                        </div>
                        
                                                        <div className="paginaConfirmacaoPagamentoInfoCompraBarraHeader" />
                        
                                                        <div className="paginaConfirmacaoPagamentoInfoCompraItemConteudo">
                        
                                                            <div className="paginaConfirmacaoPagamentoInfoCompraItemInfo">
                        
                                                                <div className="paginaConfirmacaoPagamentoInfoCompraTituloItem">
                        
                                                                    Nome do produto:
                        
                                                                </div>
                        
                                                                <div className="paginaConfirmacaoPagamentoInfoCompraItem">
                        
                                                                    Radar de patrocinadores - plano {this.props.periodo.toLowerCase()} | {this.props.qtdUsuarios} usuários
                        
                                                                </div>
                        
                                                            </div>
                        
                                                            <div className="paginaConfirmacaoPagamentoInfoCompraItemInfo">
                        
                                                                <div className="paginaConfirmacaoPagamentoInfoCompraTituloItem">
                        
                                                                    Valor:
                        
                                                                </div>
                        
                                                                <div className="paginaConfirmacaoPagamentoInfoCompraItem">
                        
                                                                    R${this.props.valor} {this.props.periodo === 'Anual' ? 'mensal' : this.props.periodo === 'Semestral' ? 'semestral' : 'anual'}
                        
                                                                </div>
                        
                                                            </div>
                        
                                                            <div className="paginaConfirmacaoPagamentoInfoCompraItemInfo">
                        
                                                                <div className="paginaConfirmacaoPagamentoInfoCompraTituloItem">
                        
                                                                    código de transação
                        
                                                                </div>
                        
                                                                <div className="paginaConfirmacaoPagamentoInfoCompraItem">
                        
                                                                    {this.props.codigoTransacao}
                        
                                                                </div>
                        
                                                            </div>
                        
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="paginaConfirmacaoPagamentoBoletoConteudoDireito">

                                                    <div className="paginaConfirmacaoPagamentoBoletoConteudoDireitoConteudo">

                                                        <div className="paginaConfirmacaoPagamentoBoletoBotaoBaixar baixar" style={{border: '0',background: '#71CAD4', color: '#FFFFFF'}} onClick={() => this.visualizarBoleto()}>

                                                            <div className="boletoBotaoIcone">

                                                                <img alt="botaoBoletoAcao" src={BaixarBoleto} />

                                                            </div>

                                                            <div className="boletoBotaoIcone">

                                                                Baixar o boleto

                                                            </div>

                                                        </div>

                                                        <div className="paginaConfirmacaoPagamentoBoletoBotaoBaixar" onClick={() => this.visualizarBoleto()}>

                                                            <div className="boletoBotaoIcone">

                                                                <img alt="botaoBoletoAcao" src={ImprimirBoleto} />

                                                            </div>

                                                            <div className="boletoBotaoIcone">

                                                                Imprimir boleto

                                                            </div>

                                                        </div>

                                                        {

                                                            document.queryCommandSupported('copy') && // Só exibe o botão se o browser permitir a ação

                                                                <div className="paginaConfirmacaoPagamentoBoletoBotaoBaixar" onClick={() => this.copiarCodigo()}>
        
                                                                    <div className="boletoBotaoIcone">
        
                                                                        <img alt="botaoBoletoAcao" src={CopiarBoleto} />
        
                                                                    </div>
        
                                                                    <div className="boletoBotaoIcone">
        
                                                                        {this.state.stCopiarCodigo}
        
                                                                    </div>
        
                                                                </div>

                                                        }

                                                        <div className="paginaConfirmacaoPagamentoBoletoBotaoMensagemContainer">

                                                            <div className="paginaConfirmacaoPagamentoBoletoBotaoMensagem">

                                                                Selecione um banco para efetuar o pagamento:

                                                            </div>

                                                        </div>

                                                        <div className="paginaConfirmacaoPagamentoBoletoCartoes">

                                                            <img alt="bancoInfo" className="paginaConfirmacaoPagamentoBoletoBanco" src={Banco1} />

                                                            <img alt="bancoInfo" className="paginaConfirmacaoPagamentoBoletoBanco" src={Banco2} />

                                                            <img alt="bancoInfo" className="paginaConfirmacaoPagamentoBoletoBanco" src={Banco3} />

                                                            <img alt="bancoInfo" className="paginaConfirmacaoPagamentoBoletoBanco" src={Banco4} />

                                                            <img alt="bancoInfo" className="paginaConfirmacaoPagamentoBoletoBanco" src={Banco5} />

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                            <div className="paginaConfirmacaoPagamentoBoletoInfo">

                                                <div className="paginaConfirmacaoPagamentoBoletoInfoConteudo">

                                                    <div className="paginaConfirmacaoPagamentoBoletoInfoConteudoLinhaContainer">

                                                        <div className="paginaConfirmacaoPagamentoBoletoInfoConteudoLinha">

                                                            <span className="linhaDigitavelTitulo">Linha digitável:</span> <span className="linhaDigitavelConteudo">{this.props.linhaDigitavel}</span>

                                                        </div>

                                                        {/*<img alt="linhaDigitavel" src={BarraBoleto} />*/}

                                                        <Barcode 
                                                            value={this.props.linhaDigitavel} 
                                                            displayValue={false}    
                                                            height={50}
                                                            width={1}
                                                            background={'#FAFAFA'}
                                                        />

                                                    </div>

                                                </div>

                                            </div>

                                            <div className="paginaConfirmacaoPagamentoBoletoMensagemContainer">

                                                <div className="paginaConfirmacaoPagamentoBoletoMensagem">

                                                    Para dúvidas relacionadas ao conteúdo do produto, entre em contato com a Produtora através do email <span className="emailBoleto">radar@simbiose.social</span>. Tenha em mente que o boleto pode demorar até 6 horas para liberar o pagamento.

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    
                                    </>

                            }

                </>

        )
    }
}

export default withRouter(Confirmacao);