import axios from 'axios';

//export const URL_API = "http://localhost:8000/api/v1/";
export const URL_API = "https://api.simbiose.social/api/v1";
export const URL_API_NEW = "https://auditoria.simbi.social/api/v2";

export const CLIENT_ID = 15
export const CLIENT_SECRET = 'nqhjeKAEnczcU7SvrZNSjScRN45ejiRvrjiMs3NH'

export function AXIOS(){
        return axios.create({
                baseURL: URL_API,
                headers: {
                        "Authorization" : "Bearer " + localStorage.getItem('@oauth:token'),
                        "Accept" : 'application/json',
                        "Content-Type" : 'application/json'
                }
        });
}

export function AXIOS_NEW(){
    return axios.create({
            baseURL: URL_API_NEW,
            headers: {
                    "Accept" : 'application/json',
                    "Content-Type" : 'application/json'
            }
    });
}

export const URL_VINDI_API = 'https://app.vindi.com.br/api/v1/'

export const CHAVE_PUBLICA_ACESSO = '6wCf2Bw-GwhLxf0KHZcCutc0vJOPIIU8zTyhrj1SQbk'

export const valores =  {

        'usuarios':
            [
                {
                    'qtdUser': 1,

                    'mensal':

                        {

                            'plan_id': 161571,
                            'preco': '199,00',
                            'descricao': '1x de R$199,00',
                            'descricao_a_vista': '1x de R$199,00',
                            'parcelado': '199,00',
                            'a_vista': '199,00',

                        },

                    'semestral':

                        {

                            'plan_id': 156640,
                            'preco' : '139,00',
                            'descricao': '6x de R$139,00',
                            'descricao_a_vista': '1x de R$834,00',
                            'parcelado': '139,00',
                            'a_vista': '834,00',

                        },

                    'anual':

                        {
                            'plan_id': 161585,
                            'preco': '99,00',
                            'descricao': '12x de R$99,00',
                            'descricao_a_vista': '1x de R$1.188,00',
                            'parcelado': '99,00',
                            'a_vista': '1.188,00',

                        },

                },

                {
                    'qtdUser': 3,

                    'mensal':

                        {
                            'plan_id': 161587,
                            'preco': '159,00',
                            'descricao': '1x de R$477,00',
                            'descricao_a_vista': '1x de R$477,00',
                            'parcelado': '477,00',
                            'a_vista': '477,00',

                        },

                    'semestral':

                        {
                            'plan_id': 161590,
                            'preco' : '109,00',
                            'descricao': '6x de R$327,00',
                            'descricao_a_vista': '1x de R$1.962,00',
                            'parcelado': '327,00',
                            'a_vista': '1.962,00',

                        },
                     'anual':

                        {

                            'plan_id': 157652,
                            'preco': '79,00',
                            'descricao': '12x de R$237,00',
                            'descricao_a_vista': '1x de R$2.844,00',
                            'parcelado': '237,00',
                            'a_vista': '2.844,00',

                        },
                },
                {
                    'qtdUser': 5,

                    'mensal':

                        {
                            'plan_id': 161588,
                            'preco': '139,00',
                            'descricao': '1x de R$695,00',
                            'descricao_a_vista': '1x de R$695,00',
                            'parcelado': '695,00',
                            'a_vista': '695,00',

                        },

                    'semestral':

                        {
                            'plan_id': 161591,
                            'preco' : '99,00',
                            'descricao': '6x de R$495,00',
                            'descricao_a_vista': '1x de R$2.970,00',
                            'parcelado': '495,00',
                            'a_vista': '2.970,00',

                        },

                    'anual':

                        {
                            'plan_id': 163695,
                            'preco': '69,00',
                            'descricao': '12x de R$345,00',
                            'descricao_a_vista': '1x de R$2.844,00',
                            'parcelado': '345,00',
                            'a_vista': '4.140,00',

                        },
                }
            ]
    }

export function getAxiosSource(){
        let axiosSource = axios.CancelToken.source()
        //axiosSource.token.throwIfRequested = axiosSource.token.throwIfRequested;
        axiosSource.token.promise.then = axiosSource.token.promise.then.bind(axiosSource.token.promise);
        axiosSource.token.promise.catch = axiosSource.token.promise.catch.bind(axiosSource.token.promise);
        return axiosSource;
}

export default axios;
