import React from 'react'
import RadioDesmarcado from '../../images/radioDesmarcado.svg'
import RadioMarcado from '../../images/radioMarcado.svg'
import Checkbox from '../../images/checkbox.svg'
import CheckboxChecked from '../../images/checkboxChecked.svg'
import PlanosExpandiveis from '../actioncomponents/PlanosExpandiveis'
import {AXIOS} from '../../service/Base'

 class PopUpContratar extends React.Component {

    constructor() {

        super()
        
        this.state = {
            empresa: '',
            tipoCliente: 'empresa',
            receberNovidades: false,
            nome: '',
            email: '',
            emailValidador: false,
            mensagem: '',
            loaderEnviandoMensagem: false,
            ErroEnviandoMensagem: false,
            mensagemEnviadaComSucesso: false,
            plano: 1,
            linkAcao: 'https://sandbox-app.vindi.com.br/customer/pages/1fea8e20-e2dd-4780-b9d1-137870274421/subscriptions/new',
            RadioId: 0,
            RadioOutro: '',
        }

        this.popUpFecha = this.popUpFecha.bind(this);
        this.clickForaDaDiv = this.clickForaDaDiv.bind(this);

    }
    

    setaTipoCliente = (tipo) => {

        this.setState({
            tipoCliente: tipo
        })

    }

    componentDidMount() {

        document.addEventListener('mousedown', this.clickForaDaDiv);

    }

    componentWillUnmount() {

        document.removeEventListener('mousedown', this.clickForaDaDiv);

    }

    popUpFecha(node) {

        this.wrapperRef = node;
        
    }

    clickForaDaDiv(event) {

        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {

            this.props.fechaPopUpContratar()

        }

    }

    aceitoEmail = () => {

        this.setState({
            receberNovidades: !this.state.receberNovidades
        })

    }

    setaNome = (text) => {

        this.setState({
            nome: text.target.value
        })

    }

    setaEmpresa = (text) => {

        this.setState({
            empresa: text.target.value
        })

    }

    setaEmail = (text) => {

        this.setState({
            email: text.target.value
        })

        const usuario = text.target.value.substring(0, text.target.value.indexOf("@"));
        const dominio = text.target.value.substring(text.target.value.indexOf("@")+ 1, text.target.value.length);
         
        if ((usuario.length >=1) &&
            (dominio.length >=3) && 
            (usuario.search("@")===-1) && 
            (dominio.search("@")===-1) &&
            (usuario.search(" ")===-1) && 
            (dominio.search(" ")===-1) &&
            (dominio.search(".")!==-1) &&      
            (dominio.indexOf(".") >=1)&& 
            (dominio.lastIndexOf(".") < dominio.length - 1)) {
            this.setState({
                emailValidador: true
            })
        }
        else{
            this.setState({
                emailValidador: false
            })
        }

    }

    setaMensagem = (text) => {

        this.setState({
            mensagem: text.target.value
        })

    }

    /*
    
    POST /contatosite

        {
            "st_nome": "TESTE EMAIL",*
            "st_nome_empresa": "NOME EMPRESA TESTE",*
            "st_email": "emailteste@teste.com.br",*
            "mensagem": "Essa é uma mensagem de teste",
            "id_novidades": "1"
        }
    
        * campos com asterisco são obrigatórios.

        Quanto ao id_novidades, se o checkbox estiver false o valor deve ser 0 ou nao deve ser enviado, se estiver checado o value deve ser 1.
    
    */

    alteraPlano = (plano) => {

        const link = plano === 1 ? 
            'https://sandbox-app.vindi.com.br/customer/pages/1fea8e20-e2dd-4780-b9d1-137870274421/subscriptions/new'
        : plano === 2 ? 
            'https://sandbox-app.vindi.com.br/customer/pages/1affb88a-1ce1-46bb-a56c-4d5540999e3f/subscriptions/new'
        : 
            'https://sandbox-app.vindi.com.br/customer/pages/39577ac5-27d7-44e1-be5b-3648f1a17aa6/subscriptions/new'

        this.setState({
            plano: plano,
            linkAcao: link
        })

    }

    enviarMensagem = () => {

        const represento = this.state.RadioId === 0 ? 'Empresa/ Patrocinador' : this.state.RadioId === 1 ? 'Agência' : this.state.RadioId === 2 ? this.state.RadioOutro : ''

        this.setState({
            loaderEnviandoMensagem: true
        })

        !this.state.emailValidador || !this.state.nome || !this.state.empresa || !this.state.email || !this.state.mensagem || (this.state.RadioId === 2 && !this.state.RadioOutro) ?

            this.setState({
                loaderEnviandoMensagem: false,
                ErroEnviandoMensagem: true,
            })

        :

            AXIOS().post('contatosite', {
                "st_nome": this.state.nome, // Obrigatório
                "st_nome_empresa": this.state.empresa, // Obrigatório
                "st_email": this.state.email, // Obrigatório
                "mensagem": this.state.mensagem,
                "id_novidades": this.state.receberNovidades ? '1' : '',
                'st_representa': represento
            })
            .then((response) => {
                console.log(response)
                this.setState({
                    loaderEnviandoMensagem: false,
                    mensagemEnviadaComSucesso: true,
                })
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    loaderEnviandoMensagem: false,
                    ErroEnviandoMensagem: true,
                    mensagemEnviadaComSucesso: false,
                })
            })
    
    }

    fechaPopUp = () => {

        this.props.fechaPopUpContratar()

    }

    radioRepresenta = (id) => {

        this.setState({
            RadioId: id
        })

    }

    setaRadioOutro = (text) => {

        this.setState({
            RadioOutro: text.target.value
        })

    }

    render() {

        return (

            <div ref={this.popUpFecha} className="PopUpContratar" style={{width: this.props.ativaPopUpContratar ? '50%' : '', minWidth: this.props.ativaPopUpContratar ? '764px' : ''}}>

                <div className="popUpContratarContainer" style={{display: this.props.ativaPopUpContratar ? '' : 'none'}}>

                    <div className="popUpContratarHeader" onClick={this.props.fechaPopUpContratar}>
    
                        <img alt="sair" className="popUpContratarIconeSair" src={this.props.sair} />
                    
                    </div>

                    <div className="popUpContratarConteudo">

                        <div className="PopUpContratarTexto">

                            <div className="PopUpContratarTextoTitulo">

                                Um sistema pensado para empresas e proponentes

                            </div>

                            <div className="PopUpContratarTextoSubtitulo">

                                Desenvolvemos uma plataforma direcionada a necessidade para cada lado deste processo. Selecione aquela que mais se indentifica.

                            </div>

                            <div className="PopUpContratarTipoCliente">

                                <div className="PopUpContratarTipoClienteTitulo">

                                    Selecione uma categoria

                                </div>

                                <div className="PopUpContratarTipoClienteOpcaoContainer">

                                    <div className="PopUpContratarTipoClienteOpcao">

                                        <img alt="radio" className="PopUpContratarTipoClienteRadio" onClick={() => this.setaTipoCliente('empresa')} src={this.state.tipoCliente === 'empresa' ? RadioMarcado : RadioDesmarcado} /> Sou patrocinador

                                    </div>

                                    <div className="PopUpContratarTipoClienteOpcao">

                                        <img alt="radio" className="PopUpContratarTipoClienteRadio" onClick={() => this.setaTipoCliente('proponente')} src={this.state.tipoCliente === 'proponente' ? RadioMarcado : RadioDesmarcado} /> Sou proponente

                                    </div>

                                </div>

                                <div className="PopUpContratarConteudoFormulario">

                                {

                                        this.state.tipoCliente === 'empresa' ?

                                            this.state.mensagemEnviadaComSucesso ?
        
                                                <div className="PopUpContratarContatoEmpresa">
        
                                                    <div className="PopUpContratarContatoEmpresaTexto">
                                                    
                                                        <div className="PopUpContratarContatoTitulo">
                                                        
                                                            Perfeito! Mensagem recebida
        
                                                        </div>
                                                    
                                                        <div className="PopUpContratarContatoSubtitulo">
                                                        
                                                            Nossa equipe irá entrar em contato o mais breve possível para um bate papo Simbiótico para explicar com mais detalhes sobre o serviço.
        
                                                        </div>
        
                                                    </div>
        
                                                </div> 
                                                
                                            :

                                            <div className="PopUpContratarContatoEmpresa">

                                                <div className="PopUpContratarContatoEmpresaTexto">

                                                    {

                                                        this.state.ErroEnviandoMensagem &&
                                                
                                                            <div style={{color: 'red'}} className="PopUpContratarContatoTitulo">
                                                            
                                                                Ops! Parece que algo deu errado durante o envio, tente novamente!
        
                                                            </div>

                                                    }
                                                
                                                    <div className="PopUpContratarContatoTitulo">
                                                    
                                                        Sistema de gestão de incentivos

                                                    </div>
                                                
                                                    <div className="PopUpContratarContatoSubtitulo">
                                                    
                                                        Serviço completo que facilita o processo de investimento incentivado. Para saber mais sobre preços e planos entre em contato com nossa equipe.

                                                    </div>

                                                </div>

                                                <div className="PopUpContratarContatoEmpresaFormulario">
                                                
                                                    <div className="PopUpContratarContatoEmpresaFormularioSuperior">
                                                    
                                                        <input className="PopUpContratarContatoInputSuperior" placeholder="Digite seu nome" style={{borderBottom: this.state.ErroEnviandoMensagem && !this.state.nome && '1px solid red'}} onChange={(text) => this.setaNome(text)} value={this.state.nome} />
                                                    
                                                        <input className="PopUpContratarContatoInputSuperior" placeholder="Nome da empresa" style={{borderBottom: this.state.ErroEnviandoMensagem && !this.state.empresa && '1px solid red'}} onChange={(text) => this.setaEmpresa(text)} value={this.state.empresa} />

                                                    </div>

                                                    <div className="PopUpContratarContatoEmpresaFormularioSegundaLinha">
                                                    
                                                        <input className="PopUpContratarContatoInput" placeholder="Digite seu email" style={{borderBottom: this.state.ErroEnviandoMensagem && (!this.state.emailValidador || !this.state.email) && '1px solid red'}} onChange={(text) => this.setaEmail(text)} value={this.state.email} />
                                                    
                                                        {

                                                            !this.state.emailValidador && this.state.email.length > 0 &&

                                                                <div className="secaoContatoEmailmensagemErro">
                    
                                                                    Digite um email valido!
                    
                                                                </div>

                                                        }

                                                        <input className="PopUpContratarContatoInput" placeholder="Gostaria de deixar uma mensagem?" style={{borderBottom: this.state.ErroEnviandoMensagem && !this.state.mensagem && '1px solid red'}} onChange={(text) => this.setaMensagem(text)} value={this.state.mensagem} />

                                                    </div>

                                                    <div className="secaoContatoRepresenta">

                                                        <div className="secaoContatoRepresentaContainerPopUp">

                                                            <div className="secaoContatoRepresentaLabel">

                                                            </div>

                                                            <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                                <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(0)} checked={this.state.RadioId === 0 ? true : false} /> 
                                                                
                                                                Você representa: patrocinador

                                                            </div>

                                                            <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                                <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(1)} checked={this.state.RadioId === 1 ? true : false} />

                                                                Represento uma agência

                                                            </div>

                                                            <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                                <input className="secaoContatoInput" style={{backgroundColor: '#fafafa'}} type="radio" onChange={() => this.radioRepresenta(2)} checked={this.state.RadioId === 2 ? true : false} />

                                                                Outro ?

                                                            </div>

                                                            {

                                                                this.state.RadioId === 2 && 

                                                                    <input className="inputsecaoContatoTermo" placeholder="Conte-nos qual..." style={{backgroundColor: '#fafafa', borderBottom: this.state.ErroEnviandoMensagem && !this.state.RadioOutro && '1px solid red'}} value={this.state.RadioOutro} onChange={(text) => this.setaRadioOutro(text)} /> 

                                                            }

                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="PopUpContratarContatoFooterContainer">
                                                
                                                    <div className="PopUpContratarContatoFooterConteudo">
                                                    
                                                        <img alt="imagemCheckbox" className="PopUpContratarContatoFooterCheckbox" onClick={() => this.aceitoEmail()} src={this.state.receberNovidades ? CheckboxChecked : Checkbox} />

                                                        Aceito receber novidades da Simbiose Social
                                                    
                                                    </div>

                                                    <div className="PopUpContratarContatoFooterBotaoContainer">
                                                    
                                                        <div className="PopUpContratarContatoFooterBotao" style={{pointerEvents: this.state.loaderEnviandoMensagem && 'none'}} onClick={() => this.enviarMensagem()}>
                                                        
                                                            {this.state.loaderEnviandoMensagem ? 'Enviando mensagem...' : 'Falar com simbiótico' }

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        :

                                        this.state.tipoCliente === 'proponente' ?

                                            <PlanosExpandiveis fechaPopUp={this.fechaPopUp} />


                                    :

                                        ''

                                }                                    
                                    
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        )

    }

 }

export default PopUpContratar;
