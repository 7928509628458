import React from 'react'
import Checkbox from '../../images/checkbox.svg'
import CheckboxChecked from '../../images/checkboxChecked.svg'
import Hide from '../../images/hidePassword.svg'
import See from '../../images/seePassword.svg'
import pessoaInativa from '../../images/tipoPessoaRadioDesmarcado.svg'
import pessoaAtiva from '../../images/tipoPessoRadioSelecionado.svg'
import { AXIOS } from '../../service/Base'
import { Redirect } from "react-router-dom";
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom'

class Cadastro extends React.Component {

    constructor() {

        super()

        this.state = {
            politicaPrivacidade: false,
            aceitoEmail: false,
            inputTipo: "password",
            planoSelecionadoProponente: '',
            politicaObrigatoriaMensagem: false,
            email: '',
            senha: '',
            confirmaSenha: '',
            linkPlano: '',
            loadingCadastrando: false,
            cadastroErro: false,
            cadastroSucesso: false,
            cpf: '',
            cnpj: '',
            nome: '',
            tipoPessoa: 'fisica',
            razao: '',
            redireciona: false,
            stPlano: '',
            mensagemErro: '',
            phoneNumber: '',
            indicação: '',
            campopessoaindicou: false,
            pessoaindicou: ''


        }

    }

    componentWillMount() {
        window.location.replace('https://simbi.social/');

        this.setState({
            planoSelecionadoProponente: this.props.location.planoSelecionadoProponente ? this.props.location.planoSelecionadoProponente : '',
            linkPlano: this.props.location.linkPagamento ? this.props.location.linkPagamento : '',
            cadastroSucesso: false,
            stPlano: this.props.location.stPlano && this.props.location.stPlano
        })

    }

    concordoPolitica = () => {

        this.setState({
            politicaPrivacidade: !this.state.politicaPrivacidade,
            politicaObrigatoriaMensagem: false
        })

    }

    aceitoEmail = () => {

        this.setState({
            aceitoEmail: !this.state.aceitoEmail
        })

    }

    exibeSenha = () => {

        this.setState({
            inputTipo: "txt"
        })

    }

    escondeSenha = () => {

        this.setState({
            inputTipo: "password"
        })

    }

    politicaObrigatoria = () => {

        this.setState({
            politicaObrigatoriaMensagem: true
        })

    }

    setaEmail = (text) => {

        this.setState({
            email: text.target.value
        })

    }

    setaSenha = (text) => {

        this.setState({
            senha: text.target.value
        })

    }

    setaSenhaConfirmacao = (text) => {

        this.setState({
            confirmaSenha: text.target.value
        })

    }

    setacpf = (text) => {

        this.setState({
            cpf: text.target.value.replace(/[^0-9]/g, '')
        })

    }

    setacnpj = (text) => {

        this.setState({
            cnpj: text.target.value.replace(/[^0-9]/g, '')
        })

    }

    setaNome = (text) => {

        this.setState({
            nome: text.target.value
        })

    }

    tipoPessoa = (tipoPessoa) => {

        this.setState({
            tipoPessoa: tipoPessoa
        })

    }

    setaRazao = (text) => {

        this.setState({
            razao: text.target.value
        })

    }

    cpfMask = value => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }

    cnpjMask = v => {

        //Coloca ponto entre o segundo e o terceiro dígitos
        v = v.replace(/^(\d{2})(\d)/, "$1.$2")

        //Coloca ponto entre o quinto e o sexto dígitos
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")

        //Coloca uma barra entre o oitavo e o nono dígitos
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")

        //Coloca um hífen depois do bloco de quatro dígitos
        v = v.replace(/(\d{4})(\d)/, "$1-$2")

        return v
    }

    cadastrar = (e) => {

        e.preventDefault()
        this.state.tipoPessoa === 'fisica' ?

            this.cadastrarCPF()

            :

            this.cadastrarCNPJ()

    }

    cadastrarCPF = () => {

        console.log('cadastra CPF')

        this.setState({
            loadingCadastrando: true,
            mensagemErro: ''
        })

        AXIOS().post('registerradar', {
            'st_nome_proponente': this.state.nome,
            'st_tipo_pessoa': 'cpf',
            'email': this.state.email,
            'password': this.state.senha,
            'c_password': this.state.confirmaSenha,
            'cpfcnpj': this.state.cpf,
            'st_plano': this.state.stPlano,
            'st_telefone': this.state.phoneNumber,
            'indicação': this.state.indicação
        })
            .then((response) => {
                console.log(response)
                this.setState({
                    loadingCadastrando: false,
                    cadastroErro: false,
                    cadastroSucesso: true,
                    redireciona: response.status === 200 ? true : false,
                    //redireciona: response.status === 200 ? this.props.location.card ? this.props.location.card === 'Gratis' ? true : false : true : false,
                    mensagemErro: '',
                })
                //response.status === 200 && this.redirecionaPagamento()
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    loadingCadastrando: false,
                    cadastroErro: true,
                    mensagemErro: error ?
                        error.response.data.data.email ?
                            error.response.data.data.email[0]
                            :
                            error.response.data.data.cpfcnpj ?
                                error.response.data.data.cpfcnpj[0]
                                :
                                ''
                        :
                        error.response.data.data.password ?
                            error.response.data.data.password[0]
                            :
                            ''
                })
            })

    }

    cadastrarCNPJ = () => {

        console.log('cadastra CNPJ')

        this.setState({
            loadingCadastrando: true,
            mensagemErro: ''
        })

        AXIOS().post('registerradar', {
            'st_nome_proponente': this.state.razao,
            'st_tipo_pessoa': 'cnpj',
            'cpfcnpj': this.state.cnpj,
            'st_nome_usuario': this.state.nome,
            'email': this.state.email,
            'password': this.state.senha,
            'c_password': this.state.confirmaSenha,
            'st_plano': this.state.stPlano,
            'st_telefone': this.state.phoneNumber,
            'indicação': this.state.indicação
        })
            .then((response) => {
                console.log(response)
                this.setState({
                    loadingCadastrando: false,
                    cadastroErro: false,
                    cadastroSucesso: true,
                    redireciona: response.status === 200 ? true : false,
                    //redireciona: response.status === 200 ? this.props.location.card ? this.props.location.card === 'Gratis' ? true : false : true : false,
                    mensagemErro: '',
                })
                //response.status === 200 && this.redirecionaPagamento()
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    loadingCadastrando: false,
                    cadastroErro: true,
                    mensagemErro: error ? error.response.data &&
                        error.response.data.data.email ?
                            error.response.data.data.email[0]
                            :
                            error.response.data.data.cpfcnpj ?
                                error.response.data.data.cpfcnpj[0]
                                :
                                ''
                        :
                        error.response.data.data.password ?
                            error.response.data.data.password[0]
                            :
                            ''
                })
            })

    }

    redirecionaPagamento = () => {

        this.props.location.card &&

            this.props.location.card !== 'Gratis' &&

            window.location.replace(this.state.linkPlano)

    }

    setaTelefone = (e) => [

        this.setState({
            phoneNumber: e.target.value.replace(/[^0-9]/g, ''),
        })

    ]

    render() {

        const erro = <svg xmlns="http://www.w3.org/2000/svg" width="29.4" height="29.4" viewBox="0 0 18 18"><g id="Grupo_9134" data-name="Grupo 9134" transform="translate(-1232 -178)"><circle strokeWidth="2" stroke="#E6B8B8" fill="#F5D7D7" transform="translate(1233 179)" r="8" cy="8" cx="8" data-name="Elipse 102" id="Elipse_102"></circle><text id="_" data-name="!" transform="translate(1239.04 191)" fill="#B34747" fontSize="12" fontFamily="AvenirNext-DemiBold, Avenir Next" fontWeight="300"><tspan x="0" y="0">!</tspan></text></g></svg>
        const erroSenha = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29.4" viewBox="0 0 18 18"><g id="Grupo_9134" data-name="Grupo 9134" transform="translate(-1232 -178)"><circle strokeWidth="2" stroke="#E6B8B8" fill="#F5D7D7" transform="translate(1233 179)" r="8" cy="8" cx="8" data-name="Elipse 102" id="Elipse_102"></circle><text id="_" data-name="!" transform="translate(1239.04 191)" fill="#B34747" fontSize="12" fontFamily="AvenirNext-DemiBold, Avenir Next" fontWeight="300"><tspan x="0" y="0">!</tspan></text></g></svg>

        return (

            <div>

                {
                    this.state.redireciona &&

                    <Redirect push to={{ pathname: "/cadastro-confirma", search: `${this.props.location.search}`, tipoTexto: { id: this.props.location.card } }} />
                }

                <div className="paginaCadastroContainer">

                    <div className="paginaCadastroLadoEsquerdo">



                    </div>

                    <div className="paginaCadastroLadoDireito">

                        <div className="paginaCadastroTexto">

                            <div className="paginaCadastroTextoTitulo">

                                Primeiro passo, complete o seu cadastro :)

                            </div>

                            <div className="paginaCadastroTextoSubtitulo">

                                {

                                    this.props.location.card === 'Gratis' ?

                                        'Complete o cadastro abaixo e escolha o melhor dia para participar do tutorial com a nossa equipe Simbiótica!'

                                        :

                                        'Complete o cadastro abaixo para ganhar acesso à plataforma da Simbiose Social.'

                                }



                            </div>

                            <div className="paginaCadastroTipoPessoaContainer">

                                <div className="paginaCadastroTipoPessoa" onClick={() => this.tipoPessoa('fisica')}>

                                    <img className="paginaCadastroTipoPessoaIcone" alt="tipoPessoa" src={this.state.tipoPessoa === 'fisica' ? pessoaAtiva : pessoaInativa} />Pessoa física

                                </div>

                                <div className="paginaCadastroTipoPessoa" onClick={() => this.tipoPessoa('juridica')}>

                                    <img className="paginaCadastroTipoPessoaIcone" alt="tipoPessoa" src={this.state.tipoPessoa === 'juridica' ? pessoaAtiva : pessoaInativa} />Pessoa juridica

                                </div>

                            </div>

                        </div>

                        <form onSubmit={this.state.politicaPrivacidade ? (e) => this.cadastrar(e) : (e) => this.politicaObrigatoria(e)} className="paginaCadastroFormulario" style={{ marginTop: this.state.senha !== this.state.confirmaSenha ? '80px' : '' }}>

                            {

                                this.state.tipoPessoa === 'juridica' &&

                                <input style={{ marginTop: '5px' }} className="paginaCadastroFormularioInput" placeholder="Digite sua razão social" onChange={(text) => this.setaRazao(text)} value={this.state.razao} />

                            }

                            <input style={{ marginTop: '5px' }} className="paginaCadastroFormularioInput" placeholder="Digite seu nome" onChange={(text) => this.setaNome(text)} value={this.state.nome} />

                            {

                                this.state.tipoPessoa === 'fisica' ?

                                    <input style={{ marginTop: '5px' }} className="paginaCadastroFormularioInput" placeholder="Digite seu CPF" maxLength={14} onChange={(text) => this.setacpf(text)} value={this.cpfMask(this.state.cpf)} />

                                    :

                                    <input style={{ marginTop: '5px' }} className="paginaCadastroFormularioInput" placeholder="Digite seu CNPJ" maxLength={18} onChange={(text) => this.setacnpj(text)} value={this.cnpjMask(this.state.cnpj)} />

                            }

                            <InputMask
                                style={{ marginTop: '5px' }}
                                className="paginaCadastroFormularioInput"
                                mask={this.state.phoneNumber.length < 13 ? "+99 (99) 9999-99999" : "+99 (99) 99999-9999"}
                                maskChar=""
                                value={this.state.phoneNumber}
                                onChange={(e) => this.setaTelefone(e)}
                                placeholder='(Opcional) - Número do telefone'
                            />

                            <input style={{ marginTop: '5px' }} className="paginaCadastroFormularioInput" name="email" type="email" placeholder="Digite seu email" onChange={(text) => this.setaEmail(text)} value={this.state.email} />

                            <div style={{ display: 'flex', width: '530px', marginTop: '5px' }}>

                                {

                                    <img className="hideOrSeePasswordTag" alt="hideOrSeePassword" src={this.state.inputTipo === 'password' ? See : Hide}
                                        onClick={this.state.inputTipo === "password" ? () => this.exibeSenha() : () => this.escondeSenha()} />

                                }

                                <input className="paginaCadastroFormularioInput" placeholder="Digite uma senha" onChange={(text) => this.setaSenha(text)} value={this.state.senha} type={this.state.inputTipo} />

                            </div>

                            <div style={{ display: 'flex', width: '530px', marginTop: '5px' }} >

                                {

                                    <img className="hideOrSeePasswordConfirmationTag" alt="hideOrSeePassword" src={this.state.inputTipo === 'password' ? See : Hide}
                                        onClick={this.state.inputTipo === "password" ? () => this.exibeSenha() : () => this.escondeSenha()} />

                                }

                                <input className="paginaCadastroFormularioInput" placeholder="Confirmar senha" onChange={(text) => this.setaSenhaConfirmacao(text)} value={this.state.confirmaSenha} type={this.state.inputTipo} />

                            </div>

                            <div style={{ display: 'flex', width: '530px', marginTop: '5px' }}>
                                <select onChange={(e) => {
                                    this.setState({
                                        indicação: e.target.value,
                                        campopessoaindicou: false
                                    });
                                    console.log(this.state.indicação)
                                }} style={{ width: '100%' }} className="paginaCadastroFormularioInput" name="indicação" >
                                    <option value="">Como você nos achou?</option>
                                    <option value="facebook">Facebook</option>
                                    <option value="google">Google</option>
                                    <option value="newsletter">Newsletter</option>
                                    <option value="evento/webinar">evento/webinar</option>
                                    <option value="indicação">Indicação Pessoal</option>
                                </select>
                            </div>
                            {
                                this.state.campopessoaindicou &&
                                <div>
                                    <input className="paginaCadastroFormularioInput" placeholder="Digite a Pessoa/Empresa que nos indicou" onChange={(text) => {
                                        this.setState({
                                            pessoaindicou: text.target.value,
                                            indicação: text.target.value

                                        })
                                    }} value={this.state.pessoaindicou} />
                                </div>
                            }


                            <div className="paginaCadastroFormularioTermos">

                                <div className="paginaCadastroFormularioTermo">

                                    {

                                        <img className="checkboxTag" alt="checkbox" src={this.state.politicaPrivacidade ? CheckboxChecked : Checkbox} onClick={() => this.concordoPolitica()} />

                                    }

                                    <div className="paginaCadastroFormularioTermoString">

                                        Concordo com a <a className="paginaCadastroFormularioTermoStringLink" href="https://simbiose.social/politica-de-privacidade" target="_blank" rel="noopener noreferrer">Política de privacidade</a> e com os Termos de serviços da Simbiose Social.

                                    </div>

                                </div>

                                <div className="paginaCadastroFormularioTermo">

                                    {

                                        <img className="checkboxTag" alt="checkbox" src={this.state.aceitoEmail ? CheckboxChecked : Checkbox} onClick={() => this.aceitoEmail()} />

                                    }

                                    <div className="paginaCadastroFormularioTermoString">

                                        Aceito receber emails da Simbiose Social.

                                    </div>

                                </div>

                                <div className="paginaCadastroFormularioTermo">

                                    <div className="paginaCadastroFormularioTermoString">

                                        <Link to="/login">Já tenho cadastro, quero reagendar meu teste.</Link>

                                    </div>

                                </div>

                            </div>

                            <input type="submit" className="paginaCadastroFormularioBotao" style={{
                                pointerEvents: this.state.loadingCadastrando || this.state.cadastroSucesso || (!this.state.nome) || (this.state.tipoPessoa == "juridica" && !this.state.razao ? true : false) || (!this.state.cpf && !this.state.cnpj) || !this.state.email || !this.state.senha || this.state.senha !== this.state.confirmaSenha || !this.state.politicaPrivacidade || !this.state.indicação ? 'none' : 'auto',
                                color: !this.state.politicaPrivacidade || (!this.state.nome) || (this.state.tipoPessoa == "juridica" && !this.state.razao ? true : false) || (!this.state.cpf && !this.state.cnpj) || !this.state.email || !this.state.senha || this.state.senha !== this.state.confirmaSenha || !this.state.politicaPrivacidade || !this.state.indicação ? 'rgb(128, 128, 128)' : '',
                                backgroundColor: !this.state.politicaPrivacidade || (!this.state.nome) || (this.state.tipoPessoa == "juridica" && !this.state.razao ? true : false) || (!this.state.cpf && !this.state.cnpj) || !this.state.email || !this.state.senha || this.state.senha !== this.state.confirmaSenha || !this.state.politicaPrivacidade || !this.state.indicação ? 'rgb(235, 235, 235)' : ''
                            }}
                                value={this.state.loadingCadastrando ? 'Cadastrando...' : this.state.cadastroSucesso ? 'Cadastrado com sucesso!' : 'Criar conta'}
                            />

                        </form>

                        {

                            this.state.senha !== this.state.confirmaSenha &&

                            <div className="mensagemSenha">

                                {erroSenha} <div className="mensagemSenhaMensagem">Ops! As senhas não coincidem!</div>

                            </div>

                        }

                        <div className="paginaCadastroFormularioBotaoEnviarContainer">

                            {

                                ((this.state.politicaObrigatoriaMensagem && !this.state.politicaPrivacidade) || !this.state.email) &&

                                <div className="mensagemAceitarTermos">

                                    {erro} <div className="mensagemAceitarTermosMensagem">

                                        {

                                            this.state.politicaObrigatoriaMensagem && !this.state.politicaPrivacidade ?

                                                'Por favor aceite nossos termos de serviço!'

                                                :

                                                !this.state.email ?

                                                    'O campo de email está vazio!'

                                                    :

                                                    ''

                                        }

                                    </div>

                                </div>

                            }

                            {

                                this.state.cadastroErro &&

                                <div className="mensagemAceitarTermos">

                                    {erro}

                                    <div className="mensagemAceitarTermosMensagem">

                                        <div dangerouslySetInnerHTML={{ __html: this.state.mensagemErro }} />

                                    </div>

                                </div>

                            }

                        </div>

                    </div>

                </div>

            </div>

        )

    }

}

export default Cadastro;