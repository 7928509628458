import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import * as serialize from 'form-serialize';
import Footer from '../Footer'
import InputMask from 'react-input-mask'
import { AXIOS } from '../../service/Base'
import Axios from 'axios';

class ContatoLanding extends React.Component {
  constructor() {
    super()
    this.state = {
      titulo: "",
      subtitulo: "",
      verificadocaptcha: '',
      perguntas: [],
      arquivoapresentação: '',
      respostas: [],
      nomeEmpresa: '',
      respostasfiles: [],
      email: '',
      nomeproponente: '',
      nome_ong: '',
      tooltips: [],
      form: false,
      sectionagradecimento: false,
      loading: false,
      logoempresa: "",
      nome_projeto: "",
      cpf_cnpj: "",
      nome_lei: "",
      campo_outralei: false,
      aprovadoalgumalei: false,
      leis: [],
      evento: ""

    }
    this.enviarcontato = this.enviarcontato.bind(this)
    this.dadosform = React.createRef();
  }


  componentDidMount() {

    const id = this.props.match.params.id_cliente
    AXIOS().get(`perguntasformlanding/${id}`).then((response) => {
      if (!response.data.perguntas["0"]) {
        //window.location.href = "https://simbiose.social/";
      } else {
        this.setState({
          perguntas: response.data.perguntas,
          nomeEmpresa: response.data.perguntas["0"] && response.data.perguntas["0"].nome_cliente,
          form: true,
          logoempresa: response.data.link_logo,
          titulo: response.data.titulo,
          subtitulo: response.data.subtitulo,
          leis: response.data.leis,
          evento: response.data.evento
        })
        response.data.perguntas.map((tooltips, key) => {
          this.state.tooltips[key] = false
        })
      }
    }).catch(() => {
      window.location.href = "https://simbiose.social/";
    })


  }




  async enviarcontato(e) {
    e.preventDefault()
    this.setState({
      loading: true
    })
    const id_cliente = this.props.match.params.id_cliente
    let respostas = [];
    let respostasfiles = []

    this.state.respostas.map((resposta) => {
      respostas.push(resposta)
    })
    this.state.respostasfiles.map((resposta) => {
      respostasfiles.push(resposta)
    })

    await AXIOS().post(`criarcontatolanding/${this.state.perguntas["0"].id_cliente}`, { email: this.state.email, nome: this.state.nome, nome_ong: this.state.nome_ong, nome_projeto: this.state.nome_projeto, nome_lei: this.state.nome_lei, nome_cliente: this.state.perguntas["0"].nome_cliente, cpf_cnpj: this.state.cpf_cnpj }).then(async (contatoform) => {
      respostasfiles.forEach(async (response) => {
        let data = new FormData()
        data.append('id_cliente', response.id_cliente);
        data.append('id_pergunta', response.id_pergunta);
        data.append('nome_cliente', response.nome_cliente);
        data.append('pergunta', response.pergunta);
        data.append('resposta', response.resposta);
        data.append('tipo_pergunta', response.tipo_pergunta);
        data.append('email', this.state.email);
        data.append('nome', this.state.nome);
        data.append('nome_ong', this.state.nome_ong);
        data.append('id_contatoform', contatoform.data.id_contatoform)
        AXIOS().post(`respostaformlanding/${this.state.perguntas["0"].id_cliente}`, data)
      })

      await AXIOS().post(`respostaformlanding/${this.state.perguntas["0"].id_cliente}`, { resposta: respostas, email: this.state.email, nome: this.state.nome, nome_ong: this.state.nome_ong, id_contatoform: contatoform.data.id_contatoform }).then(async () => {
        this.setState({
          loading: false,
          sectionagradecimento: true
        })
      })
    })


    return false;
  }
  render() {
    return (
      <div className="paginaContatoLandingContainer" >

        {/*Logo empresas Participantes */
          < div className="empresastopbar" >
            <div style={{ marginRight: '10px', fontSize: '16px' }}>Apoio:</div>
            {
              this.state.perguntas.length > 0 && this.state.logoempresa !== "" &&
              <img className="logoEmpresa" alt="logoempresa" src={`${this.state.logoempresa}`} />
            }
          </div >
        }
        {
          !this.state.sectionagradecimento ?
            this.state.form &&
            <div>
              <div className="paginaContatoLandingSection" style={{ marginTop: '40px', minHeight: '190px' }}>
                <strong className="h1title" style={{ fontSize: '30px' }}>
                  {this.state.titulo}
                </strong>

                <h1 className="h1title" style={{ fontSize: '20px', marginTop: '30px' }}>
                  {this.state.subtitulo}
                </h1>
              </div>
              <form  className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '500px', backgroundColor: '#fff' }} onSubmit={(e) => this.enviarcontato(e)} >
                <div style={{ display: "grid", gridTemplateColumns: "420px 420px" }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                    <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway' }}>Email*</span>
                    <input autoComplete="none"className="inputform" type="text"  required onChange={(e) => {
                      this.setState({ email: e.target.value })
                    }} />

                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', marginTop: '20px' }}>Nome*</span>
                    <input autoComplete="none" className="inputform" type="text" required onChange={(e) => {
                      this.setState({ nome: e.target.value })
                    }} />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', marginTop: '25px' }}>Nome do Proponente / Razão Social*</span>
                    <input autoComplete="none" className="inputform" type="text"  required onChange={(e) => {
                      this.setState({ nome_ong: e.target.value })
                    }} />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', marginTop: '25px' }}>CPF/CNPJ*</span>
                    <input autoComplete="none" className="inputform" type="text"  minLength={11} maxLength={14} required onChange={(e) => {
                      this.setState({ cpf_cnpj: e.target.value })
                    }} />
                  </div>


                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', marginTop: '25px' }}>Nome do Projeto*</span>
                    <input autoComplete="none" className="inputform" type="text" required onChange={(e) => {
                      this.setState({ nome_projeto: e.target.value })
                    }} />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', marginTop: '25px' }}>Seu projeto está Aprovado em alguma lei de incentivo?*</span>
                    <div>

                      <input type="radio" required name="aproavado_lei" id="Sim" value="Sim" onClick={() => {
                        this.state.leis.sort((a, b) => {
                          if (a.st_descricao > b.st_descricao) {
                            return 1;
                          }
                          if (a.st_descricao < b.st_descricao) {
                            return -1;
                          }

                          return 0;
                        }); this.setState({ aprovadoalgumalei: true, nome_lei: this.state.leis["0"].st_descricao }); this.forceUpdate();
                      }} />
                      <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway' }}>Sim</span>
                    </div>
                    <div>
                      <input name="aproavado_lei" required type="radio" id="Nao" value="Não" onClick={() => { this.setState({ aprovadoalgumalei: false, nome_lei: "Verba Direta" }); this.forceUpdate(); }} />
                      <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway' }}>Não</span>
                    </div>
                  </div>
                  {
                    this.state.aprovadoalgumalei &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', marginTop: '25px' }}>Nome da Lei*</span>
                      <select className="inputform" onChange={(e) => {
                        e.target.value === "outralei" ? this.setState({ campo_outralei: true }) :
                          this.setState({ nome_lei: e.target.value, campo_outralei: false })
                      }}>

                        {
                          this.state.leis && this.state.leis.length > 0 && this.state.leis.sort((a, b) => {
                            if (a.st_descricao > b.st_descricao) {
                              return 1;
                            }
                            if (a.st_descricao < b.st_descricao) {
                              return -1;
                            }

                            return 0;
                          }) &&
                          this.state.leis.map((response) => {
                            return (
                              <option label={response.st_descricao} value={response.st_descricao} />
                            )

                          })
                        }
                        <option label="Outra Lei" value={"outralei"} />
                      </select>
                    </div>
                  }



                  {
                    this.state.campo_outralei &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', marginTop: '25px' }}>Digite o nome da nova lei: *</span>
                      <input autoComplete="none" className="inputform" type="text" name="campo_outralei" required onChange={(e) => {
                        this.setState({ nome_lei: e.target.value })
                      }} />
                    </div>
                  }
                  {
                    this.state.perguntas && this.state.perguntas.map((response, key) => {
                      let chave = key + 1
                      return (
                        <div key={chave}>


                          {
                            response.tipo_pergunta == 'texto' &&
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '25px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', userSelect: "none" }}>{chave}.{response.pergunta}</span>
                                <a
                                  style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', userSelect: "none", marginLeft: '5px' }}
                                  onMouseOverCapture={() => {
                                    this.state.tooltips[chave] = true; this.forceUpdate()
                                  }} onMouseOutCapture={() => {
                                    this.state.tooltips[chave] = false; this.forceUpdate()
                                  }}
                                >
                                  <span
                                    className="fas fa-info-circle"
                                  />
                                </a>
                                <div>
                                  {
                                    this.state.tooltips[chave] &&
                                    <div className="tooltip" >{response.tooltip}</div>
                                  }
                                </div>
                              </div>
                              <input autoComplete="none" className="inputform" required={true} onChange={(e) => {
                                this.state.respostas[chave] = {
                                  id_pergunta: response.id_pergunta,
                                  pergunta: response.pergunta,
                                  id_cliente: this.state.perguntas["0"].id_cliente,
                                  resposta: e.target.value,
                                  nome_cliente: response.nome_cliente,
                                  tipo_pergunta: response.tipo_pergunta,
                                }
                              }} />
                            </div>
                          }


                          {
                            response.tipo_pergunta == 'checkbox' &&
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <span onMouseOverCapture={() => {
                                  this.state.tooltips[chave] = true; this.forceUpdate()
                                }} onMouseOutCapture={() => {
                                  this.state.tooltips[chave] = false; this.forceUpdate()
                                }} style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', userSelect: "none" }}>{chave}.{response.pergunta}</span>
                                <a
                                  style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', userSelect: "none", marginLeft: '5px' }}
                                  onMouseOverCapture={() => {
                                    this.state.tooltips[chave] = true; this.forceUpdate()
                                  }} onMouseOutCapture={() => {
                                    this.state.tooltips[chave] = false; this.forceUpdate()
                                  }}
                                >
                                  <span
                                    className="fas fa-info-circle"
                                  />
                                </a>
                                <div>
                                  {
                                    this.state.tooltips[chave] &&
                                    <div className="tooltip" >{response.tooltip}</div>
                                  }
                                </div>
                              </div>
                              <div>
                                <input checked={this.state.respostas[chave] && this.state.respostas[chave].resposta == 'Sim' ? true : false} onClick={() => {
                                  this.state.respostas[chave] = {
                                    id_pergunta: response.id_pergunta,
                                    id_cliente: this.state.perguntas["0"].id_cliente,
                                    pergunta: response.pergunta,
                                    resposta: "Sim",
                                    nome_cliente: response.nome_cliente,
                                    tipo_pergunta: response.tipo_pergunta
                                  }; this.forceUpdate();
                                }} type="radio" id="sim" value="Sim" />
                                <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway' }}>Sim</span>
                              </div>

                              <div>
                                <input checked={this.state.respostas[chave] && this.state.respostas[chave].resposta == 'Não' ? true : false} onClick={() => {
                                  this.state.respostas[chave] = {
                                    id_pergunta: response.id_pergunta,
                                    id_cliente: this.state.perguntas["0"].id_cliente,
                                    pergunta: response.pergunta,
                                    resposta: "Não",
                                    nome_cliente: response.nome_cliente,
                                    tipo_pergunta: response.tipo_pergunta
                                  }; this.forceUpdate();
                                }} type="radio" id="Não" value="Não" />
                                <span style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway' }}>Não</span>
                              </div>
                            </div>
                          }

                          {

                            response.tipo_pergunta == 'upload' &&

                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <span onMouseOverCapture={() => {
                                  this.state.tooltips[chave] = true; this.forceUpdate();
                                }} onMouseOutCapture={() => {
                                  this.state.tooltips[chave] = false; this.forceUpdate();
                                }} style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', userSelect: "none" }}>{chave}.{response.pergunta}*</span>
                                <a
                                  style={{ color: ' #1d2024', fontSize: '12px', fontFamily: 'Raleway', userSelect: "none", marginLeft: '5px' }}
                                  onMouseOverCapture={() => {
                                    this.state.tooltips[chave] = true; this.forceUpdate()
                                  }} onMouseOutCapture={() => {
                                    this.state.tooltips[chave] = false; this.forceUpdate()
                                  }}
                                >
                                  <span
                                    className="fas fa-info-circle"
                                  />
                                </a>
                                <div>
                                  {
                                    this.state.tooltips[chave] &&
                                    <div className="tooltip" >{response.tooltip}</div>
                                  }
                                </div>
                              </div>
                              <div className="inputform" style={{ display: 'flex', alignItems: 'center' }} >
                                <input type="file" onChange={(e) => {
                                  this.state.respostasfiles[chave] = {
                                    id_pergunta: response.id_pergunta,
                                    id_cliente: this.state.perguntas["0"].id_cliente,
                                    pergunta: response.pergunta,
                                    resposta: e.target.files[0],
                                    nome_cliente: response.nome_cliente,
                                    tipo_pergunta: response.tipo_pergunta
                                  }

                                }} style={{ width: '394px', height: '25px' }} required />
                              </div>
                            </div>
                          }


                        </div>
                      )
                    })
                  }
                </div>
                <div className="inputformrows" >

                  <div style={{ display: 'flex', marginTop: '30px' }}>
                    <span style={{ fontSize: '12px', fontFamily: 'Raleway' }}>Ao preencher o formulário, você está ciente que a nossa empresa poderá enviar, de tempos em tempos, comunicações e conteúdos de acordo com os seus interesses. Você pode modificar as suas permissões a qualquer tempo.
Confira nossa  <a target="_blank" style={{ color: '#000' }} href="https://simbiose.social/politica-de-privacidade">Política de Privacidade</a>.</span>
                  </div>
                </div>

                <div className="inputformrows">
                  {this.state.verificadocaptcha == ''
                    ?

                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>

                      <ReCAPTCHA
                        sitekey="6LcED60ZAAAAAF2g7DhalNIEawcwKuAYqty1-AQS"
                        onChange={(e) => {
                          setInterval(() => {
                            this.setState({ verificadocaptcha: e })
                          }, 700)
                        }}
                        hl={'pt-BR'}

                      />
                    </div>


                    :
                    <div style={{ display: 'flex', marginTop: '20px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                      <button type="submit" className="botaofaçaparte"  >{this.state.loading ? <div className="loader"></div> : "Faça Parte"}</button>
                    </div>
                  }

                </div>
              </form>

              {/*O que é a Simbiose Social?*/}
              <div className="paginaContatoLandingSection" style={{ marginTop: '20px', minHeight: '250px' }}>
                <strong className="h1title" style={{ fontSize: '36px', marginBottom: '20px' }}>
                  O que é a Simbiose Social?
                </strong>

                <span className="textosection">
                  A Simbiose é uma plataforma que une patrocinadores e proponentes semelhantes através da inteligência de dados. Fundada em 2016, a partir da missão de democratizar o acesso a recursos, somos uma startup de tecnologia voltada para o impacto social. Especialistas em leis de incentivo, conectamos empresas, proponentes e governo para potencializar a transformação. Apostamos e acreditamos na democratização e transparência das informações e dados públicos como catalisadores de mudança social.
                </span>
              </div>

              {/*O que acontece quando envio meu projeto? */}
              <div className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '300px', backgroundColor: '#fff', flexDirection: 'column' }}>

                <strong className="h1title" style={{ fontSize: '36px', marginBottom: '20px', color: '#1f99a7' }}>
                  O que acontece quando envio meu projeto?
                </strong>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginRight: '30px' }}>
                    <img className="imgpassosenvio" src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1613761774/$5dhh7i9r36w" alt="1" />
                    <span className="textopassosenvio">
                      Recebemos todos os dados enviados pelo formulário e o seu projeto entrará em fase de análise.
                    </span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginRight: '30px' }}>
                    <img className="imgpassosenvio" src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1613761774/$o5vg0ha23l" alt="2" />
                    <span className="textopassosenvio">
                      Caso os documentos estejam regulares, ele será aprovado. Você receberá um e-mail com o resultado da análise!
                    </span>
                  </div>


                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginRight: '30px' }}>
                    <img className="imgpassosenvio" src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1613761774/$6cqu4o4pntd" alt="3" />
                    <span className="textopassosenvio">
                      Uma vez aprovado, o projeto terá destaque na plataforma. O patrocinador poderá encontrá-lo e saberá que entrou em contato.
                    </span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginRight: '30px' }}>
                    <img className="imgpassosenvio" src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1613761774/$162h0du505d" alt="4" />
                    <span className="textopassosenvio">
                      Caso o patrocinador tenha interesse no projeto, ele indicará através da plataforma e entraremos em contato. 😉
                    </span>
                  </div>

                </div>
              </div>

              {/*A Simbiose Social é uma agência de captação de recursos? */}
              <div className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '350px', backgroundColor: '#f0f5f8' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                  <img style={{ width: '110px' }} src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1610315758/$pzjecxgpk7" alt="faqimg" />

                  <strong style={{ fontSize: '24px', marginTop: '40px' }}>
                    A Simbiose Social é uma agência de captação de recursos?
                  </strong>
                  <span className="textosection" style={{ color: '#333', textAlign: 'justify', padding: 0, marginTop: '30px', fontSize: '14px', marginTop: '20px' }}>
                    Atuamos em um formato diferente de agências tradicionais de captação de recursos. Dentro da nossa plataforma de <strong>Radar de Projetos</strong>, os patrocinadores buscam ativamente os projetos e têm uma visibilidade de todos os projetos que estão aptos a captar recursos no mercado, realmente podendo encontrar o que mais se encaixa ao perfil e pilares de atuação da empresa. A Simbiose atua com a tecnologia e inteligência de dados por trás desta busca toda que ajuda a otimizar os processos do mercado.
                  </span>
                </div>
              </div>

              {/*Se eu enviar meu projeto para vocês, vocês me ajudam a encontrar um patrocinador para ele já que possuem contato com diversas empresas? */}
              <div className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '300px', backgroundColor: '#f0f5f8' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                  <strong style={{ fontSize: '24px' }}>
                    Se eu enviar meu projeto para vocês, vocês me ajudam a encontrar um <br />patrocinador para ele já que possuem contato com diversas empresas?
                  </strong>

                  <span className="textosection" style={{ color: '#333', textAlign: 'justify', padding: 0, fontSize: '14px', marginTop: '20px' }}>

                    Se o seu projeto está aprovado em <strong>Lei de Incentivo</strong> e apto a captar recursos, ele já está disponível em nossa plataforma sendo visto por todos os nossos clientes. Para te auxiliar na busca por um patrocinador, temos o Radar de Patrocinadores, plataforma em que os proponentes buscam ativamente por patrocinadores que possuem um perfil de investimento que mais se assemelha ao projeto que estão buscando captação.
                  </span>
                </div>
              </div>

              {/* Posso colocar a apresentação e vídeos do meu projeto na plataforma de */}
              <div className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '300px', backgroundColor: '#f0f5f8' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                  <strong style={{ fontSize: '24px' }}>
                    Posso colocar a apresentação e vídeos do meu projeto na plataforma de <br />vocês para os clientes verem?
                  </strong>

                  <span className="textosection" style={{ color: '#333', textAlign: 'justify', padding: 0, fontSize: '14px', marginTop: '20px' }}>
                    Como prezamos pela democratização de acesso e queremos garantir que todos os projetos do país sejam vistos da mesma maneira em nossa plataforma, em um primeiro momento nossos clientes têm acesso a todas as informações públicas dos projetos (aquelas que foram inscritas nos ministérios, secretarias, etc). Caso algum deles se interesse pelo seu projeto, entraremos em contato e você poderá contar com suas próprias palavras sobre ele, o impacto que ele busca, enviar fotos, vídeos, apresentações, etc!
                  </span>
                </div>
              </div>

              {/*Crie conexões duradouras. Amplie o seu */}
              <div className="paginaContatoLandingSection" style={{ marginTop: '30px', minHeight: '300px', backgroundColor: '#fff' }}>
                <span style={{ fontSize: '32px', color: '#606a73', textAlign: 'center' }}>
                  Crie conexões duradouras. Amplie o seu<br /> impacto social.
                </span>
                <strong style={{ fontSize: '32px', color: '#606a73', textAlign: 'center' }}>
                  Inspire a transformação.
                </strong>

                <a href="https://simbiose.social/" className="botaofaçaparte">Faça Parte</a>
              </div>

            </div>
            :
            <div>
              <div className="paginaContatoLandingSection" style={{ marginTop: '20px', minHeight: '650px', backgroundColor: '#1f99a7' }}>
                <strong className="h1title" style={{ fontSize: '41px', marginBottom: '20px', backgroundColor: '#FFF', color: '#1f99a7' }}>
                  Agradecemos seu cadastro!
                </strong>

                <span className="textosection" style={{ fontSize: '20px' }}>
                  Fique ligado em seu e-mail, continuaremos nossa conversa por lá. 😉
                </span>
              </div>
              <div style={{ marginTop: '35px' }} className="paginaContatoLandingSection propagandaarea">
                <p style={{ color: "rgb(155, 155, 155)", fontFamily: 'Open Sans', fontSize: 25, fontWeight: 'bold' }} >Achamos que você pode se interessar</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '100px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                    <img src="https://d335luupugsy2.cloudfront.net/cms/files/226807/1614622068/$iib8vazjjd" alt="Panorama Leis de Incentivo para 2021" />
                    <a href="https://conteudo.simbiose.social/panorama-leis-de-incentivo-2021" style={{ textDecoration: 'none', color: '#000', cursor: 'pointer', backgroundColor: '#ffe338', borderRadius: '35px', height: '44px', lineHeight: '1.42857', fontSize: '19px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '200px' }}>
                      Baixe agora!
                    </a>
                  </div>

                  <p style={{ color: "rgb(3, 181, 192)", fontFamily: 'Open Sans', maxWidth: '480px', fontSize: '48px' }}>
                    Panorama
                    Leis de Incentivo 2021
                  </p>
                </div>
              </div>
            </div>
        }
      </div >
    )
  }
}

export default ContatoLanding