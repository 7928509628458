import React from 'react'
import { NavLink } from "react-router-dom";

    class Potencialize extends React.Component {

        constructor() {

            super()

            this.state = {



            }

        }

        render() {

            return (

                <div className="landingPotencializeContainer">

                    <div className="landingPotencializeConteudo">

                        <div className="landingPotencializeConteudoTitulo">

                            Gere impacto social positivo e contribua para a Agenda 2030 da ONU

                        </div>

                        <div className="landingPotencializeConteudoSubtitulo">

                            A Simbiose Social te ajuda a potencializar as transformações sociais em todo o Brasil

                        </div>

                        <div className="landingPotencializeConteudoAcoes">

                        <NavLink className="landingPotencializeConteudoAcoesBotao" to={{pathname: "/patrocinadores", search: `${this.props.location.search}`}}>

                            Soluções para empresa

                        </NavLink>

                        <NavLink className="landingPotencializeConteudoAcoesBotao" to={{pathname: "/plataforma-de-projetos", search: `${this.props.location.search}`}}>

                            Soluções para projetos sociais

                        </NavLink>

                        </div>

                    </div>

                </div>
                
                )

        }

    }

export default Potencialize;