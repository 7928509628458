import React from 'react'
import { Link } from "react-router-dom";
import Imagem1 from '../../images/plataformaProponente1.svg'
import Imagem2 from '../../images/plataformaProponente2.svg'
import Imagem3 from '../../images/plataformaProponente3.svg'
import Imagem4 from '../../images/plataformaProponente4.svg'
import PlanoBolinhaDesmarcada from '../../images/planoBolinhaDesmarcada.svg'
import PlanoBolinhaMarcada from '../../images/planoBolinhaMarcada.svg'

    class PlataformaProponentes extends React.Component {

        constructor(){

            super()

            this.state = {
                componenteAtivo: 'radar',
                imagem: Imagem1,
                titulo: 'Radar de patrocinadores',
                subtituloBold: '',
                subtitulo: 'Use a plataforma para buscar por empresas investidores que mais se aproximam do perfil do seu projeto de forma otimizada.',
            }

        }

        mudaComponenteAtivo = (componente) => {

            componente === 'radar' &&

            this.setState({
                componenteAtivo: componente,
                imagem: Imagem1,
                titulo: 'Radar de patrocinadores',
                subtituloBold: '',
                subtitulo: 'Use a plataforma para buscar por empresas investidores que mais se aproximam do perfil do seu projeto de forma otimizada.',
            })

            componente === 'cnpj' &&

            this.setState({
                componenteAtivo: componente,
                imagem: Imagem2,
                titulo: 'Busca por CNPJ',
                subtituloBold: '',
                subtitulo: 'Crie estratégias de captação de recursos com base em investimentos sociais anteriores das empresas.',
            })

            componente === 'relatorios' &&

            this.setState({
                componenteAtivo: componente,
                imagem: Imagem3,
                titulo: 'Relatórios de Investimento',
                subtituloBold: '',
                subtitulo: 'Obtenha relatórios das empresas investidores para facilitar a sua estratégia de captação de recursos.',
            })

            componente === 'atualizacoes' &&

            this.setState({
                componenteAtivo: componente,
                imagem: Imagem4,
                titulo: 'Novas atualizações',
                subtituloBold: '',
                subtitulo: 'Saiba em tempo real as empresas que investem em projetos parecidos com o seu.',
            })

        }

        render(){

            return (

                <div className="landingPlataformaProponentesContainer">

                    <div className="landingPlataformaProponentesHeaderContainer">

                        <div className="landingPlataformaProponentesHeader">

                            <div className="landingPlataformaProponentesHeaderIcone" 
                                style={{
                                    backgroundColor: this.state.componenteAtivo === 'radar' ? '#71CAD4' : '', 
                                    color: this.state.componenteAtivo === 'radar' ? '#FFF' : ''
                                }} 
                                onClick={() => this.mudaComponenteAtivo('radar')}>

                                RADAR DE PATROCINADORES

                            </div>

                            <div className="landingPlataformaProponentesHeaderIcone"
                                style={{
                                    backgroundColor: this.state.componenteAtivo === 'cnpj' ? '#71CAD4' : '', 
                                    color: this.state.componenteAtivo === 'cnpj' ? '#FFF' : ''
                                }} 
                                onClick={() => this.mudaComponenteAtivo('cnpj')}>

                                BUSCA POR CNPJ

                            </div>

                            <div className="landingPlataformaProponentesHeaderIcone"
                                style={{
                                    backgroundColor: this.state.componenteAtivo === 'relatorios' ? '#71CAD4' : '', 
                                    color: this.state.componenteAtivo === 'relatorios' ? '#FFF' : ''
                                }} 
                                onClick={() => this.mudaComponenteAtivo('relatorios')}>

                                RELATÓRIOS DE INVESTIMENTO

                            </div>

                            <div className="landingPlataformaProponentesHeaderIcone"
                                style={{
                                    backgroundColor: this.state.componenteAtivo === 'atualizacoes' ? '#71CAD4' : '', 
                                    color: this.state.componenteAtivo === 'atualizacoes' ? '#FFF' : ''
                                }} 
                                onClick={() => this.mudaComponenteAtivo('atualizacoes')}>

                                NOVAS ATUALIZAÇÕES

                            </div>

                        </div>

                    </div>

                    <div className="landingPlataformaProponentesHeaderIconeTriangulo" style={{
                        marginLeft: this.state.componenteAtivo === 'radar' ? '10%' 
                            : this.state.componenteAtivo === 'cnpj' ? '36%' 
                                : this.state.componenteAtivo === 'relatorios' ? '61%'
                                    : this.state.componenteAtivo === 'atualizacoes' ? '87%' : '10%'}} />

                    <div className="landingPlataformaProponentesConteudoContainerDesktop">

                        <div className="landingPlataformaProponentesConteudo">

                            <div className="landingPlataformaProponentesTextoPlataforma">

                                <div className="landingPlataformaProponentesTitulo">

                                    {this.state.titulo}

                                </div>

                                <div className="landingPlataformaProponentesBarra" />

                                <div className="landingPlataformaProponentesSubtituloBold">

                                    {this.state.subtituloBold}

                                </div>

                                <div className="landingPlataformaProponentesSubtitulo">

                                    {this.state.subtitulo}

                                </div>

                                <Link className="landingPlataformaProponentesBotao" to={{pathname: "plataforma-de-projetos", exibePrecoIniciar: true, search: this.props.location.search}}>
                                    
                                    Ver preços
                                    
                                </Link>

                            </div>

                            <div className="landingPlataformaProponentesTextoImagem">

                                <img className="imagemPreviewPlataforma" alt="imagemPlataformaProponente" src={this.state.imagem} />

                            </div>

                        </div>

                    </div>

                    {

                        /*


                            SEPARAÇÃO DIV DESKTOP E DIV MOBILE

                        
                        */

                    }

                    <div className="landingPlataformaProponentesConteudoContainerMobile">

                        <div className="landingPlataformaProponentesConteudoContainer">

                            <div className="landingPlataformaProponentesConteudo">

                                <div className="landingPlataformaProponentesTextoPlataforma">

                                    <div className="landingPlataformaProponentesTitulo">

                                        Radar de patrocinadores

                                    </div>

                                    <div className="landingPlataformaProponentesBarra" />

                                    <div className="landingPlataformaProponentesSubtituloBold">

                                        

                                    </div>

                                    <div className="landingPlataformaProponentesSubtitulo">

                                        Temos uma lista de 62 mil CNPJs que já investiram em leis de incentivo, nós te ajudamos a encontrar os que mais se aproximam do perfil do seu projeto.

                                    </div>

                                    <div className="landingPlataformaProponentesBotao">

                                        Ver preços

                                    </div>

                                </div>

                                <div className="landingPlataformaProponentesTextoImagem">

                                    <img className="imagemPreviewPlataforma" alt="imagemPlataformaProponente" src={this.state.imagem} />

                                </div>

                                <Link className="landingPlataformaProponentesTextoPlataformaBotaoMobile" to={{pathname: "plataforma-de-projetos", exibePrecoIniciar: true, search: this.props.location.search}}>
                                    
                                    Ver preços
                                    
                                </Link>

                            </div>

                        </div>

                    </div>

                    <div className="landingPlataformaProponentesBolinhasContainer">

                        <div className="landingPlataformaProponentesBolinhasConteudo">

                            <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.mudaComponenteAtivo('radar')} src={this.state.componenteAtivo === 'radar' ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                            <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.mudaComponenteAtivo('cnpj')} src={this.state.componenteAtivo === 'cnpj' ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                            <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.mudaComponenteAtivo('relatorios')} src={this.state.componenteAtivo === 'relatorios' ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                            <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.mudaComponenteAtivo('atualizacoes')} src={this.state.componenteAtivo === 'atualizacoes' ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                        </div>

                    </div>

                </div>

            )

        }

    }

export default PlataformaProponentes;