import React from 'react'
import Checkbox from '../../images/checkbox.svg'
import CheckboxChecked from '../../images/checkboxChecked.svg'
import {AXIOS} from '../../service/Base'

    class Contato extends React.Component {

        constructor() {

            super()

            this.state = {
                nome: '',
                nomeEmpresa: '',
                email: '',
                emailValidador: false,
                mensagem: '',
                privacidade: false,
                receberEmails: false,
                enviadoComSucesso: false,
                RadioId: 0,
                RadioOutro: '',
                loaderEnviandoMensagem: false,
            }

        }

        setaNome = (text) => {

            this.setState({
                nome: text.target.value
            })

        }

        setaNomeEmpresa = (text) => {

            this.setState({
                nomeEmpresa: text.target.value
            })

        }

        setaEmail = (text) => {

            this.setState({
                email: text.target.value
            })
    
            const usuario = text.target.value.substring(0, text.target.value.indexOf("@"));
            const dominio = text.target.value.substring(text.target.value.indexOf("@")+ 1, text.target.value.length);
             
            if ((usuario.length >=1) &&
                (dominio.length >=3) && 
                (usuario.search("@")===-1) && 
                (dominio.search("@")===-1) &&
                (usuario.search(" ")===-1) && 
                (dominio.search(" ")===-1) &&
                (dominio.search(".")!==-1) &&      
                (dominio.indexOf(".") >=1)&& 
                (dominio.lastIndexOf(".") < dominio.length - 1)) {
                this.setState({
                    emailValidador: true
                })
            }
            else{
                this.setState({
                    emailValidador: false
                })
            }

        }

        setaMensagem = (text) => {

            this.setState({
                mensagem: text.target.value
            })

        }

        aceitaPrivacidade = () => {

            this.setState({
                privacidade: !this.state.privacidade
            })

        }

        aceitaReceberEmails = () => {

            this.setState({
                receberEmails: !this.state.receberEmails
            })

        }

        enviarMensagem = () => {
    
            console.log('Envia mensagem')

            const represento = this.state.RadioId === 0 ? 'Empresa/ Patrocinador' : this.state.RadioId === 1 ? 'Agência' : this.state.RadioId === 2 ? this.state.RadioOutro : ''
    
            this.setState({
                loaderEnviandoMensagem: true
            })

            !this.state.emailValidador || !this.state.nome || !this.state.nomeEmpresa || !this.state.email || !this.state.mensagem || (this.state.RadioId === 2 && !this.state.RadioOutro) ?

                this.setState({
                    loaderEnviandoMensagem: false,
                    ErroEnviandoMensagem: true,
                })

            :
        
                AXIOS().post('contatosite', {
                    "st_nome": this.state.nome, // Obrigatório
                    "st_nome_empresa": this.state.nomeEmpresa, // Obrigatório
                    "st_email": this.state.email, // Obrigatório
                    "mensagem": this.state.mensagem,
                    "id_novidades": this.state.receberNovidades ? '1' : '',
                    'st_representa': represento
                })
                .then((response) => {
                    console.log(response)
                    this.setState({
                        loaderEnviandoMensagem: false,
                        ErroEnviandoMensagem: false,
                        enviadoComSucesso: true,
                    })                    
                    this.props.history.push(`${this.props.location.search ? `${this.props.location.search}&` : '?'}formularioEmpresaEnviado`)
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        loaderEnviandoMensagem: false,
                        ErroEnviandoMensagem: true,
                    })
                })
            
        }

        radioRepresenta = (id) => {

            this.setState({
                RadioId: id
            })

        }

        setaRadioOutro = (text) => {

            this.setState({
                RadioOutro: text.target.value
            })

        }

        render() {

            return (

                <div className="secaoContatoContainer" style={{height: this.state.RadioId === 2 ? '900px' : ''}}>
                    
                    <div className="secaoContatoConteudo">

                        <div className="secaoContatoTexto">

                            <div className="secaoContatoTitulo">

                                Entre em contato para contratar

                            </div>

                            <div className="secaoContatoSubtitulo">

                                Nosso serviço inclui um processo de setup para adaptação dos processos internos da sua empresa ao uso da plataforma. Então, recomendamos que antes da contratação, você fale com um simbiótico para que receba uma proposta ideal para a sua demanda. Entre em contato para saber mais sobre preços e planos!

                            </div>

                        </div>

                        <div className="secaoContatoFormulario">

                            <div className="secaoContatoFormularioContainer">

                                <div className="secaoContatoFormularioSuperior">

                                    <input className="inputCadastro" placeholder="Digite seu nome" style={{borderBottom: this.state.ErroEnviandoMensagem && !this.state.nome && '1px solid red'}} value={this.state.nome} onChange={(text) => this.setaNome(text)} />

                                    <input className="inputCadastro" placeholder="Nome da empresa" style={{borderBottom: this.state.ErroEnviandoMensagem && !this.state.nomeEmpresa && '1px solid red'}} value={this.state.nomeEmpresa} onChange={(text) => this.setaNomeEmpresa(text)} />

                                </div>

                                <div className="secaoContatoFormularioSegundo">

                                    <input className="inputCadastroSegundaLinha" placeholder="Digite seu email" style={{borderBottom: this.state.ErroEnviandoMensagem && (!this.state.emailValidador || !this.state.email) && '1px solid red'}} value={this.state.email} onChange={(text) => this.setaEmail(text)} />

                                    {

                                        !this.state.emailValidador && this.state.email.length > 0 &&

                                            <div className="secaoContatoEmailmensagemErro">

                                                Digite um email valido!

                                            </div>

                                    }

                                    <input className="inputCadastroSegundaLinha" placeholder="Gostaria de deixar uma mensagem?" style={{borderBottom: this.state.ErroEnviandoMensagem && !this.state.mensagem && '1px solid red'}} value={this.state.mensagem} onChange={(text) => this.setaMensagem(text)} /> 

                                </div>

                            </div>

                        </div>

                        {

                            this.state.enviadoComSucesso ?

                                <div className="secaoContatoFormularioEnviadoSucesso">

                                    <div className="secaoContatoFormularioEnviadoSucessoTitulo">

                                        Perfeito! Mensagem recebida

                                    </div>

                                    <div className="secaoContatoFormularioEnviadoSucessoSubtitulo">

                                        Nossa equipe irá entrar em contato o mais breve possível para um bate papo Simbiótico para explicar com mais detalhes sobre o serviço.

                                    </div>

                                </div>

                            :

                                <>

                                    <div className="secaoContatoRepresenta">
        
                                        <div className="secaoContatoRepresentaContainer">

                                            <div className="secaoContatoRepresentaLabel">

                                            </div>

                                            <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(0)} checked={this.state.RadioId === 0 ? true : false} /> 
                                                
                                                Você representa: patrocinador

                                            </div>

                                            <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(1)} checked={this.state.RadioId === 1 ? true : false} />

                                                Represento uma agência

                                            </div>

                                            <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(2)} checked={this.state.RadioId === 2 ? true : false} />

                                                Outro ?

                                            </div>

                                            {

                                                this.state.RadioId === 2 && 

                                                    <input className="inputsecaoContatoTermo" placeholder="Conte-nos qual..." style={{borderBottom: this.state.ErroEnviandoMensagem && !this.state.RadioOutro && '1px solid red'}} value={this.state.RadioOutro} onChange={(text) => this.setaRadioOutro(text)} /> 

                                            }

                                        </div>

                                    </div>

                                    {
                                        this.state.ErroEnviandoMensagem &&

                                            <div className="secaoContatoFormulariomensagemErro">

                                                Ops! Parece que algo deu errado. Tente novamente!

                                            </div>

                                    }

                                <div className="secaoContatoTermos" style={{marginTop: '10px'}}>
        
                                    <div className="secaoContatoTermosContainer">
        
                                        {/*<div className="secaoContatoTermo">
        
                                            <img alt="checkbox" className="secaoContatoTermoCheckbox" src={this.state.privacidade ? CheckboxChecked : Checkbox} onClick={this.aceitaPrivacidade} /> Concordo com a Política de privacidade e com os Termos de serviços da Simbiose Social. 
        
                                        </div>*/}
        
                                        <div className="secaoContatoTermo">
        
                                            <img alt="checkbox" className="secaoContatoTermoCheckbox" src={this.state.receberEmails ? CheckboxChecked : Checkbox} onClick={this.aceitaReceberEmails} /> Aceito receber novidades da Simbiose Social.
        
                                        </div>
        
                                    </div>
        
                                </div>

                                <div className="secaoContatoBotaoContainer">

                                    <div className="secaoContatoBotao" onClick={() => this.enviarMensagem()} style={{pointerEvents: this.state.loaderEnviandoMensagem ? 'none' : 'auto'}}>

                                        {this.state.loaderEnviandoMensagem ? 'Enviando mensagem...' : 'Falar com um simbiótico'}

                                    </div>

                                </div>

                                </>

                        }

                    </div>
                    
                </div>

            )

        }

    }

export default Contato;