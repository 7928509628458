import React from 'react'

    class SobreNos extends React.Component {

        constructor() {

            super()

            this.state = {



            }

        }

        render() {

            return (

                <div>

                    SOBRE NÓS

                </div>

            )

        }

    }

export default SobreNos;