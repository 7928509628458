import React from 'react'
//import FotoDepoimento1 from '../../images/fotoDepoimento1.png'
import FotoDepoimentoAlessandro from '../../images/alessandro-vay.jpg'
import AnaFuncaoLivro from '../../images/anafundacaolivro.jpg'
import SuelmaRosa from '../../images/SuelmaRosaUnilever.png'
//import IconeDepoimento from '../../images/iconeDepoimento.png'
import ProximoDepoimentoIcone from '../../images/proximoDepoimento.svg'
import PlanoBolinhaDesmarcada from '../../images/planoBolinhaDesmarcada.svg'
import PlanoBolinhaMarcada from '../../images/planoBolinhaMarcada.svg'


    class Depoimentos extends React.Component {

        constructor() {

            super()

            this.state = {
                depoimentoAtivo: 0,
                depoimentos:

                    [
                        {
                            'nome': 'Suelma Rosa',
                            'cargo': 'Head de Reputação e Assuntos Corporativos do Brasil e da América Latina na Unilever',
                            'mensagem': 'A nova plataforma da Simbiose Social é um grande avanço. Trará inteligência de dados para a tomada de decisão em projetos que realmente contribuem para a melhoria do bem-estar da sociedade, com base em dados oficiais, do IBGE, IDH e outras fontes que validam os projetos como mais necessários para as regiões.',
                            'foto': SuelmaRosa
                        },
                        {
                            'nome': 'Alessandro Vay',
                            'cargo': 'Cushman & Wakefield',
                            'mensagem': 'A Simbiose Social desempenha um papel muito mais importante do que uma simples plataforma, são agentes relevantes na cadeia do impacto social',
                            'foto': FotoDepoimentoAlessandro
                        },
                        {
                            'nome': 'Ana Luz Mira',
                            'cargo': 'Fundação do Livro e Leitura de Ribeirão Preto',
                            'mensagem': 'O Radar de patrocinadores virou meu meio principal de buscas de empresas e facilita muito minha organização. Antes de adquirir a plataforma eu ficava vendo listas de empresas que investem em Ribeirão. Assim que assinamos a plataforma, ela já virou meu meio principal de buscas de empresas. Eu ampliei minhas buscas e o relatório que a Simbiose Social oferece me foi muito útil.',
                            'foto': AnaFuncaoLivro
                        },
                    ]                                
                            
            }

        }

        próximoDepoimento = () => {

            this.state.depoimentoAtivo === this.state.depoimentos.length-1 ?

                this.setState({
                    depoimentoAtivo: 0,
                })

            :

                this.setState({
                    depoimentoAtivo: this.state.depoimentoAtivo + 1
                })

        }

        setaDepoimento = (depoimentoId) => {

            this.setState({
                depoimentoAtivo: depoimentoId
            })

        }

        render() {

            return (

                <>

                <div className="landingDepoimentosDepoimentoContainer">

                    {

                        this.state.depoimentos.map((depoimento, index) => {

                            return (

                                this.state.depoimentoAtivo === index ?

                                    <div key={index} className="landingDepoimentosDepoimento">
                
                                        <div className="landingDepoimentosDepoimentoHeader">
                
                                            <div className="landingDepoimentosDepoimentoHeaderImagem">
                
                                                <img alt="fotoDepoimento" className="fotoDepoimentoTag" src={depoimento.foto} />
                
                                            </div>
                
                                            <div className="landingDepoimentosDepoimentoHeaderInfo">
                
                                                <div className="landingDepoimentosDepoimentoHeaderNome">
                
                                                    {depoimento.nome}
                
                                                </div>
                
                                                <div className="landingDepoimentosDepoimentoHeaderLocal">
                
                                                    {depoimento.cargo}
                
                                                </div>
                
                                            </div>

                                            {/*
                    
                                                <div className="landingDepoimentosDepoimentoHeaderIcone">
                    
                                                    <img alt="iconeDepoimento" src={IconeDepoimento} />
                    
                                                </div>

                                            */}
                
                                        </div>
                
                                        <div className="landingDepoimentosDepoimentoMensagemContainer">
                
                                            <div className="landingDepoimentosDepoimentoMensagem">
                
                                                “{depoimento.mensagem}”
                
                                            </div>
                
                                        </div>
                
                                    </div>

                                :

                                    ''

                            )

                        })

                    }

                    <div className="landingDepoimentosDepoimentoProximoDepoimento">

                        {

                            this.state.depoimentos.length > 1 &&
                            
                                <img className="proximoDepoimento" alt="proximoDepoimento" src={ProximoDepoimentoIcone} onClick={() => this.próximoDepoimento()} />
                                                                

                        }

                    </div>

                </div>

                <div className="landingPlataformaProponentesBolinhasContainerDepoimentos">

                    <div className="landingPlataformaProponentesBolinhasConteudo">

                        <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.setaDepoimento(0)} src={this.state.depoimentoAtivo === 0 ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                        <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.setaDepoimento(1)} src={this.state.depoimentoAtivo === 1 ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                        <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.setaDepoimento(2)} src={this.state.depoimentoAtivo === 2 ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                        {/*

                            <div className="landingPlataformaProponentesBolinhas"><img alt="bolinhaSecao" onClick={() => this.setaDepoimento(2)} src={this.state.depoimentoAtivo === 2 ? PlanoBolinhaMarcada : PlanoBolinhaDesmarcada} /></div>

                        */}

                    </div>

                </div>

                </>

            )

        }

    }

export default Depoimentos;