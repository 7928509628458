import React from 'react'
import Footer from "../Footer"
import Potencialize from "../Potencialize"
import { AXIOS } from '../../service/Base'

class Contato extends React.Component {

    constructor() {

        super()

        this.state = {
            email: '',
            nome: '',
            vocerepresenta: '',
            organização: '',
            mensagem: '',
            loaderEnviandoContato: false,
            ErroEnviandoContato: false,
            enviadoComSucesso: false,
            assunto: '',
            emailValidador: false
        }

    }

    componentWillMount() {
        window.location.replace('https://simbi.social/')
        window.scrollTo(0, 0)

    }

    componentDidMount() {

    }

    enviaContato = (e) => {

        e.preventDefault()

        this.setState({
            loaderEnviandoContato: true
        })

        if (!this.state.email || !this.state.mensagem || !this.state.nome || !this.state.assunto || this.state.vocerepresenta == "") {

            console.log('incompleto')

        } else {

            AXIOS().post('contatolanding', {
                'st_mensagem': this.state.mensagem,
                'st_assunto': this.state.assunto,
                'vocerepresenta': this.state.vocerepresenta,
                'organização': this.state.organização,
                'email': this.state.email,
                'st_nome': this.state.nome
            })
                .then((response) => {
                    console.log(response)
                    this.setState({
                        loaderEnviandoContato: false,
                        ErroEnviandoContato: false,
                        enviadoComSucesso: true,
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        loaderEnviandoContato: false,
                        ErroEnviandoContato: true,
                    })
                })

        }

    }

    handleChange = (e) => {

        const value = e.target.value
        const input = e.target.name

        if (input === 'email') {

            this.setState({
                [input]: value
            })

            const usuario = e.target.value.substring(0, e.target.value.indexOf("@"));
            const dominio = e.target.value.substring(e.target.value.indexOf("@") + 1, e.target.value.length);

            if ((usuario.length >= 1) &&
                (dominio.length >= 3) &&
                (usuario.search("@") === -1) &&
                (dominio.search("@") === -1) &&
                (usuario.search(" ") === -1) &&
                (dominio.search(" ") === -1) &&
                (dominio.search(".") !== -1) &&
                (dominio.indexOf(".") >= 1) &&
                (dominio.lastIndexOf(".") < dominio.length - 1)) {
                this.setState({
                    emailValidador: true
                })
            }
            else {
                this.setState({
                    emailValidador: false
                })
            }

        } else {

            this.setState({
                [input]: value
            })

        }

    }

    render() {

        const libera = this.state.emailValidador && this.state.email && this.state.nome && this.state.vocerepresenta && this.state.organização&&  this.state.assunto && this.state.mensagem && !this.state.loaderEnviandoContato

        return (

            <div>

                <div className="paginaContatoSection">

                    <div className="paginaContatoContainer">

                        <div className="paginaContatoContent">

                            <h2 className="paginaContatoTitle">

                                Entre em contato com nosso time simbiótico!

                            </h2>

                            <form className="paginaContatoForm" onSubmit={(e) => this.enviaContato(e)}>


                                <input className="paginaContatoFormInput" placeholder="digite aqui o seu nome..." type="text" name="nome" value={this.state.nome} onChange={(e) => this.handleChange(e)} />

                                <select className="paginaContatoFormSelect" required type="text" name="vocerepresenta" value={this.state.vocerepresenta} onChange={(e) => this.handleChange(e)} >
                                    <option style={{ fontSize: 16 }} value="" label="Você Representa"></option>
                                    <option style={{ fontSize: 16 }} value="Proponente" label="Proponente"></option>
                                    <option style={{ fontSize: 16 }} value="Patrocinador" label="Patrocinador"></option>
                                    <option style={{ fontSize: 16 }} value="Agência" label="Agência"></option>
                                </select>

                                <input className="paginaContatoFormInput" style={{ borderBottom: this.state.emailValidador ? '' : '1px solid red' }} placeholder="digite aqui o seu email..." type="text" name="email" value={this.state.email} onChange={(e) => this.handleChange(e)} />

                                {

                                    !this.state.emailValidador &&

                                    <div className="paginaContatoFormInputDisclaimer">

                                        Digite um email válido!

                                    </div>

                                }
                                <input className="paginaContatoFormInput" placeholder="digite aqui o nome da Organização..." type="text" name="organização" value={this.state.organização} onChange={(e) => this.handleChange(e)} />

                                <input className="paginaContatoFormInput" placeholder="digite aqui o assunto..." type="text" name="assunto" value={this.state.assunto} onChange={(e) => this.handleChange(e)} />

                                <input className="paginaContatoFormInput" placeholder="digita aqui a sua mensagem..." type="text" name="mensagem" value={this.state.mensagem} onChange={(e) => this.handleChange(e)} />

                                {

                                    this.state.enviadoComSucesso ?

                                        <div className="paginaContatoEnviadoComSucesso">

                                            <span className="paginaContatoEnviadoComSucessoTitle">Enviado com sucesso!</span>

                                            Nosso time entrará em contato com você o mais breve possível!

                                        </div>

                                        :

                                        <input className="paginaContatoFormButton" style={{
                                            color: libera ? '' : 'rgb(128, 128, 128)',
                                            backgroundColor: libera ? '' : 'rgb(235, 235, 235)',
                                            pointerEvents: libera ? 'auto' : 'none'
                                        }} type="submit" value={this.state.loaderEnviandoContato ? 'Enviando...' : "Enviar"} />

                                }

                            </form>

                            {

                                this.state.ErroEnviandoContato &&

                                <div className="paginaContatoErro">

                                    Ocorreu um erro durante o envio do formulário...

                                </div>

                            }

                        </div>

                    </div>

                </div>

                <Potencialize {...this.props} />

                <Footer />

            </div>

        )

    }

}

export default Contato