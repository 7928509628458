import React from 'react';
import './Landing.css';
import './components/styling/Proponentes.css';
import './components/styling/Cadastro.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PageNotFound from "./components/PageNotFound"
import Landing from "./components/Landing"
import Navbar from "./components/Navbar"
import Proponentes from "./components/pages/Proponentes"
import Empresas from "./components/pages/Empresas"
import SobreNos from "./components/pages/SobreNos"
import MenuMobile from './components/actioncomponents/MenuMobile'
import Cadastro from "./components/pages/Cadastro"
import Agendamento from "./components/pages/Agendamento"
import Reagendamento from "./components/pages/Reagendamento"
import Simbinet from "./components/pages/Simbinet"
import Contato from "./components/pages/Contato"
import Login from "./components/pages/Login"
import CadastroConfirmacao from "./components/pages/CadastroConfirmacao"
import PopUpVideoApresentacao from "./components/popups/PopUpVideoApresentacao"
import BoxWebnair from "./components/popups/BoxWebnair"
import PopUpContratar from "./components/popups/PopUpContratar"
import Sair from './images/sair.svg'
import SairContratar from './images/sairContratar.svg'
import Pagamento from './components/pages/PopUpPagamento'
import PrivacyPolicy from './components/pages/PrivacyPolicy'
import ContatoLanding from './components/pages/ContatoLanding';
import ContatoLandingTS from './components/pages/ContatoLandingTS';
import { BlipChat } from "blip-chat-widget";
import PoliticaPrivacidadeAnalise from './components/pages/components/PoliticaseTermos/PoliticaPrivacidadeAnalise';
new BlipChat()
  .withAppKey("c2ltYmlvc2VwcmluY2lwYWw6OTcyZDlkMDUtYzc1Zi00ZTdjLTgxMTAtMWVlZmI3Mzc2ZmVm")
  .withButton({
    color: "#2CC3D5",
  })
  .build();
class App extends React.Component {

  constructor() {

    super()

    this.state = {
      menuMobileAtivo: false,
      ativaPopUpVideo: false,
      ativaPopUpContratar: false,
      ativaBoxWebnar: false,
    }

  }

  componentDidMount() {

    if (window.location.href.indexOf('/agendamento') > -1 || window.location.href.indexOf('/cadastro') > -1) {

    } else {

      /*setTimeout(
        function() {*/
      this.setState({ ativaBoxWebnar: true });
      /*}
      .bind(this),
      3000
    );*/

    }


  }

  /* opção 1

  componentDidMount() {

    window.addEventListener("beforeunload", (ev) =>    {

      this.setState({
        ativaPopUpVideo: !this.state.ativaPopUpVideo
      })

      ev.preventDefault();

      return 'Are you sure you want to close?';
    })

  }

  opção 2

  componentDidMount() {
    window.addEventListener('beforeunload', this.keepOnPage);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.keepOnPage);
  }

  keepOnPage(e) {
    console.log('inicia')
    var message = 'Warning!\n\nNavigating away from this page will delete your text if you haven\'t already saved it.';
    e.returnValue = message;
    return message;
  }

  */

  ativaMenuMobile = () => {

    this.setState({
      menuMobileAtivo: !this.state.menuMobileAtivo
    })

  }

  fechaMenuMobile = () => {

    this.setState({
      menuMobileAtivo: false
    })

  }

  ativaPopUpVideo = () => {

    this.setState({
      ativaPopUpVideo: !this.state.ativaPopUpVideo
    })

  }

  fechaPopUpVideo = () => {

    this.setState({
      ativaPopUpVideo: false
    })

  }

  ativaPopUpContratar = () => {

    this.setState({
      ativaPopUpContratar: !this.state.ativaPopUpContratar
    })

  }

  fechaPopUpContratar = () => {

    this.setState({
      ativaPopUpContratar: false
    })

  }

  fechaBoxWebnar = () => {

    this.setState({
      ativaBoxWebnar: false
    })

  }

  render() {

    return (

      <div className="App">

        {

          this.state.ativaPopUpVideo &&

          <PopUpVideoApresentacao
            fechaPopUpVideo={this.fechaPopUpVideo}
            sair={Sair}
          />

        }

        <BrowserRouter basename={process.env.PUBLIC_URL}>

          {

            !this.state.ativaPopUpVideo && !this.state.ativaPopUpContratar && this.state.ativaBoxWebnar &&

            <BoxWebnair
              fechaBoxWebnar={this.fechaBoxWebnar}
              sair={Sair}
            />

          }

          <PopUpContratar
            ativaPopUpContratar={this.state.ativaPopUpContratar}
            fechaPopUpContratar={this.fechaPopUpContratar}
            sair={SairContratar}
          />



          <div className="containerLanding" style={{
            filter: this.state.ativaPopUpVideo ? 'blur(4px) grayscale(1)' : this.state.ativaPopUpContratar ? 'brightness(0.5)' : '',
            pointerEvents: this.state.ativaPopUpVideo || this.state.ativaPopUpContratar ? 'none' : 'auto',
            overflowY: this.state.ativaPopUpVideo || this.state.ativaPopUpContratar ? 'hidden' : ''
          }}>

            <Navbar
              ativaMenuMobile={this.ativaMenuMobile}
              menuMobileAtivo={this.state.menuMobileAtivo}
              fechaMenuMobile={this.fechaMenuMobile}
              ativaPopUpContratar={this.ativaPopUpContratar}
            />

            <MenuMobile
              ativaMenuMobile={this.state.menuMobileAtivo}
              ativaMenuMobileFuncao={this.ativaMenuMobile}
            />

            <Switch>
              <Route path="/" render={(props) => <Landing {...props} ativaPopUpVideo={this.ativaPopUpVideo} />} exact />
              <Route path="/plataforma-de-projetos" component={Proponentes} />
              <Route path="/patrocinadores" component={Empresas} />
              <Route path="/sobre-nos" component={SobreNos} />
              <Route path="/cadastro" component={Cadastro} />
              <Route path="/cadastro-confirma" component={CadastroConfirmacao} />
              <Route path="/agendamento" component={Agendamento} />
              <Route path="/contato" component={Contato} />
              <Route path="/simbinet" component={Simbinet} />
              <Route path="/login" component={Login} />
              <Route path="/reagendamento" component={Reagendamento} />
              <Route path="/pagamento" component={Pagamento} />
              <Route exact path="/politica-de-privacidade" component={PrivacyPolicy} />
              <Route exact path="/politica-de-privacidade-analise" component={PoliticaPrivacidadeAnalise} />
              <Route exact path="/politica" component={PrivacyPolicy} />
              <Route exact path="/politica-de-privacidade/:politica" component={PrivacyPolicy} />
              <Route path="/formcontato/:id_cliente" component={ContatoLanding} />
              <Route path="/form/:id_cliente/formulario/:id_formulario" component={ContatoLandingTS} />
              <Route component={PageNotFound} />

            </Switch>
          </div>
        </BrowserRouter>
      </div>

    )

  }
}

export default App;
