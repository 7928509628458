import React from 'react'
import { Link } from "react-router-dom";
import PlusIcon from '../../images/plusIcon.svg';
import WhitePlusIcon from '../../images/plusIconWhite.svg';

class PlanosProponente extends React.Component {

    constructor() {

        super()

        this.state = {
            plano: 1,
            linkAcao: 'https://sandbox-app.vindi.com.br/customer/pages/1fea8e20-e2dd-4780-b9d1-137870274421/subscriptions/new',
            cardExpandir: '',
        }

        /*

        1 usuario anual https://app.vindi.com.br/customer/pages/204ba81b-412b-4ed9-8b47-f9c460d87dc8/subscriptions/
        3 usuarios anual https://app.vindi.com.br/customer/pages/6ecbe97e-4e53-45b3-966c-968c552e44f7/subscriptions/new
        5 usuarios anual https://app.vindi.com.br/customer/pages/390702ae-0258-4e53-9d5f-9549a2f1eb25/subscriptions/new

        1 usuario semestral https://app.vindi.com.br/customer/pages/d1e18907-8011-4cf4-9773-2b551a6e5221/subscriptions/new
        3 usuarios semestral https://app.vindi.com.br/customer/pages/c1400904-57fb-4580-8e9a-bb9c86131640/subscriptions/new
        5 usuarios semestral https://app.vindi.com.br/customer/pages/8dd43ce7-06b2-44f4-b9f3-221d6427cdcf/subscriptions/new

        1 usuario mensal https://app.vindi.com.br/customer/pages/97b6626e-ae8c-4f4d-9390-d013ee933865/subscriptions/new
        3 usuarios mensal https://app.vindi.com.br/customer/pages/93931246-2b19-4372-9831-0bab3cb9049b/subscriptions/new
        5 usuarios mensal https://app.vindi.com.br/customer/pages/9632eaa4-62a0-42cf-b43e-77bf0281c142/subscriptions/new

        */

    }

    alteraPlano = (plano) => {

        const link = plano === 1 ?
            'https://sandbox-app.vindi.com.br/customer/pages/1fea8e20-e2dd-4780-b9d1-137870274421/subscriptions/new'
            : plano === 2 ?
                'https://sandbox-app.vindi.com.br/customer/pages/1affb88a-1ce1-46bb-a56c-4d5540999e3f/subscriptions/new'
                :
                'https://sandbox-app.vindi.com.br/customer/pages/39577ac5-27d7-44e1-be5b-3648f1a17aa6/subscriptions/new'

        this.setState({
            plano: plano,
            linkAcao: link
        })

    }

    expandeCardPlano = (idCard) => {

        idCard === this.state.cardExpandir ?

            this.setState({
                cardExpandir: ''
            })

            :

            this.setState({
                cardExpandir: idCard
            })

    }

    render() {

        return (

            <div>

                <div className="paginaPropoentesPlanosProponenteDesktop">

                    <div className="paginaPropoentesPlanosProponenteContainer">

                        <div className="paginaPropoentesPlanosProponenteTexto">

                            Escolha o plano alinhado a você

                            </div>

                        <div className="paginaPropoentesPlanosProponentePlanosHeader">

                            <div className="paginaPropoentesPlanosProponentePlano"
                                onClick={() => this.alteraPlano(1)}
                                style={{
                                    backgroundColor: this.state.plano === 1 ? '#1F99A7' : '',
                                    color: this.state.plano === 1 ? '#FFF' : ''
                                }}>

                                <span className="paginaPropoentesPlanosTextoBold">1</span> USUÁRIO

                                </div>

                            <div className="paginaPropoentesPlanosProponentePlano"
                                onClick={() => this.alteraPlano(2)}
                                style={{
                                    backgroundColor: this.state.plano === 2 ? '#1F99A7' : '',
                                    color: this.state.plano === 2 ? '#FFF' : ''
                                }}>

                                <span className="paginaPropoentesPlanosTextoBold">3</span> USUÁRIOS

                                </div>

                            <div className="paginaPropoentesPlanosProponentePlano"
                                onClick={() => this.alteraPlano(3)}
                                style={{
                                    backgroundColor: this.state.plano === 3 ? '#1F99A7' : '',
                                    color: this.state.plano === 3 ? '#FFF' : ''
                                }}>

                                <span className="paginaPropoentesPlanosTextoBold">5</span> USUÁRIOS

                                </div>

                        </div>


                        <div className="paginaPropoentesPlanosProponenteCards">
                            {
                                /*<div className="paginaPropoentesPlanosProponenteCard" style={{
                                display: this.state.cardExpandir === 1 && 'block',
                                height: this.state.cardExpandir === 1 && '313px',
                            }}>


                                <div className="paginaProponentesPlanosHeaderInfo">

                                    <div className="paginaPropoentesPlanosProponenteEconomize" style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        backgroundColor: 'inherit',
                                        marginBottom: '10px',
                                        borderRadius: '6px',
                                        height: '30px',
                                        fontweight: 'bold',
                                        color: 'rgb(31, 153, 167)',
                                    }}>

                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardPeriodicidade">

                                        APRESENTAÇÃO DA PLATAFORMA

                                        </div>

                                    <div className="paginaProponentesPlanosPropoenteExpandirIcone" onClick={() => this.expandeCardPlano(1)}>

                                        <img className="expandirIconTag" alt="expandirIcon" src={PlusIcon} style={{
                                            transform: this.state.cardExpandir === 1 && 'rotate(45deg)'
                                        }} />

                                    </div>

                                </div>

                                <div className="barraDivideCardProponentePlanos" />

                                <div className="paginaPropoentesPlanosProponenteCardPreco">

                                    <div className="paginaPropoentesPlanosProponenteCardValor">

                                        Grátis

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorDescricao">



                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorTotal">



                                    </div>

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudo" style={{
                                    display: this.state.cardExpandir === 1 && 'block',
                                    height: this.state.cardExpandir === 1 && '110px'
                                }}>

                                    Escolha o melhor dia para você e participe do tutorial com a nossa equipe Simbiótica!

                                    </div>

                                <div className="paginaPropoentesPlanosProponenteCardFooter">

                                    <div className="paginaPropoentesPlanosProponenteCardFooterConteudo">

                                        <Link className="paginaPropoentesPlanosProponenteCardFooterBotao" to={{ pathname: "/cadastro", search: `${this.props.search}`, stPlano: 'Gratis', planoSelecionadoProponente: this.state.plano, card: 'Gratis', linkPagamento: this.state.linkAcao, cardClicado: 'Gratis' }}>

                                            Realizar cadastro

                                        </Link>

                                        <div className="paginaPropoentesPlanosProponenteCardFooterMensagem">Ao selecionar o plano, você aceita os <a target="_blank" href="https://simbiose.social/politica-de-privacidade" style={{ color: '#606A73', textDecoration: 'underline' }}>Termos e Condições</a> e o
                                            <a target="_blank" href={this.state.plano === 1 ? 'https://simbiose.social/politica-de-privacidade' :
                                                this.state.plano === 2 ? 'https://simbiose.social/politica-de-privacidade' : 'https://simbiose.social/politica-de-privacidade'} style={{ color: '#606A73', textDecoration: 'underline' }}>Termo de Adesão</a>

                                        </div>

                                    </div>

                                </div>

                            </div>
*/
                            }


                            <div className="paginaPropoentesPlanosProponenteCard" style={{
                                backgroundColor: '#1F99A7',
                                color: '#FFF',
                                display: this.state.cardExpandir === 2 && 'block',
                                height: this.state.cardExpandir === 2 && '313px',
                            }}>

                                <div className="paginaPropoentesPlanosProponenteEconomizeMobile" style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    backgroundColor: 'rgb(238 240 243)',
                                    marginBottom: '10px',
                                    borderRadius: '6px',
                                    height: '30px',
                                    fontweight: 'bold',
                                    color: 'rgb(31, 153, 167)',
                                }}>

                                    {

                                        this.state.plano === 1 ? 'Economize 50%' :
                                            this.state.plano === 2 ? 'Economize 60%' : 'Economize 65%'

                                    }

                                </div>

                                <div className="paginaProponentesPlanosHeaderInfo">

                                    <div className="paginaPropoentesPlanosProponenteEconomize" style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        backgroundColor: 'rgb(238 240 243)',
                                        marginBottom: '10px',
                                        borderRadius: '6px',
                                        height: '30px',
                                        fontweight: 'bold',
                                        color: 'rgb(31, 153, 167)',
                                    }}>

                                        {

                                            this.state.plano === 1 ? 'Economize 50%' :
                                                this.state.plano === 2 ? 'Economize 60%' : 'Economize 65%'

                                        }

                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardPeriodicidade" style={{
                                        color: '#FFF'
                                    }}>

                                        ASSINATURA ANUAL

                                        </div>

                                    <div className="paginaProponentesPlanosPropoenteExpandirIcone" onClick={() => this.expandeCardPlano(2)}>

                                        <img className="expandirIconTag" alt="expandirIcon" src={WhitePlusIcon} style={{
                                            transform: this.state.cardExpandir === 2 && 'rotate(45deg)'
                                        }} />

                                    </div>

                                </div>

                                <div className="barraDivideCardProponentePlanos" />

                                <div className="paginaPropoentesPlanosProponenteCardPreco">



                                    <div className="paginaPropoentesPlanosProponenteCardValor" style={{
                                        color: '#FFF'
                                    }}>

                                        <span className="paginaPropoentesPlanosProponenteCardPrecoRS">R$</span>

                                        {

                                            this.state.plano === 1 ? '99,00' :
                                                this.state.plano === 2 ? '79,00' : '69,00'

                                        }

                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorDescricao" style={{
                                        color: '#FFF'
                                    }}>

                                        mensais por usuário

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorTotal" style={{
                                        color: '#FFF'
                                    }}>

                                        {

                                            this.state.plano === 1 ? '12x de R$99,00' :
                                                this.state.plano === 2 ? '12x de R$237,00' : '12x de R$354,00'

                                        }

                                    </div>

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudo" style={{
                                    color: '#FFF',
                                    height: this.state.cardExpandir === 2 && '110px',
                                    display: this.state.cardExpandir === 2 && 'block',
                                }}>

                                    {/*

                                        <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                            {

                                                this.state.plano === 1 ? 'Desconto de 50%' :
                                                    this.state.plano === 2 ? 'Desconto de 60%' : 'Desconto de 65%'

                                            }

                                        </div>

                                        */}

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Mais de 62 mil patrocinadores

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Dados consolidados em relatórios

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Dicas de aporte

                                        </div>

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardFooter">

                                    <div className="paginaPropoentesPlanosProponenteCardFooterConteudo">

                                        {/*

                                            <a className="paginaPropoentesPlanosProponenteCardFooterBotao" href={this.state.linkAcao} style={{
                                                backgroundColor: '#FFF',
                                                color: '#71CAD4'
                                            }}>

                                                Realizar cadastro

                                            </a>

                                            */}

                                        <Link className="paginaPropoentesPlanosProponenteCardFooterBotao" style={{
                                            backgroundColor: '#FFF',
                                            color: '#71CAD4'
                                        }} to={{
                                            pathname: "/cadastro",
                                            planoSelecionadoProponente: this.state.plano,
                                            stPlano: 'Anual', card: 2,
                                            search: `${this.props.search}`,

                                            linkPagamento:

                                                this.state.plano === 1 ?
                                                    'https://app.vindi.com.br/customer/pages/204ba81b-412b-4ed9-8b47-f9c460d87dc8/subscriptions/'
                                                    :
                                                    this.state.plano === 2 ?
                                                        'https://app.vindi.com.br/customer/pages/6ecbe97e-4e53-45b3-966c-968c552e44f7/subscriptions/new'
                                                        :
                                                        this.state.plano === 3 ?
                                                            'https://app.vindi.com.br/customer/pages/390702ae-0258-4e53-9d5f-9549a2f1eb25/subscriptions/new'
                                                            :
                                                            'teste',

                                            cardClicado: 'Anual'
                                        }}>

                                            Realizar cadastro

                                            </Link>

                                        <div className="paginaPropoentesPlanosProponenteCardFooterMensagem" style={{
                                            color: '#FFF'
                                        }}>

                                            <div style={{ color: '#FFF' }} className="paginaPropoentesPlanosProponenteCardFooterMensagem">Ao selecionar o plano, você aceita os <a target="_blank" href="https://simbiose.social/politica-de-privacidade" style={{ color: '#FFF', textDecoration: 'underline' }}>Termos e Condições</a> e o
                                            <a target="_blank" href={this.state.plano === 1 ? 'https://simbiose.social/politica-de-privacidade' :
                                                    this.state.plano === 2 ? 'https://simbiose.social/politica-de-privacidade' : 'https://simbiose.social/politica-de-privacidade'} style={{ color: '#FFF', textDecoration: 'underline' }}>Termo de Adesão</a>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="paginaPropoentesPlanosProponenteCard" style={{
                                display: this.state.cardExpandir === 3 && 'block',
                                height: this.state.cardExpandir === 3 && '313px',
                            }}>

                                <div className="paginaPropoentesPlanosProponenteEconomizeMobile" style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    backgroundColor: 'rgb(238 240 243)',
                                    marginBottom: '10px',
                                    borderRadius: '6px',
                                    height: '30px',
                                    fontweight: 'bold',
                                    color: 'black',
                                }}>

                                    {

                                        this.state.plano === 1 ? 'Economize 30%' :
                                            this.state.plano === 2 ? 'Economize 45%' : 'Economize 50%'

                                    }

                                </div>

                                <div className="paginaProponentesPlanosHeaderInfo">

                                    <div className="paginaPropoentesPlanosProponenteEconomize" style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        backgroundColor: 'rgb(238 240 243)',
                                        marginBottom: '10px',
                                        borderRadius: '6px',
                                        height: '30px',
                                        fontweight: 'bold',
                                        color: 'black',
                                    }}>

                                        {

                                            this.state.plano === 1 ? 'Economize 30%' :
                                                this.state.plano === 2 ? 'Economize 45%' : 'Economize 50%'

                                        }

                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardPeriodicidade">

                                        ASSINATURA SEMESTRAL

                                        </div>

                                    <div className="paginaProponentesPlanosPropoenteExpandirIcone" onClick={() => this.expandeCardPlano(3)}>

                                        <img className="expandirIconTag" alt="expandirIcon" src={PlusIcon} style={{
                                            transform: this.state.cardExpandir === 3 && 'rotate(45deg)'
                                        }} />

                                    </div>

                                </div>

                                <div className="barraDivideCardProponentePlanos" />

                                <div className="paginaPropoentesPlanosProponenteCardPreco">

                                    <div className="paginaPropoentesPlanosProponenteCardValor">

                                        <span className="paginaPropoentesPlanosProponenteCardPrecoRS">R$</span>

                                        {

                                            this.state.plano === 1 ? '139,00' :
                                                this.state.plano === 2 ? '109,00' : '99,00'

                                        }

                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorDescricao">

                                        mensais por usuário

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorTotal">

                                        {

                                            this.state.plano === 1 ? '6x de R$139,00' :
                                                this.state.plano === 2 ? '6x de R$327,00' : '6x de R$495,00'

                                        }

                                    </div>

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudo" style={{
                                    display: this.state.cardExpandir === 3 && 'block',
                                    height: this.state.cardExpandir === 3 && '110px'
                                }}>

                                    {/*

                                            <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                                {

                                                    this.state.plano === 1 ? 'Desconto de 30%' :
                                                        this.state.plano === 2 ? 'Desconto de 45%' : 'Desconto de 50%'

                                                }

                                            </div>

                                        */}

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Mais de 62 mil patrocinadores

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Dados consolidados em relatórios

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Dicas de aporte

                                        </div>

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardFooter">

                                    <div className="paginaPropoentesPlanosProponenteCardFooterConteudo">

                                        {/*

                                            <a className="paginaPropoentesPlanosProponenteCardFooterBotao" href={this.state.linkAcao}>

                                                Realizar cadastro

                                            </a>

                                            */}

                                        <Link className="paginaPropoentesPlanosProponenteCardFooterBotao"
                                            to={{
                                                pathname: "/cadastro",
                                                stPlano: 'Semestral',
                                                planoSelecionadoProponente: this.state.plano,
                                                card: 3,
                                                search: `${this.props.search}`,

                                                linkPagamento:

                                                    this.state.plano === 1 ?
                                                        'https://app.vindi.com.br/customer/pages/d1e18907-8011-4cf4-9773-2b551a6e5221/subscriptions/new'
                                                        :
                                                        this.state.plano === 2 ?
                                                            'https://app.vindi.com.br/customer/pages/c1400904-57fb-4580-8e9a-bb9c86131640/subscriptions/new'
                                                            :
                                                            this.state.plano === 3 ?
                                                                'https://app.vindi.com.br/customer/pages/8dd43ce7-06b2-44f4-b9f3-221d6427cdcf/subscriptions/new'
                                                                :
                                                                'teste',

                                                cardClicado: 'Semestral'
                                            }}>

                                            Realizar cadastro

                                            </Link>

                                        <div className="paginaPropoentesPlanosProponenteCardFooterMensagem">

                                            <div className="paginaPropoentesPlanosProponenteCardFooterMensagem">Ao selecionar o plano, você aceita os <a target="_blank" href="https://simbiose.social/politica-de-privacidade" style={{ color: '#606A73', textDecoration: 'underline' }}>Termos e Condições</a> e o
                                                <a target="_blank" href={this.state.plano === 1 ? 'https://simbiose.social/politica-de-privacidade' :
                                                    this.state.plano === 2 ? 'https://simbiose.social/politica-de-privacidade' : 'https://simbiose.social/politica-de-privacidade+usua%CC%81rios.pdf'} style={{ color: '#606A73', textDecoration: 'underline' }}>Termo de Adesão</a>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="paginaPropoentesPlanosProponenteCard" style={{
                                display: this.state.cardExpandir === 4 && 'block',
                                height: this.state.cardExpandir === 4 && '313px',
                            }}>

                                <div className="paginaPropoentesPlanosProponenteEconomizeMobile" style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    backgroundColor: this.state.plano === 1 ? 'inherit' : 'rgb(238 240 243)',
                                    marginBottom: '10px',
                                    borderRadius: '6px',
                                    height: '30px',
                                    fontweight: 'bold',
                                    color: 'black',
                                }}>

                                    {

                                        this.state.plano === 1 ? '' :
                                            this.state.plano === 2 ? 'Economize 20%' : 'Economize 30%'

                                    }

                                </div>

                                <div className="paginaProponentesPlanosHeaderInfo">

                                    <div className="paginaPropoentesPlanosProponenteEconomize" style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        backgroundColor: this.state.plano === 1 ? 'inherit' : 'rgb(238 240 243)',
                                        marginBottom: '10px',
                                        borderRadius: '6px',
                                        height: '30px',
                                        fontweight: 'bold',
                                        color: 'black',
                                    }}>

                                        {

                                            this.state.plano === 1 ? '' :
                                                this.state.plano === 2 ? 'Economize 20%' : 'Economize 30%'

                                        }

                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardPeriodicidade">

                                        ASSINATURA MENSAL

                                        </div>

                                    <div className="paginaProponentesPlanosPropoenteExpandirIcone" onClick={() => this.expandeCardPlano(4)}>

                                        <img className="expandirIconTag" alt="expandirIcon" src={PlusIcon} style={{
                                            transform: this.state.cardExpandir === 4 && 'rotate(45deg)'
                                        }} />

                                    </div>

                                </div>

                                <div className="barraDivideCardProponentePlanos" />

                                <div className="paginaPropoentesPlanosProponenteCardPreco">

                                    <div className="paginaPropoentesPlanosProponenteCardValor">

                                        <span className="paginaPropoentesPlanosProponenteCardPrecoRS">R$</span>

                                        {

                                            this.state.plano === 1 ? '199,00' :
                                                this.state.plano === 2 ? '159,00' : '139,00'

                                        }

                                    </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorDescricao">

                                        mensais por usuário

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardValorTotal">

                                        {

                                            this.state.plano === 1 ? '1x de R$199,00' :
                                                this.state.plano === 2 ? '1x de R$477,00' : '1x de R$695,00'

                                        }

                                    </div>

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardConteudo" style={{
                                    display: this.state.cardExpandir === 4 && 'block',
                                    height: this.state.cardExpandir === 4 && '110px'
                                }}>

                                    {/*

                                        <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        {

                                            this.state.plano === 1 ? '' :
                                                this.state.plano === 2 ? 'Desconto de 20%' : 'Desconto de 30%'

                                        }

                                        </div>

                                        */}

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Mais de 62 mil patrocinadores

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Dados consolidados em relatórios

                                        </div>

                                    <div className="paginaPropoentesPlanosProponenteCardConteudoItem">

                                        Dicas de aporte

                                        </div>

                                </div>

                                <div className="paginaPropoentesPlanosProponenteCardFooter">

                                    <div className="paginaPropoentesPlanosProponenteCardFooterConteudo">

                                        {/*

                                        <a className="paginaPropoentesPlanosProponenteCardFooterBotao" href={this.state.linkAcao}>

                                            Realizar cadastro

                                        </a>

                                        */}

                                        <Link className="paginaPropoentesPlanosProponenteCardFooterBotao"
                                            to={{
                                                pathname: "/cadastro",
                                                stPlano: 'Mensal',
                                                planoSelecionadoProponente: this.state.plano,
                                                card: 4,
                                                search: `${this.props.search}`,

                                                linkPagamento:

                                                    this.state.plano === 1 ?
                                                        'https://app.vindi.com.br/customer/pages/97b6626e-ae8c-4f4d-9390-d013ee933865/subscriptions/new'
                                                        :
                                                        this.state.plano === 2 ?
                                                            'https://app.vindi.com.br/customer/pages/93931246-2b19-4372-9831-0bab3cb9049b/subscriptions/new'
                                                            :
                                                            this.state.plano === 3 ?
                                                                'https://app.vindi.com.br/customer/pages/9632eaa4-62a0-42cf-b43e-77bf0281c142/subscriptions/new'
                                                                :
                                                                'teste',

                                                cardClicado: 'Mensal'
                                            }}>

                                            Realizar cadastro

                                        </Link>

                                        <div className="paginaPropoentesPlanosProponenteCardFooterMensagem">

                                            <div className="paginaPropoentesPlanosProponenteCardFooterMensagem">Ao selecionar o plano, você aceita os <a target="_blank" href="https://simbiose.social/politica-de-privacidade" style={{ color: '#606A73', textDecoration: 'underline' }}>Termos e Condições</a> e o
                                                <a target="_blank" href={this.state.plano === 1 ? 'https://simbiose.social/politica-de-privacidade' :
                                                    this.state.plano === 2 ? 'https://simbiose.social/politica-de-privacidade' : 'https://simbiose.social/politica-de-privacidade'} style={{ color: '#606A73', textDecoration: 'underline' }}>Termo de Adesão</a>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div className="paginaPropoentesPlanosProponenteDesktopMensagem">

                    * Assinaturas para mais de 5 usuários entre em contato com o nosso atendimento <span className="linkLanding">radar@simbiose.social</span>

                </div>

            </div>

        )

    }

}

export default PlanosProponente;