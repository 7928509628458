import React from 'react'
import {AXIOS} from '../../../service/Base'

    class BoxWebinar extends React.Component {

        constructor() {

            super()

            this.state = {
                cancelando: false
            }

        }

        exibeData = (data) => {

            const mainDate = data.split(' ')[0];
            const month = mainDate.split('-')[1];
            const day = mainDate.split('-')[2];
            const year = mainDate.split('-')[0];


            return (day+'/'+month+'/'+year)

        }

        cancelaWebinar = () => {

            this.setState({
                cancelando: true
            })
        
            AXIOS().delete('agendamento/'+this.props.webinar.id_webinar, {
                
            })
            .then((response) => {
                console.log(response)
                this.setState({
                    cancelando: true,
                })
                this.props.buscaAgendamentos()
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    cancelando: false,
                })
            })

        }

        render() {

            return (

                <div className="paginaAgendamentoBoxWebinar">

                    <div>

                        Dia {this.exibeData(this.props.webinar.data_webinar)} às {this.props.webinar.pivot.horario}

                    </div>

                    <div className="paginaAgendamentoBoxWebinarCancelar" style={{pointerEvents: this.state.cancelando ? 'none' : 'auto'}} onClick={() => this.cancelaWebinar()}>

                        {this.state.cancelando ? 'Cancelando...' : 'Cancelar'}

                    </div>

                </div>

            )

        }

    }

export default BoxWebinar