import React from 'react'
import {AXIOS} from '../../service/Base'
import BoxWebinar from './components/BoxWebinar'
import { Redirect } from 'react-router-dom'

    class Reagendamento extends React.Component {

        constructor() {

            super()

            this.state = {
                buscandoAgendamento: false,
                datas: [],
                RadioId: 0,
                radioText: ''
            }

        }

        componentWillMount() {

            window.location.replace('https://simbi.social/');

            this.setState({
                buscandoAgendamento: true,
            })

            setTimeout(() => {

                this.buscaAgendamentos()

            }, 1000)

        }

        buscaAgendamentos = () => {

            this.setState({
                buscandoAgendamento: true,
            })

            AXIOS().get('agendamentoauth', {
                
            })
            .then((response) => {
                console.log(response)
                this.setState({
                    buscandoAgendamento: false,    
                    datas: response.data.data,    
                    datasWebnairs: response.data.data.webinars,
                })
            })
            .catch((error) => {
                if(error.response.data.data === '401') {

                    localStorage.setItem('@oauth:user', '');
                    localStorage.setItem('@oauth:token', '');   

                    this.setState({
                        buscandoAgendamento: false
                    })
                        

                } else {

                    this.setState({
                        buscandoAgendamento: false,                    
                    })

                }
            })   
        
        }

        agendarWebnair = (e) => {

            e.preventDefault()

            const representa = this.state.RadioId === 0 ? 'Proponente' : this.state.RadioId === 1 ? 'Agência' : this.state.RadioId === 2 ? this.state.radioText : ''

            this.setState({
                inscrevendo: true
            })

            AXIOS().post('inscrever/'+this.state.dataSelecionada, {
                "usuario": this.state.datas.usuario.id_usuario,
                "horario": this.state.horarioSelecionado,     
                "st_representa": representa
            })
            .then((response) => {
                console.log(response)
                if(response.data.success){
                    this.setState({
                        inscritoComSucesso: true,
                        dataConfirmada: response.data.data.data_webinar,
                        inscrevendo: false,
                        erroNaInscricao: false,
                        horarioSelecionado: '',
                        stringDataSelecionada: ''
                    })
                    this.buscaAgendamentos()
                }
            })
            .catch((error) => {
                console.log('erro post aqui > ', error.response.data.message)
                if(error.response.data.message === 'Call to a member function agendados() on null') {

                    localStorage.setItem('@oauth:user', '');
                    localStorage.setItem('@oauth:token', '');   

                    this.setState({
                        buscandoAgendamento: false
                    })
                        

                } else { 

                    this.setState({
                        inscritoComSucesso: false,
                        inscrevendo: false,
                        erroNaInscricao: true,
                    })
            
            }
            })

        }

        selecionaData = (text) => {

            console.log(text.target)

            this.setState({
                dataSelecionada: parseInt(text.target.value),
                horarioSelecionado: ''
            })

            this.state.datasWebnairs.map((webnar) => {

                return (

                    parseInt(text.target.value) === webnar.id_webinar &&

                        this.setState({
                            stringDataSelecionada: webnar.data_webinar
                        })

                )

            })

        }

        exibeData = (data) => {

            const mainDate = data.split(' ')[0];
            const month = mainDate.split('-')[1];
            const day = mainDate.split('-')[2];
            const year = mainDate.split('-')[0];


            return (day+'/'+month+'/'+year)

        }

        selecionaHorario = (horario) => {

            this.setState({
                horarioSelecionado: horario
            })

        }

        /*
        
        Pegar as datas disponíveis:
        GET /api/v1/agendamento
        retorno:
        {
            "success": true,
            "data": [
                {
                    "id_webinar": 1,
                    "data_webinar": "2020-06-17 00:00:00",
                    "horarios": [
                        "21:00",
                        "10:00"
                    ],
                    "created_at": "2020-06-10 17:03:06",
                    "updated_at": "2020-06-10 17:03:06"
                }
            ],
            "message": "Datas Retornadas com sucesso!"
        }

        Para se inscrever:
        POST /api/v1/inscrever/{id_webinar}
        body:
        {
            "usuario": "b6d767d2f8ed5d21a44b0e5886680cb9",
            "horario": "21:00"
        }

        */

       tenhoInteresseWebnair = () => {

            this.setState({
                confirmandoInteresse: true
            })
        
            AXIOS().post('contatosite', {
                "st_nome": this.state.nome, // Obrigatório
                "st_nome_empresa": this.state.empresa, // Obrigatório
                "st_email": this.state.email, // Obrigatório
                "mensagem": 'Recebemos uma declaração de interesse de um proponente no nosso site em participar de um treinamento do Radar. Como não temos nenhum Webinar cadastrado na API, assim que tivermos uma data confirmada, por favor envie um e-mail confirmando a data para '+this.state.nome +' no email ' +this.state.email,
                "id_novidades": '',
            })
            .then((response) => {
                console.log(response)
                this.setState({
                    confirmandoInteresse: false,
                    confirmadoInteresseComSucesso: true,
                })
            })
            .catch((error) => {
                console.log(error)
                if(error.response.data.data === '401') {

                    localStorage.setItem('@oauth:user', '');
                    localStorage.setItem('@oauth:token', '');   

                    this.setState({
                        buscandoAgendamento: false
                    })
                        

                } else {

                    this.setState({
                        confirmandoInteresse: false,
                        ErroConfirmandoInteresse: true,
                    })
            
                }
            })

       }

       radioRepresenta = (id) => {

            this.setState({
                RadioId: id
            })

       }

       setaRadioOutro = (text) => {

            this.setState({
                radioText: text.target.value
            })

       }

        render() {

            const logged = localStorage.getItem('@oauth:token')

            return (

                <div className="paginaContatoSection">

                    {

                        this.state.buscandoAgendamento ?

                            'Carregando...'

                        :

                            logged ?
                        
                            <>

                                <div className="paginaAgendamentoConteudoContainer">

                                    <div className="paginaAgendamentoConteudoEsquerdo">

                                        <div className="paginaAgendamentoConteudoTexto">

                                            <div className="paginaAgendamentoConteudoTextoTitulo">

                                                {

                                                    this.state.datas.usuario?.agendados.length > 0 ?

                                                        `Você tem webinares agendados:`

                                                    :

                                                        'Você ainda não agendou seu webinar.'

                                                }

                                            </div>

                                            <div className="paginaAgendamentoConteudoTextoSubtitulo">

                                                {

                                                    this.state.datas.usuario?.agendados.length > 0 ?

                                                        `Para alterar a data de seu webinar selecione a nova data ao lado.`

                                                    :

                                                        'Selecione uma data e horário para agendar um teste do radar de patrocinadores com a nossa equipe.'

                                                }

                                            </div>

                                        </div>

                                        <div className="paginaAgendamentoConteudoInformacoes">

                                            {

                                                this.state.datas.usuario &&

                                                    this.state.datas.usuario?.agendados.length > 0 &&

                                                        this.state.datas.usuario.agendados.map((webinar) => {

                                                            return (

                                                                <BoxWebinar webinar={webinar} buscaAgendamentos={this.buscaAgendamentos} />

                                                            )

                                                        })

                                            }

                                        </div>

                                    </div>

                                    <div className="paginaAgendamentoConteudoDireito">

                                    <div className="paginaAgendamentoConteudoTextoTitulo" style={{marginBottom: '20px'}}>

                                        Preencha o formulário abaixo:

                                    </div>

                                    <div className="paginaAgendamentoConteudoDireitoContainer">

                                        <form onSubmit={(e) => this.agendarWebnair(e)} className="paginaAgendamentoConteudoDireitoConteudo">

                                            <div className="paginaAgendamentoConteudoDireitoConteudoTitulo">

                                                Você representa?

                                            </div>

                                            <input type="email" name="email" value={this.state.email} style={{display: 'none'}} />

                                            <input type="data" name="data" value={this.state.stringDataSelecionada} style={{display: 'none'}} />

                                            <input type="text" name="horario" value={this.state.horarioSelecionado} style={{display: 'none'}} />

                                            <input type="text" name="represento" value={this.state.RadioId === 0 ? 'Proponente' : this.state.RadioId === 1 ? 'Agência' : this.state.RadioId === 2 ? this.state.radioText : ''} style={{display: 'none'}} />                                            
        
                                            {

                                            <div className="secaoContatoRepresentaContainer">

                                                <div className="secaoContatoRepresentaLabel">

                                                </div>

                                                <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                    <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(0)} checked={this.state.RadioId === 0 ? true : false} /> 
                                                    
                                                    Sou um proponente

                                                </div>

                                                <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                    <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(1)} checked={this.state.RadioId === 1 ? true : false} />

                                                    Sou uma agência

                                                </div>

                                                <div className="secaoContatoTermo" style={{fontSize: '16px'}}>

                                                    <input className="secaoContatoInput" type="radio" onChange={() => this.radioRepresenta(2)} checked={this.state.RadioId === 2 ? true : false} />

                                                    Outro ?

                                                </div>

                                                {

                                                    this.state.RadioId === 2 && 

                                                        <input className="inputsecaoContatoTermo" placeholder="Qual?" style={{marginBottom: '0px',borderBottom: this.state.ErroConfirmandoInteresse && !this.state.radioText && '1px solid red'}} value={this.state.radioText} onChange={(text) => this.setaRadioOutro(text)} /> 

                                                }

                                            </div>

                                            }

                                            <div className="paginaAgendamentoConteudoDireitoConteudoTitulo" style={{marginTop: '20px'}}>

                                                Escolha uma data disponível

                                            </div>

                                            <select className="paginaAgendamentoConteudoDatas" onChange={(text) => this.selecionaData(text)} value={this.state.dataSelecionada} placeholder="Selecione uma categoria">

                                                <option value="selecione" selected hidden> Selecione uma data </option>

                                                {
                                                    this.state.datasWebnairs.map((webnair) => {

                                                        return (

                                                            <option key={webnair.id_webinar} id={webnair.id_webinar} name={webnair.data_webinar} value={webnair.id_webinar}>{this.exibeData(webnair.data_webinar)}</option>

                                                        )

                                                    })
                                                }

                                            </select>
                                        
                                        {

                                        this.state.dataSelecionada &&

                                            <>

                                                <div className="paginaAgendamentoConteudoTitulo">

                                                    Escolha um horário disponível

                                                </div>

                                                <div className="paginaAgendamentoConteudoTagHorarios">

                                                    {

                                                        this.state.datasWebnairs.map((webnairs) => {

                                                            return (

                                                                webnairs.id_webinar === this.state.dataSelecionada &&

                                                                    webnairs.horarios.map((horarios, index) => {

                                                                        return (

                                                                            horarios != null &&

                                                                            <div key={index} style={{
                                                                                color: this.state.horarioSelecionado === horarios && '#fff',
                                                                                backgroundColor: this.state.horarioSelecionado === horarios && '#71CAD4'

                                                                            }} className="paginaAgendamentoConteudoTituloHorario" onClick={() => this.selecionaHorario(horarios)}>{horarios}</div>

                                                                        )

                                                                    })

                                                            )

                                                        })

                                                    }

                                                </div>

                                                <button type="submit" style={{
                                                    color: ((this.state.RadioId === 2 && !this.state.radioText) || !this.state.dataSelecionada || !this.state.horarioSelecionado) && 'rgb(128, 128, 128)',
                                                    backgroundColor: ((this.state.RadioId === 2 && !this.state.radioText) || !this.state.dataSelecionada || !this.state.horarioSelecionado) && 'rgb(235, 235, 235)',
                                                    pointerEvents: ((this.state.RadioId === 2 && !this.state.radioText) || !this.state.dataSelecionada || !this.state.horarioSelecionado || this.state.inscrevendo) && 'none',
                                                }} className="paginaAgendamentoConteudoBotaoConfirmar">

                                                    {this.state.inscrevendo ? 'Inscrevendo...' : 'Confirmar'}

                                                </button>

                                            </>

                                        }

                                        </form>

                                    </div>

                                    </div>
                                    

                                </div>
                            
                            </>

                            :

                            <Redirect 
                                to={{
                                    pathname: "/login",
                                    state: { from: 'reagendamento' }
                                }}
                            />

                    }

                </div>

            )

        }

    }

export default Reagendamento