import * as React from "react"


export default class PagamentoRejeitado extends React.Component {
    axiosSource

    constructor(props){
        super(props);

        this.state = {
            
        }
    }
    
    render() {
        
            return (

                <>

                    <div className="paginaConfirmacaoPagamentoContainer">

                        <div className="paginaConfirmacaoPagamentoConteudo erro">

                            <div className="paginaConfirmacaoPagamentoHeaderConteudo">

                                <div className="paginaConfirmacaoPagamentoConteudoIconeConfirmacao">

                                    :(

                                </div>

                                <div className="paginaConfirmacaoPagamentoConteudoTituloConfirmacao">

                                    Infelizmente seu pagamento foi rejeitado!

                                </div>

                                <div className="paginaConfirmacaoPagamentoConteudoSubtituloConfirmacao">

                                    Sua compra não foi aceita! Para mais informações envie uma mensagem para <span className="emailMensagem">radar@simbiose.social</span>

                                </div>

                            </div>
                
                            <div className="paginaConfirmacaoPagamentoInfoCompraBotaoContainer">

                                <div className="paginaConfirmacaoPagamentoInfoCompraBotao" onClick={this.props.voltarPlanos}>

                                    Voltar à área de pagamento

                                </div>

                            </div>

                        </div>

                    </div>

                </>

        )
    }
}